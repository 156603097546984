/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useState, useRef } from "react";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import {
  useFetchMaterialCategories,
  useFetchProducts,
} from "../../../../apolloClient/hooks/itemCreation/fetchHooks";
import {
  setMaterialCategories,
  setProductSystems,
} from "../../../../redux/reducers";

import { Container } from "../../../../globalstyled";
import AppLoader from "../../../../components/loader";
import { AppAlert } from "../../../../components/dialogue/Dialogue";
import { ProductSystemsTable } from "./ProductSystemsTable";

interface ProductSystemProp {}

const ProductSystems: React.FC<ProductSystemProp> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const userReducer = useAppSelector((state) => state.userReducer);
  const [isSkip, setIsSkip] = useState<boolean>(false);
  const refetchCalled = useRef<boolean>(false);
  const { productSystems, materialCategories } = userReducer;

  const isSkipFetchProductSystem = productSystems.length > 0 ? true : false;
  const isSkipFetchMaterialCategories =
    materialCategories.length > 0 ? true : false;

  const { loading, error, products, refetch } = useFetchProducts({
    limit: 100,
    offset: 0,
    isSkip: isSkip,
  });

  const handleRefetch = useCallback(() => {
    setIsSkip(false);
    refetchCalled.current = true;
    refetch();
  }, [refetch]);

  const {
    loading: materialCategoryLoading,
    error: materialCategoryError,
    materialCategories: materialCategory,
  } = useFetchMaterialCategories({
    limit: 100,
    offset: 0,
    isSkip: isSkipFetchMaterialCategories,
  });

  useEffect(() => {
    if (
      isSkipFetchMaterialCategories &&
      isSkipFetchProductSystem &&
      !refetchCalled.current
    ) {
      setIsSkip(true);
      setIsLoading(false);
    }
    onFetchProductSystem();
  }, [loading, error, products, refetchCalled]);

  useEffect(() => {
    onFetchMaterialCategories();
  }, [
    materialCategoryLoading,
    materialCategoryError,
    materialCategory,
    dispatch,
  ]);

  const onFetchProductSystem = () => {
    if (!loading && !error && products) {
      dispatch(setProductSystems(products));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Product:", error);
      setIsLoading(false);
    }
  };

  const onFetchMaterialCategories = () => {
    if (
      !materialCategoryError &&
      !materialCategoryLoading &&
      materialCategory
    ) {
      dispatch(setMaterialCategories(materialCategory));
    } else if (error) {
      console.error("Error fetching Product:", error);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <ProductSystemsTable
          productSystemData={productSystems}
          refetchProduct={handleRefetch}
        />
      </Container>
    );
  };

  return hostContainer();
};

export default ProductSystems;
