import React from "react";
import { Box } from "@mui/material";
import ProductSystemTableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";
import { AppCSS } from "../../../../components";

interface DisplayPhotoProps {
  value?: any;
  key?: string;
}

const DisplayPhoto: React.FC<DisplayPhotoProps> = ({ value, key }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <ProductSystemTableWithoutHeader
        key={`${key} display `}
        bodyContent={[
          {
            text: (
              <a
                href={value}
                target="_blank"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  fontSize: 12,
                  color: AppCSS.GLAS_BLUE,
                  backgroundColor: AppCSS.GREEN_MILD,
                  borderRadius: "10px",
                  padding: "5px",
                }}
                rel="noreferrer"
                download={false}
              >
                <p>View File</p>
              </a>
            ),
            width: "20%",
          },
        ]}
      />
    </Box>
  );
};

export default DisplayPhoto;
