/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { AppCSS, Spacer, TxtInput, ChkInputBox } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "../../../../globalstyled";
import { toast } from "react-toastify";

import { Box, Typography } from "@mui/material";

import UpArrowIcon from "../../../../images/UploadIcon.png";
import DownArrowIcon from "../../../../images/DownloadIcon.png";

import LeadsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import LaborMeasurementsDrawer from "../SideDrawer/index";
import TableViewDetails from "../../../../components/table/TableViewDetails";
import CancelAndSubmitButtons from "../ItemCreationComponent/SubmitAndCancelButton";

import LeadsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import { LABOR_MEASUREMENTS_TABLE_HEADER_LIST } from "../../../../contants/dashboardHeader";

import {
  LaborMeasurements,
  ProductSystem,
  ProductSystemForMiscField,
} from "../../../../redux/types/creation";
import "react-toastify/dist/ReactToastify.css";
import { FormTitleTypography, ArrowIcon } from "../itemCreation.styled";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import SearchAndCreateComponent from "../ItemCreationComponent/SearchAndCreateComponent";
import Pagination from "../ItemCreationComponent/Pagination";
import ViewRowDetailsDrawer from "../ItemCreationComponent/ViewFieldDetails";
import { LABOR_MEASUREMENT_DRAWER_DETAILS } from "../../../../contants/itemsCreation";
import { useCreateLaborMeasurement } from "../../../../apolloClient/hooks/itemCreation/createHooks";
import { extractCheckedIds } from "../../../../utils/formatting";
import { CreateLaborMeasurementInput } from "../../../../types/graphQlQuery.interface";
import EmptyTable from "../../../../components/table/EmptyTable";
import ProductSystemParentIdsTable from "../ItemCreationComponent/ProductSystemParentIdsTable";

interface MaterialMeasurementsTableProps {
  laborMeasurementsData: LaborMeasurements[];
  refetchLaborMeasurement?: Function;
  productsIds?: ProductSystem[];
}

export const LaborMeasurementsTable: React.FC<
  MaterialMeasurementsTableProps
> = ({ laborMeasurementsData, refetchLaborMeasurement, productsIds }) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openRowDetailsDrawer, setOpenRowDetailsDrawer] =
    React.useState<boolean>(false);

  const [viewRowDetailsData, setViewRowDetailsData] =
    useState<LaborMeasurements | null>(null);

  const [openAlert, setOpenAlert] = useState(false);

  const [productSystemId, setProductSystemId] = useState<string>("");
  const [productSystemIdsTableData, setProductSystemIdsTableData] = useState<
    { parentId: number }[]
  >([]);
  const [materialMeasurementName, setMaterialMeasurementName] =
    useState<string>("");
  const [measurementUnit, setMeasurementUnit] = useState<string>("");

  const [showProductSystemParent, setShowProductSystemParent] =
    useState<boolean>(false);

  const [checkboxStates, setCheckboxStates] = useState<{
    [key: number]: boolean;
  }>({});

  const { createLaborMeasurement } = useCreateLaborMeasurement();

  const viewRowDetails = (rowData: LaborMeasurements) => {
    setViewRowDetailsData(rowData);
    handleViewRowDetailsDrawerOpen();
  };

  const handleViewRowDetailsDrawerOpen = () => {
    setOpenRowDetailsDrawer(true);
  };

  const handleViewRowDetailsDrawerClose = () => {
    setOpenRowDetailsDrawer(false);
  };

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterLaborMeasurements = () => {
    if (keyword.length > 0 && Array.isArray(laborMeasurementsData)) {
      return laborMeasurementsData.filter((item) => {
        const laborMeasurementsName = `${item.name}`;
        const laborMeasurementsUnit = `${item.unit}`;
        return (
          laborMeasurementsName.toLowerCase().includes(keyword.toLowerCase()) ||
          laborMeasurementsUnit.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    }
    return laborMeasurementsData;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterLaborMeasurements().length / rowsPerPage) - 1
      )
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const onTapSubmitForm = async () => {
    const validationRules = [
      {
        condition: !materialMeasurementName,
        message: "Please Enter the material measurement name",
      },
      {
        condition: !measurementUnit,
        message: "Please Enter the material measurement unit",
      },
      {
        condition: !extractCheckedIds(checkboxStates).length,
        message: "Please select a minimum of one product system!",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }
    const checkedParentIds = extractCheckedIds(checkboxStates);

    const input: CreateLaborMeasurementInput = {
      name: materialMeasurementName,
      unit: measurementUnit,
      parentIds: checkedParentIds,
    };

    const success = await createLaborMeasurement(input);
    if (success) {
      toast("Misc field created successfully.", {
        type: "success",
        className: "toast-message",
      });
      refetchLaborMeasurement();
      handleDrawerClose();
      setMaterialMeasurementName("");
      setMeasurementUnit("");
      setCheckboxStates({});
    } else {
      console.error("Failed to create Misc field.");
    }
  };

  const createLaborMeasurementsForm = () => {
    return (
      <LaborMeasurementsDrawer open={openDrawer} onClose={handleDrawerClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={handleDrawerClose}
            labelTitle="Create new labor measurement"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <ColDiv
                style={{
                  gap: "5px",
                }}
              >
                <Spacer size={1} direction="col" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Name</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter name"
                  onChange={setMaterialMeasurementName}
                  required={true}
                  value={materialMeasurementName}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <FormTitleTypography>Measurement unit</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter unit"
                  onChange={setMeasurementUnit}
                  required={true}
                  value={measurementUnit}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <Spacer size={1} direction="col" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Lbl
                    title="Associated product systems"
                    size={16}
                    width={80}
                    bold={600}
                  />
                </Box>
                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "inherit",
                    borderRadius: "10px",
                    border: "1px solid rgba(173, 173, 173, 1)",
                    height: "fit-content",
                    minHeight: "50px",
                    paddingY: "10px",
                  }}
                  onClick={() =>
                    setShowProductSystemParent(!showProductSystemParent)
                  }
                >
                  <RowDiv
                    style={{
                      width: "100%",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Lbl
                        title="
                        Select associated product systems"
                        size={16}
                        mobileFontSize={16}
                        isFullWidth={true}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "40%",
                        alignItems: "center",
                        justifyContent: "end",
                        paddingRight: "20px",
                      }}
                    >
                      {showProductSystemParent ? (
                        <ArrowIcon src={UpArrowIcon} />
                      ) : (
                        <ArrowIcon src={DownArrowIcon} />
                      )}
                    </Box>
                  </RowDiv>

                  {showProductSystemParent && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "start",
                        width: "100%",
                        paddingX: "20px",
                      }}
                    >
                      {productsIds.map((item) => (
                        <Box
                          key={`checkProductsSystemParent ${item.id}`}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            cursor: "pointer",
                            justifyContent: "start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <ChkInputBox
                            onChange={(value: boolean) => {
                              setCheckboxStates((prevCheckboxStates) => ({
                                ...prevCheckboxStates,
                                [item.id]: value,
                              }));
                              setShowProductSystemParent(true);
                            }}
                            placeholder=""
                            width={50}
                            marginTop={0}
                            marginBottom={0}
                            height={20}
                            background="inherit"
                            checked={checkboxStates[item.id] || false}
                          />
                          <Typography>{item.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleDrawerClose}
          onCreate={onTapSubmitForm}
        />
      </LaborMeasurementsDrawer>
    );
  };

  const showLaborMeasurementRowDetails = () => {
    return (
      <ViewRowDetailsDrawer
        openDrawer={openRowDetailsDrawer}
        handleDrawerClose={handleViewRowDetailsDrawerClose}
        rowDetailsData={viewRowDetailsData}
        labelTitle="Labor Measurements Details"
        rowArrayTableComponent={(
          value: ProductSystemForMiscField[],
          key?: string
        ) => <ProductSystemParentIdsTable value={value} key={key} />}
        detailsOfRowToBeDisplay={LABOR_MEASUREMENT_DRAWER_DETAILS}
      />
    );
  };
  const LaborMeasurementsTable = () => {
    return (
      <Container>
        <ColDiv
          style={{
            width: "100%",
            gap: "10px",
          }}
        >
          <ColDiv
            style={{
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "151px",
            }}
          >
            <SearchAndCreateComponent
              handleSearchIconClick={handleSearchIconClick}
              handleDrawerOpen={() => {
                handleDrawerOpen();
              }}
              setKeyword={setKeyword}
              inputRef={inputRef}
              searchPlaceHolder="Search labor measurements"
            />
            <Box
              sx={{
                height: "calc(100% - 65px)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeadsTableHeaderCard
                headers={LABOR_MEASUREMENTS_TABLE_HEADER_LIST}
              />
            </Box>
          </ColDiv>
          {filterLaborMeasurements().length === 0 ? (
            <EmptyTable message="No labor measurement available" />
          ) : (
            filterLaborMeasurements()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <LeadsTableBodyCard
                  key={`Labor  Measurements ${rowIndex}`}
                  containerWidth="100%"
                  bodyContent={[
                    {
                      text: `${row.id}`,
                      width: "10%",
                      fontSize: "15px",
                      paddingLeft: "30px",
                    },
                    {
                      text: `${row.name}`,
                      width: "30%",
                      fontSize: "15px",
                      paddingLeft: "50px",
                    },
                    {
                      text: `${row.unit}`,
                      width: "30%",
                      fontSize: "15px",
                    },
                    {
                      text: "",
                      width: "30%",
                      justifyContent: "end",
                      isAction: true,
                      actionComponent: (
                        <TableViewDetails
                          rowData={row}
                          onViewClick={viewRowDetails}
                          text={"View"}
                        />
                      ),
                    },
                  ]}
                />
              ))
          )}
        </ColDiv>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          filterData={filterLaborMeasurements}
        />
      </Container>
    );
  };

  const laborMeasurementsContainer = () => {
    return (
      <Container
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <ColDiv
          style={{
            justifyContent: "start",
          }}
        >
          {Array.isArray(laborMeasurementsData) && LaborMeasurementsTable()}
        </ColDiv>
        {createLaborMeasurementsForm()}
        {showLaborMeasurementRowDetails()}
      </Container>
    );
  };

  return laborMeasurementsContainer();
};
