import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
  query GetProducts($limit: Int!, $offset: Int!) {
    products(limit: $limit, offset: $offset) {
      id
      name
      paymentMethods {
        id
        name
        paymentType
        percent
        amount
        stepNumber
      }
      thumbnail
      duration
      priority
    }
  }
`;

export const GET_PRODUCTS_Ids = gql`
  query GetProducts($limit: Int!, $offset: Int!) {
    products(limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;

export const GET_MATERIAL_CATEGORIES = gql`
  query GetMaterialCategories($limit: Int!, $offset: Int!) {
    materialCategory(limit: $limit, offset: $offset) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_MISC_FIELDS = gql`
  query GetMiscFields($limit: Int!, $offset: Int!) {
    miscFields(limit: $limit, offset: $offset) {
      id
      name
      showToCustomer
      parents {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_LABOR_MEASUREMENT_FIELDS = gql`
  query GetLaborMeasurements($limit: Int!, $offset: Int!) {
    laborMeasurements(limit: $limit, offset: $offset) {
      id
      name
      unit
      parents {
        id
        name
      }
    }
  }
`;

export const GET_MATERIAL_MEASUREMENT_FIELDS = gql`
  query GetMaterialMeasurement($limit: Int!, $offset: Int!) {
    materialMeasurements(limit: $limit, offset: $offset) {
      id
      name
      unit
      parents {
        id
        name
      }
      categoryId
    }
  }
`;

export const GET_MATERIAL = gql`
  query GetMaterials($limit: Int!, $offset: Int!) {
    materials(limit: $limit, offset: $offset) {
      id
      name
      measurement {
        id
        name
      }
      brand
      description
      price
      quality
      measurementUnit
      coverageAmount
      images {
        id
        url
      }
    }
  }
`;

export const GET_CONTRACTOR_REPORTS = gql`
  query GetContractorReports($limit: Int!, $offset: Int!) {
    contractorReports(limit: $limit, offset: $offset) {
      id
      name
      responsibility
      description
      parents {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONTRACTOR = gql`
  query GetContractors($limit: Int!, $offset: Int!) {
    contractors(limit: $limit, offset: $offset) {
      id
      name
      website
      description
      files {
        id
        name
        description
        url
        fileType
      }
      trades {
        id
        name
        description
        yearsOfDoing
      }
    }
  }
`;

export const GET_LABORS = gql`
  query Labors($limit: Int!, $offset: Int!) {
    labors(limit: $limit, offset: $offset) {
      id
      description
      price
      measurementUnit
      coverageAmount
      contractorId
      measurementId
      parents {
        id
        name
      }
      files {
        id
        name
        description
        url
        fileType
      }
    }
  }
`;

export const GET_CONTRACTOR_CHANGE_ORDERS = gql`
  query GetContractorChangeOrders($limit: Int!, $offset: Int!) {
    ContractorOrders(limit: $limit, offset: $offset) {
      id
      name
      materialPrice
      laborPrice
      materialId
      laborId
      parents {
        id
        name
      }
    }
  }
`;

export const GET_MATERIAL_MEASUREMENTS_NAME_UNIT_FIELDS = gql`
  query GetMaterialMeasurement($limit: Int!, $offset: Int!) {
    materialMeasurements(limit: $limit, offset: $offset) {
      id
      name
      unit
    }
  }
`;

export const GET_PRODUCTS_NAME = gql`
  query GetProducts($limit: Int!, $offset: Int!) {
    products(limit: $limit, offset: $offset) {
      id
      name
    }
  }
`;
