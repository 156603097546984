import { AdminUserModel } from "../types";
import { BASE_URL } from "./appConst";
import { axiosAuth } from "./common";

export const GetUsers = async () => {
  try {
    const api = axiosAuth();
    const response = await api.get(`${BASE_URL}/users?offset=0&limit=100`);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occured",
    };
  }
};

export const CreateAdminUser = async (input: Partial<AdminUserModel>) => {
  try {
    const api = axiosAuth();
    const response = await api.post(`${BASE_URL}/users`, {
      email: input.email,
      first_name: input.firstName,
      last_name: input.lastName,
      phone: input.phone,
      role: input.role,
      password: input.password,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occurred",
    };
  }
};

export const EditAdminUser = async (
  userId: number,
  input: Partial<AdminUserModel>
) => {
  try {
    const api = axiosAuth();
    const response = await api.patch(`${BASE_URL}/users/${userId}`, {
      first_name: input.firstName,
      last_name: input.lastName,
      phone: input.phone,
      role: input.role,
      status: input.Status,
    });
    return response.status;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occurred",
    };
  }
};
