import React, { ReactNode } from "react";

import { BodyBoxWithoutHeader, CardTypography } from "./table.styled";

interface Body {
  text: string | ReactNode;
  width: string;
  fontSize?: string;
  isAction?: boolean;
  actionComponent?: ReactNode;
  paddingLeft?: string;
  justifyContent?: string;
}

interface BodyCardProps {
  bodyContent: Body[];
  onViewLeadsDetails?: () => void;
  isViewDetails?: boolean;
  containerWidth?: string;
}

const BodyCardWithoutHeader: React.FC<BodyCardProps> = ({
  bodyContent,
  onViewLeadsDetails,
  isViewDetails,
  containerWidth,
}) => {
  return (
    <BodyBoxWithoutHeader
      onClick={() => {
        if (isViewDetails) {
          onViewLeadsDetails();
          return;
        }
      }}
      sx={{
        cursor: isViewDetails ? "pointer" : "default",
      }}
      width={containerWidth}
    >
      {bodyContent.map((body, index) => (
        <CardTypography
          key={`${index}-${body.text} body`}
          width={body.width}
          fontSize={body.fontSize || "15px"}
          color="black"
          paddingLeft={body.paddingLeft}
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",
            justifyContent: body.justifyContent ? body.justifyContent : "",
          }}
          variant="body1"
        >
          {body.isAction && body.actionComponent
            ? body.actionComponent
            : body.text}
        </CardTypography>
      ))}
    </BodyBoxWithoutHeader>
  );
};

export default BodyCardWithoutHeader;
