/* eslint-disable jsx-a11y/alt-text */
import { AppCSS, TxtInput } from "../../../../components";
import React from "react";
import { Grid, Box, Button } from "@mui/material";
import { RowLeadIconDiv } from "../../Leads/leads.styled";
import { SearchIcon, SearchInputBox } from "../../dashboard.styled";
import searchIcon from "../../../../images/searchIcon.png";

interface Props {
  handleSearchIconClick: () => void;
  handleDrawerOpen: () => void;
  setKeyword: (value: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  searchPlaceHolder: string;
}

const SearchAndCreateComponent: React.FC<Props> = ({
  handleSearchIconClick,
  handleDrawerOpen,
  setKeyword,
  inputRef,
  searchPlaceHolder,
}) => {
  return (
    <RowLeadIconDiv
      style={{
        justifyContent: "start",
        paddingLeft: "30px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        height: "55px",
        marginTop: "20px",
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Grid item xs={9}>
          <Box
            sx={{
              width: "98%",
              marginX: "1%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
            onClick={handleSearchIconClick}
          >
            <SearchInputBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                width: "100%",
                flexDirection: "row",
                border: "1px solid black",
                borderRadius: "10px",
              }}
              height={40}
            >
              <SearchIcon
                src={searchIcon}
                style={{
                  marginLeft: "10px",
                  background: "inherit",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleSearchIconClick}
              />
              <Box
                sx={{
                  width: "calc(100% - 24px)",
                  height: "25px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <TxtInput
                  placeholder={searchPlaceHolder}
                  onChange={setKeyword}
                  inputRef={inputRef}
                  background="white"
                  height={30}
                />
              </Box>
            </SearchInputBox>
          </Box>
        </Grid>
        <Grid
          item
          xs={3}
          spacing={2}
          sx={{
            paddingX: "10px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              height: "40px",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                height: "100%",
                width: "164px",
                color: AppCSS.BLUE,
                border: "1px solid",
                borderColor: AppCSS.BLUE,
                borderRadius: "10px",
              }}
              onClick={handleDrawerOpen}
            >
              Create
            </Button>
          </Box>
        </Grid>
      </Grid>
    </RowLeadIconDiv>
  );
};

export default SearchAndCreateComponent;
