/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppAlert } from "../../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../../redux/hooks";
import { MaterialMeasurementsTable } from "./MaterialMeasurementsTable";
import { setMaterialMeasurements } from "../../../../redux/reducers";
import { Container } from "../../../../globalstyled";
import AppLoader from "../../../../components/loader";
import { useFetchMaterialMeasurements } from "../../../../apolloClient/hooks/itemCreation/fetchHooks";

interface MaterialMeasurementsProp {}

const MaterialMeasurement: React.FC<MaterialMeasurementsProp> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { materialMeasurements, productSystems, materialCategories } =
    userReducer;

  const { loading, error, materialMeasurementsData, refetch } =
    useFetchMaterialMeasurements({
      limit: 100,
      offset: 0,
    });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    onFetchMaterialMeasurements();
  }, [loading, error, materialMeasurementsData, dispatch, handleRefetch]);

  const onFetchMaterialMeasurements = () => {
    if (!loading && !error && materialMeasurementsData) {
      dispatch(setMaterialMeasurements(materialMeasurementsData));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Material misc field:", error);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <MaterialMeasurementsTable
          materialMeasurementsData={materialMeasurements}
          refetchMaterialMeasurement={handleRefetch}
          productsIds={productSystems}
          materialCategories={materialCategories}
        />
      </Container>
    );
  };

  return hostContainer();
};

export default MaterialMeasurement;
