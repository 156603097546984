import React from "react";
import { Typography, Box } from "@mui/material";
import { NumericFormat } from "react-number-format";

interface DisplayRowDetailsCardProps {
  label: string;
  value: any;
  isArray?: boolean;
  isObject?: boolean;
  objectDetails?: any[];
  isColumn?: boolean;
  isAmount?: boolean;
}

const DisplayRowDetailsCard: React.FC<DisplayRowDetailsCardProps> = ({
  label,
  value,
  isArray = false,
  isObject = false,
  objectDetails,
  isColumn = false,
  isAmount = false,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isColumn ? "column" : "row",
        paddingX: "10px",
        gap: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          width: "50%",
          paddingRight: "10px",
          fontSize: "16px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {label}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "right",
            fontSize: "16px",
            alignSelf: "right",
            fontWeight: "bold",
          }}
        >
          {`  :`}
        </Typography>
      </Box>

      {isArray && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingY: "10px",
          }}
        >
          {value}
        </Box>
      )}

      {!isArray && !isObject && (
        <Typography
          variant="body1"
          sx={{
            textAlign: "left",
            width: "50%",
            fontSize: "14px",
            paddingLeft: "5px",
          }}
        >
          {isAmount === true ? (
            <NumericFormat
              style={{
                width: "100%",
                height: "100%",
              }}
              value={value}
              prefix="$"
              thousandSeparator
            />
          ) : (
            value
          )}
        </Typography>
      )}

      {isObject && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: "10px",
            gap: "5px",
          }}
        >
          {objectDetails.map((ele, index) => {
            const objKey = Object.keys(ele)[0];
            const objValue = ele[objKey];
            const displayValue = value[objKey];
            return (
              <Box
                key={`ele-${index}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  paddingX: "10px",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "left",
                    width: "45%",
                    paddingRight: "10px",
                    fontSize: "16px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "left",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    {objValue}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "right",
                      fontSize: "12px",
                      alignSelf: "right",
                      fontWeight: "bold",
                    }}
                  >
                    {`  :`}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "left",
                    width: "65%",
                    fontSize: "14px",
                    paddingLeft: "5px",
                  }}
                >
                  {displayValue}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default DisplayRowDetailsCard;
