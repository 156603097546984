/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import React from "react";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import DeleteIcon from "@material-ui/icons/Delete";

import { LinkTag, RowDiv } from "../misc/misc.styled";
import { AppCSS } from "..";

export interface CheckBoxProp {
  onChange: Function;
  placeholder: string;
  borderColor?: string;
  value?: any;
  items?: unknown;
  width?: number;
  background?: string;
  lines?: string;
  required?: boolean;
  marginTop?: number;
  marginBottom?: number;
  checked?: boolean;
  height?: number;
}

export const ChkInputBox: React.FC<CheckBoxProp> = ({
  onChange,
  placeholder,
  width,
  background,
  lines,
  required,
  marginTop,
  marginBottom,
  checked,
  height,
  borderColor,
}) => {
  const [isChecked, setIsChecked] = React.useState(checked || false);

  React.useEffect(() => {
    setIsChecked(checked || false); // Update isChecked when checked prop changes
  }, [checked]);

  return (
    <Box
      style={{
        flexDirection: "row",
        paddingLeft: "2%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: background ? background : AppCSS.GRAY_MILD,
        borderRadius: "5px",
        marginTop: marginTop ? `${marginTop}px` : "5px",
        marginBottom: marginBottom ? `${marginBottom}px` : "5px",
        width: width ? `${width}px` : "96%",
        height: height ? `${height}px` : lines ? "auto" : "35px",
      }}
    >
      <FormControlLabel
        style={{
          color: "#979797",
          fontSize: "0.5rem",
          width: required ? "95%" : "100%",
        }}
        control={
          <Checkbox
            checked={isChecked}
            onChange={(e) => {
              const newChecked = e.target.checked;
              onChange(newChecked);
              setIsChecked(newChecked);
            }}
            sx={{
              color: borderColor ? borderColor : AppCSS.BLACK,
              "&.Mui-checked": {
                color: AppCSS.GREEN,
              },
            }}
          />
        }
        label={placeholder}
      />
      {required && (
        <p
          style={{
            fontSize: 32,
            color: AppCSS.RED,
            zIndex: 200,
            width: "5%",
            marginRight: "0px",
            paddingRight: "0px",
          }}
        >
          *
        </p>
      )}
    </Box>
  );
};

export const ChkInputBoxMini: React.FC<CheckBoxProp> = ({
  onChange,
  placeholder,
  borderColor,
  checked,
  width,
}) => {
  return (
    <FormControlLabel
      style={{
        color: "#979797",
        width: width ? width : "auto",
      }}
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            color: borderColor ? borderColor : "#dc2342",
            "&.Mui-checked": {
              color: "#dc2342",
            },
          }}
        />
      }
      label={placeholder}
    />
  );
};

export const ChkInputButton: React.FC<CheckBoxProp> = ({
  onChange,
  placeholder,
}) => {
  return (
    <FormControlLabel
      style={{
        marginLeft: "10px",
        marginRight: "30px",
        marginTop: "1.8rem",
        marginBottom: "0.8rem",
        background: "#f3f0f0",
        borderRadius: "5px",
      }}
      labelPlacement="end"
      control={
        <Checkbox
          defaultChecked
          icon={<CircleUnchecked />}
          checkedIcon={<CircleCheckedFilled />}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            borderRadius: "10px",
            marginRight: "10px",
            marginLeft: 0,
            color: "#dc2342",
            "&.Mui-checked": {
              color: "#dc2342",
            },
          }}
        />
      }
      label={placeholder}
    />
  );
};

export const DeleteOptionMini: React.FC<CheckBoxProp> = ({
  onChange,
  placeholder,
}) => {
  return (
    <FormControlLabel
      style={{
        color: "#979797",
      }}
      control={
        <Checkbox
          defaultChecked
          icon={<DeleteIcon />}
          checkedIcon={<DeleteIcon />}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            margin: 0,
            color: "#dc2342",
            "&.Mui-checked": {
              color: "#dc2342",
            },
          }}
        />
      }
      label={placeholder}
    />
  );
};

interface TNCProps {
  tncSet: Function;
}

export const TermsAndConditionCheck: React.FC<TNCProps> = ({ tncSet }) => {
  return (
    <RowDiv
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RowDiv
        style={{
          width: "50px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <FormControlLabel
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            marginTop: "1.8rem",
            marginBottom: "0.8rem",
            color: "#979797",
            fontSize: "0.5rem",
            width: "10%",
          }}
          control={
            <Checkbox
              onChange={(e) => tncSet(e.target.value)}
              sx={{
                color: "#dc2342",
                "&.Mui-checked": {
                  color: "#dc2342",
                },
              }}
            />
          }
          label={""}
        />
      </RowDiv>
    </RowDiv>
  );
};
