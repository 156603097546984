/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";

import * as React from "react";
import { Container } from "../../../globalstyled";
import { InsuranceCompany } from "../../../redux/types/InsuranceCompany";
import InsuranceCompanyTableHeaderCard from "../../../components/table/CustomTableHeaderCard";
import InsuranceCompanyTableBodyCard from "../../../components/table/CustomTableBodyCard";
import { INSURANCE_COMPANY_TABLE_HEADER } from "../../../contants/dashboardHeader";
import { Box } from "@mui/material";
import { DeleteIcon } from "../dashboard.styled";
import deleteIcon from "../../../images/DeleteIcon.png";
import { Prompt } from "../../../components/dialogue/Dialogue";
import { useMutation } from "@apollo/client";
import { DELETE_INSURANCE_COMPANY_BY_ID } from "../../../apolloClient/operation/mutations/mutation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmptyTable from "../../../components/table/EmptyTable";

interface UserTableProps {
  insuranceCompanies: InsuranceCompany[];
  onTapAddCompany: Function;
  onEditInsurance: (insurance: InsuranceCompany) => void;
  refetch?: Function;
}

export const InsuranceTable: React.FC<UserTableProps> = ({
  insuranceCompanies,
  onTapAddCompany,
  onEditInsurance,
  refetch,
}) => {
  const [keyword, setKeyword] = React.useState("");
  const [openConfirmDelete, setOpenConfirmDelete] =
    React.useState<boolean>(false);
  const [insuranceIdToDelete, setInsuranceIdToDelete] = React.useState<
    number | null
  >(null);
  const [insuranceCompanyNameToDelete, setInsuranCompanyNameToDelete] =
    React.useState<string>("");

  const [deleteInsuranceCompany] = useMutation<
    { deleteInsuranceCompany: Boolean },
    { id: Number }
  >(DELETE_INSURANCE_COMPANY_BY_ID);

  const filterInSuranceCompanies = () => {
    if (keyword.length > 0 && Array.isArray(insuranceCompanies)) {
      return insuranceCompanies.filter((item) => {
        return item.companyName.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return insuranceCompanies;
  };

  const handleDeleteInsuranceCompanyById = async (id: number) => {
    try {
      const { data } = await deleteInsuranceCompany({
        variables: { id: id },
      });
      if (data?.deleteInsuranceCompany) {
        refetch();
        setInsuranceIdToDelete(null);
        setOpenConfirmDelete(false);
        toast("Insurance company deleted successfully!", {
          type: "success",
          className: "toast-message",
        });
      }
    } catch (error) {
      console.error("Error deleting insurance company:", error);
      setInsuranceIdToDelete(null);
      setOpenConfirmDelete(false);
      toast("Error deleting insurance company!", {
        type: "error",
        className: "toast-message",
      });
    }
  };

  const handleCancleInsuranceCompanyById = () => {
    setOpenConfirmDelete(false);
    setInsuranceIdToDelete(null);
    setInsuranCompanyNameToDelete("");
  };

  const InsuranceTable = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <RowDiv
          style={{
            justifyContent: "flex-start",
            width: "95%",
            marginLeft: "2.5%",
          }}
        >
          <TxtInput
            width={300}
            placeholder={`Search providers`}
            onChange={setKeyword}
            borderColor={AppCSS.BLUE}
          />
          <RowDiv style={{ justifyContent: "flex-end" }}>
            <TapButton
              title={"Add Company"}
              bgColor={AppCSS.BLUE}
              onTap={() => onTapAddCompany()}
              width={200}
              height={40}
            />
          </RowDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            width: "95%",
            marginBottom: "1%",
            gap: "10px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <InsuranceCompanyTableHeaderCard
            headers={INSURANCE_COMPANY_TABLE_HEADER}
          />

          {filterInSuranceCompanies().length === 0 ? (
            <EmptyTable message="No insurance company available" />
          ) : (
            filterInSuranceCompanies().map((row, rowIndex) => {
              const key = `key-insurance-${rowIndex}`;
              return (
                <InsuranceCompanyTableBodyCard
                  key={key}
                  bodyContent={[
                    {
                      text: `${row.companyName}`,
                      width: "30%",
                      paddingLeft: "30px",
                    },
                    {
                      text: (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={row.companyLogo}
                            width={30}
                            height={30}
                            alt="logo"
                          />
                        </Box>
                      ),
                      width: "20%",
                      justifyContent: "center",
                    },
                    {
                      text: (
                        <a
                          href={row.claimLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: AppCSS.BLUE,
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            fontSize: "0.9rem",
                            marginLeft: 10,
                            padding: 0,
                          }}
                        >
                          Visit site
                        </a>
                      ),
                      width: "30%",
                      paddingLeft: "30px",
                    },
                    {
                      text: "",
                      width: "10%",
                      isAction: true,
                      actionComponent: (
                        <TapButton
                          title="Edit"
                          onTap={() => onEditInsurance(row)}
                          bgColor={AppCSS.WHITE}
                          borderColor={AppCSS.BLUE}
                          color={AppCSS.BLUE}
                          radius={5}
                          height={35}
                        />
                      ),
                      justifyContent: "center",
                    },
                    {
                      text: "",
                      width: "10%",
                      isAction: true,
                      actionComponent: (
                        <DeleteIcon
                          src={deleteIcon}
                          onClick={() => {
                            setOpenConfirmDelete(true);
                            setInsuranceIdToDelete(row.id);
                            setInsuranCompanyNameToDelete(row.companyName);
                          }}
                        />
                      ),
                      justifyContent: "center",
                    },
                  ]}
                  containerHeight="40px"
                />
              );
            })
          )}
        </ColDiv>
      </Container>
    );
  };

  const userContainer = () => {
    return (
      <Container>
        <Prompt
          message={`Delete ${insuranceCompanyNameToDelete} insurance company?`}
          onConfirm={() => {
            handleDeleteInsuranceCompanyById(insuranceIdToDelete);
          }}
          onClose={() => {
            handleCancleInsuranceCompanyById();
          }}
          open={openConfirmDelete}
        />
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv style={{ marginLeft: "2.5%" }}>
            <Lbl
              title="Insurance providers"
              size={25}
              backgroundColor="#f3f3f3"
            />
          </RowDiv>
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            <Spacer direction="col" />
            {Array.isArray(insuranceCompanies) && InsuranceTable()}
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  return userContainer();
};
