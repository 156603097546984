/* eslint-disable jsx-a11y/alt-text */
import { AppCSS, Spacer, TxtInput, ChkInputBox } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import * as React from "react";
import { Container } from "../../../../globalstyled";

import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import plusIcon from "../../../../images/PlusIcon.png";
import editIcon from "../../../../images/EditIcon.png";
import UpArrowIcon from "../../../../images/UploadIcon.png";
import DownArrowIcon from "../../../../images/DownloadIcon.png";

import LeadsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import ContractorDrawer from "../SideDrawer/index";
import TableViewDetails from "../../../../components/table/TableViewDetails";
import CancelAndSubmitButtons from "../ItemCreationComponent/SubmitAndCancelButton";
import {
  FormTitleTypography,
  StandardIcon,
  ArrowIcon,
} from "../itemCreation.styled";
import TableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";

import LeadsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import {
  FILE_TABLE_HEADER_LIST,
  LABOR_ITEMS_TABLE_HEADER_LIST,
} from "../../../../contants/dashboardHeader";

import {
  ContractorFile,
  Contractors,
  LaborItems,
  LaborItemsForm,
  MaterialMeasurements,
  ProductSystem,
  ProductSystemForMiscField,
} from "../../../../redux/types/creation";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SFileUpload } from "../../../../components/input/input.styled";
import { FileOpen } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import SearchAndCreateComponent from "../ItemCreationComponent/SearchAndCreateComponent";
import Pagination from "../ItemCreationComponent/Pagination";
import ViewRowDetailsDrawer from "../ItemCreationComponent/ViewFieldDetails";
import {
  LABOR_ITEMS_DRAWER_DETAILS,
  SELECT_CONTRACTOR_FILETYPE,
} from "../../../../contants/itemsCreation";
import EmptyTable from "../../../../components/table/EmptyTable";
import {
  ContractorFileInput,
  CreateLaborInput,
  CreateUploadFileInput,
} from "../../../../types/graphQlQuery.interface";
import { uploadImage } from "../../../../redux/api/common";
import { useCreateLabor } from "../../../../apolloClient/hooks/itemCreation/createHooks";
import { useCreateUploadUrl } from "../../../../apolloClient/hooks/general/createHook";
import { extractCheckedIds } from "../../../../utils";
import FileUploadTable from "../ItemCreationComponent/FileUploadTable";
import ProductSystemParentIdsTable from "../ItemCreationComponent/ProductSystemParentIdsTable";

interface LaborItemsTableProps {
  laborItemsData: LaborItems[];
  refetchLabor?: Function;
  productsIds?: ProductSystem[];
  contractors?: Contractors[];
  materialMeasurements?: MaterialMeasurements[];
}

export const LaborItemsTable: React.FC<LaborItemsTableProps> = ({
  laborItemsData,
  refetchLabor,
  productsIds,
  contractors,
  materialMeasurements,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openRowDetailsDrawer, setOpenRowDetailsDrawer] =
    React.useState<boolean>(false);

  const [photoFormDataArr, setPhotoFormDataArr] = React.useState<
    ContractorFileInput[]
  >([]);

  const [openPhotoFormDrawer, setOpenPhotoFormDrawer] = React.useState(false);

  const [viewRowDetailsData, setViewRowDetailsData] =
    useState<LaborItems | null>(null);

  const [showProductSystemParent, setShowProductSystemParent] =
    useState<boolean>(false);

  const [checkboxStates, setCheckboxStates] = useState<{
    [key: number]: boolean;
  }>({});

  const [laborItemsFormField, setLaborItemsFormField] =
    useState<LaborItemsForm>({
      description: "",
      price: null,
      measurementUnit: "",
      coverageAmount: null,
      contractorId: null,
      measurementId: null,
      parentIds: [],
      files: [],
    });

  const [laborPhotoFormField, setLaborPhotoFormField] =
    useState<ContractorFileInput>({
      name: "",
      description: "",
      fileType: null,
      url: "",
    });

  const [laborEditPhotoDetails, setLaborEditPhotoDetails] =
    useState<ContractorFileInput>({
      name: "",
      description: "",
      fileType: null,
      url: "",
    });

  const fileRef = useRef<HTMLInputElement>(null);
  const [uploadUrl, setUploadUrl] = useState("");
  const [isEditPhotoDetails, setIsEditPhotoDetails] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>("");

  const { createLabor } = useCreateLabor();

  const { createUploadUrl } = useCreateUploadUrl();

  const viewRowDetails = (rowData: LaborItems) => {
    setViewRowDetailsData(rowData);
    handleViewRowDetailsDrawerOpen();
  };

  const handleFileChange = (value: string, name: string) => {
    setLaborPhotoFormField((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const getUplaodUrl = async () => {
    const input: CreateUploadFileInput = {
      contentType: "image/jpeg",
    };

    const success = await createUploadUrl(input);
    if (success) {
      setUploadUrl(success.url);
      setFileUrl(success.fileName);
      return;
    } else {
      console.error("Failed to create Upload url.");
    }
  };

  const handleOpenFileDrawer = () => {
    setOpenPhotoFormDrawer(true);
    if (isEditPhotoDetails) {
      setLaborPhotoFormField({
        name: "",
        description: "",
        url: "",
        fileType: null,
      });
      setIsEditPhotoDetails(false);
    }
  };

  const handleSelectFileType = (event, selectedFileType) => {
    if (selectedFileType) {
      setLaborPhotoFormField((prevItem) => ({
        ...prevItem,
        fileType: selectedFileType.label,
      }));
    }
  };

  const handleCancelFileForm = () => {
    setOpenPhotoFormDrawer(false);
    setUploadUrl("");
    setIsEditPhotoDetails(false);
    setLaborPhotoFormField({
      name: "",
      description: "",
      url: "",
      fileType: null,
    });
  };

  const handlePhotoFormData = async () => {
    const file = fileRef.current?.files[0];

    const validationRules = [
      {
        condition: !laborPhotoFormField.name,
        message: "File name is required",
      },
      {
        condition: !laborPhotoFormField.description,
        message: "File description is required",
      },
      {
        condition: !laborPhotoFormField.fileType,
        message: "File fileType is required",
      },
      {
        condition: !file,
        message: "A file have not been selected",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    try {
      const result = await uploadImage(uploadUrl, file);
      if (result.success) {
        let photoFormData: ContractorFileInput = {
          name: laborPhotoFormField.name,
          description: laborPhotoFormField.description,
          url: fileUrl,
          fileType: laborPhotoFormField.fileType,
        };

        setPhotoFormDataArr((prevPhotoFormData) => [
          ...prevPhotoFormData,
          photoFormData,
        ]);

        setLaborPhotoFormField({
          name: "",
          description: "",
          url: "",
          fileType: null,
        });
        setOpenPhotoFormDrawer(false);
        setOpenPhotoFormDrawer(false);
        setUploadUrl("");
      }
    } catch (error) {
      console.error("Error uploading image ", error);
    }
  };

  const handleSubmitEditPhotoFormData = async () => {
    const file = fileRef.current?.files[0];

    try {
      if (file) {
        const result = await uploadImage(uploadUrl, file);
        if (result.success) {
          let photoFormData: ContractorFileInput = {
            name: laborPhotoFormField.name,
            description: laborPhotoFormField.description,
            url: fileUrl,
            fileType: laborPhotoFormField.fileType,
          };

          const updatedPhotoFormDataArr = photoFormDataArr.filter(
            (photoData) => photoData.url !== laborEditPhotoDetails.url
          );

          updatedPhotoFormDataArr.push(photoFormData);

          setPhotoFormDataArr(updatedPhotoFormDataArr);

          setLaborPhotoFormField({
            name: "",
            description: "",
            url: "",
            fileType: null,
          });
          setOpenPhotoFormDrawer(false);
          setUploadUrl("");
          setIsEditPhotoDetails(false);
        }
      } else {
        let photoFormData: ContractorFileInput = {
          name: laborPhotoFormField.name,
          description: laborPhotoFormField.description,
          url: laborEditPhotoDetails.url,
          fileType: laborPhotoFormField.fileType,
        };

        const updatedPhotoFormDataArr = photoFormDataArr.filter(
          (photoData) => photoData.url !== laborEditPhotoDetails.url
        );

        updatedPhotoFormDataArr.push(photoFormData);

        setPhotoFormDataArr(updatedPhotoFormDataArr);

        setLaborPhotoFormField({
          name: "",
          description: "",
          url: "",
          fileType: null,
        });
        setOpenPhotoFormDrawer(false);
        setUploadUrl("");
        setIsEditPhotoDetails(false);
      }
    } catch (error) {
      console.error("Error uploading image ", error);
    }
  };

  const handleViewRowDetailsDrawerOpen = () => {
    setOpenRowDetailsDrawer(true);
  };

  const handleViewRowDetailsDrawerClose = () => {
    setOpenRowDetailsDrawer(false);
  };

  const handleSelectContractorParentId = (event, selectedContractor) => {
    if (selectedContractor) {
      setLaborItemsFormField((prevItem) => ({
        ...prevItem,
        contractorId: selectedContractor.id,
      }));
    }
  };

  const getContractorParentIdsOptions = (
    sampleDataArray: any[]
  ): { id: number; label: string }[] => {
    return sampleDataArray.map((item) => ({ id: item.id, label: item.name }));
  };

  const handleSelectLaborMeasurementParentId = (event, selectedMeasurement) => {
    if (selectedMeasurement) {
      setLaborItemsFormField((prevItem) => ({
        ...prevItem,
        measurementId: selectedMeasurement.id,
      }));
    }
  };

  const getLaborMeasurementParentIdsOptions = (
    sampleDataArray: any[]
  ): { id: number; label: string }[] => {
    return sampleDataArray.map((item) => ({ id: item.id, label: item.name }));
  };

  const handleChange = (value: string, name: string) => {
    setLaborItemsFormField((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterLaborItems = () => {
    if (keyword.length > 0 && Array.isArray(laborItemsData)) {
      return laborItemsData.filter((item) => {
        const laborMeasurementUnit = `${item.measurementUnit}`;
        const laborContractorId = `${item.contractorId}`;
        return (
          laborMeasurementUnit.toLowerCase().includes(keyword.toLowerCase()) ||
          laborContractorId.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    }
    return laborItemsData;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterLaborItems().length / rowsPerPage) - 1
      )
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const handleEditPhotoFormData = (rowData: ContractorFileInput) => {
    const { name, description, url, fileType } = rowData;
    setLaborEditPhotoDetails(rowData);
    setLaborPhotoFormField({
      name,
      description,
      url,
      fileType,
    });
    setOpenPhotoFormDrawer(true);
    setIsEditPhotoDetails(true);
  };

  const onTapSubmitForm = async () => {
    const validationRules = [
      {
        condition: !laborItemsFormField.description,
        message: "Please Enter the labor item description",
      },
      {
        condition: !laborItemsFormField.price,
        message: "Please Enter the labor price",
      },
      {
        condition: !laborItemsFormField.measurementUnit,
        message: "Please Enter the measurement unit",
      },
      {
        condition: !laborItemsFormField.coverageAmount,
        message: "Please Enter the coverage amount",
      },
      {
        condition: !laborItemsFormField.contractorId,
        message: "Please Enter the contractor parent id",
      },
      {
        condition: !laborItemsFormField.measurementId,
        message: "Please Enter the labor measurement parent id",
      },
      {
        condition: !extractCheckedIds(checkboxStates).length,
        message: "Please select a minimum of one product system!",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    const checkedParentIds = extractCheckedIds(checkboxStates);

    const input: CreateLaborInput = {
      description: laborItemsFormField.description,
      price: laborItemsFormField.price,
      measurementUnit: laborItemsFormField.measurementUnit,
      coverageAmount: laborItemsFormField.coverageAmount,
      contractorId: laborItemsFormField.contractorId,
      measurementId: laborItemsFormField.measurementId,
      parentIds: checkedParentIds,
      files: photoFormDataArr,
    };

    const success = await createLabor(input);

    if (success) {
      toast("Labor item created successfully.", {
        type: "success",
        className: "toast-message",
        position: "top-center",
      });

      setLaborItemsFormField({
        description: "",
        price: null,
        measurementUnit: "",
        coverageAmount: null,
        contractorId: null,
        measurementId: null,
        parentIds: [],
        files: [],
      });
      setPhotoFormDataArr([]);
      handleDrawerClose();
      refetchLabor();
      setCheckboxStates({});
      return;
    } else {
      console.error("Failed to create labor item.");
    }
  };

  const createLaborItemsForm = () => {
    return (
      <ContractorDrawer open={openDrawer} onClose={handleDrawerClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={handleDrawerClose}
            labelTitle="Create new labor pricing"
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <ColDiv
                style={{
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <FormTitleTypography>
                    Labor pricing description
                  </FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter description"
                  onChange={handleChange}
                  required={true}
                  value={laborItemsFormField.description}
                  name="description"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <FormTitleTypography>Price</FormTitleTypography>
                </Box>
                <TxtInput
                  type={"number"}
                  placeholder="Enter labor price"
                  onChange={handleChange}
                  required={true}
                  value={laborItemsFormField.price}
                  name="price"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <FormTitleTypography>Measurement Unit</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter unit"
                  onChange={handleChange}
                  required={true}
                  value={laborItemsFormField.measurementUnit}
                  name="measurementUnit"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <FormTitleTypography>Coverage Amount</FormTitleTypography>
                </Box>
                <TxtInput
                  type={"number"}
                  placeholder="Enter amount"
                  onChange={handleChange}
                  required={true}
                  value={laborItemsFormField.coverageAmount}
                  name="coverageAmount"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <FormTitleTypography
                  style={{
                    display: "felx",
                    width: "60%",
                    justifyContent: "start",
                    textAlign: "start",
                  }}
                >
                  Select Associated Contractor
                </FormTitleTypography>
                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    background: "inherit",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={getContractorParentIdsOptions(contractors)}
                      sx={{ width: "100%" }}
                      onChange={handleSelectContractorParentId}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </Box>
                </Box>
                <FormTitleTypography
                  style={{
                    display: "felx",
                    width: "60%",
                    justifyContent: "start",
                    textAlign: "start",
                  }}
                >
                  Select Associated Labor Measurement
                </FormTitleTypography>
                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    background: "inherit",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={getLaborMeasurementParentIdsOptions(
                        materialMeasurements
                      )}
                      sx={{ width: "100%" }}
                      onChange={handleSelectLaborMeasurementParentId}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Lbl
                    title="Associated product systems"
                    size={16}
                    width={80}
                    bold={600}
                  />
                </Box>
                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "inherit",
                    borderRadius: "10px",
                    border: "1px solid rgba(173, 173, 173, 1)",
                    height: "fit-content",
                    minHeight: "50px",
                    paddingY: "10px",
                  }}
                  onClick={() =>
                    setShowProductSystemParent(!showProductSystemParent)
                  }
                >
                  <RowDiv
                    style={{
                      width: "100%",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Lbl
                        title="Select associated product systems"
                        size={16}
                        mobileFontSize={16}
                        isFullWidth={true}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "40%",
                        alignItems: "center",
                        justifyContent: "end",
                        paddingRight: "20px",
                      }}
                    >
                      {showProductSystemParent ? (
                        <ArrowIcon src={UpArrowIcon} />
                      ) : (
                        <ArrowIcon src={DownArrowIcon} />
                      )}
                    </Box>
                  </RowDiv>

                  {showProductSystemParent && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "start",
                        width: "100%",
                        paddingX: "20px",
                      }}
                    >
                      {productsIds.map((item) => (
                        <Box
                          key={`checkProductsSystemParent ${item.id}`}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            cursor: "pointer",
                            justifyContent: "start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <ChkInputBox
                            onChange={(value: boolean) => {
                              setCheckboxStates((prevCheckboxStates) => ({
                                ...prevCheckboxStates,
                                [item.id]: value,
                              }));
                              setShowProductSystemParent(true);
                            }}
                            placeholder=""
                            width={50}
                            marginTop={0}
                            marginBottom={0}
                            height={20}
                            background="inherit"
                            checked={checkboxStates[item.id] || false}
                          />
                          <Typography>{item.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                    marginTop: "10px",
                  }}
                >
                  <FormTitleTypography>Files</FormTitleTypography>
                  <StandardIcon src={plusIcon} onClick={handleOpenFileDrawer} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingY: "10px",
                  }}
                >
                  {photoFormDataArr.length > 0 && (
                    <LeadsTableHeaderCard
                      headers={FILE_TABLE_HEADER_LIST}
                      fontSize={"14px"}
                    />
                  )}
                  {photoFormDataArr.map((labor, laborIndex) => (
                    <TableWithoutHeader
                      key={`Labor file ${laborIndex}`}
                      containerWidth="100%"
                      bodyContent={[
                        {
                          text: `${laborIndex + 1}`,
                          width: "10%",
                          fontSize: "12px",
                        },
                        {
                          text: `${labor.name}`,
                          width: "15%",
                          fontSize: "12px",
                        },
                        {
                          text: `${labor.description}`,
                          width: "30%",
                          fontSize: "12px",
                        },
                        {
                          text: `${labor.fileType}`,
                          width: "20%",
                        },
                        {
                          text: (
                            <a
                              href={`${labor.url}`}
                              target="_blank"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                fontSize: 15,
                                color: AppCSS.GLAS_BLUE,
                                backgroundColor: AppCSS.GREEN_MILD,
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                              rel="noreferrer"
                              download={false}
                            >
                              <p>View File</p>
                            </a>
                          ),
                          width: "15%",
                        },
                        {
                          text: "",
                          width: "10%",
                          justifyContent: "end",
                          isAction: true,
                          actionComponent: (
                            <>
                              <Box
                                onClick={() => {
                                  handleEditPhotoFormData(labor);
                                }}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  paddingRight: "20px",
                                  cursor: "pointer",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <StandardIcon src={editIcon} />
                              </Box>
                            </>
                          ),
                        },
                      ]}
                    />
                  ))}
                </Box> */}
                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleDrawerClose}
          onCreate={onTapSubmitForm}
        />
        <Spacer size={2} direction="col" />
      </ContractorDrawer>
    );
  };

  const createFileForm = () => {
    return (
      <ContractorDrawer
        open={openPhotoFormDrawer}
        onClose={() => {
          setOpenPhotoFormDrawer(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={() => {
              setOpenPhotoFormDrawer(false);
            }}
            labelTitle={
              isEditPhotoDetails
                ? "Edit Photo Details"
                : "Add file to labor pricing"
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <ColDiv
                style={{
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>File name</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter the file name"
                  onChange={handleFileChange}
                  required={true}
                  value={laborPhotoFormField.name}
                  name="name"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>File Description</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter file description"
                  onChange={handleFileChange}
                  required={true}
                  value={laborPhotoFormField.description}
                  name="description"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    background: "inherit",
                    borderRadius: "10px",
                    border: "1px solid rgba(173, 173, 173, 1)",
                  }}
                >
                  <FormTitleTypography
                    style={{
                      display: "felx",
                      width: "60%",
                      justifyContent: "start",
                      textAlign: "start",
                      paddingLeft: "10px",
                    }}
                  >
                    Select File Type
                  </FormTitleTypography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "40%",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={SELECT_CONTRACTOR_FILETYPE}
                      sx={{ width: "100%" }}
                      onChange={handleSelectFileType}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                    marginTop: "10px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Upload File</FormTitleTypography>
                </Box>

                <Box
                  sx={{
                    width: "96%",
                    marginX: "2%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    background: AppCSS.GRAY_MILD,
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Lbl title="Upload file" size={16} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SFileUpload
                      style={{
                        border: uploadUrl === "" ? `3px solid #A9CBB7` : "none",
                        marginLeft: 10,
                        height: "35px",
                        width: "100px",
                      }}
                      onClick={() => {
                        if (fileRef.current) {
                          fileRef.current.click();
                        }
                      }}
                    >
                      <input
                        type="file"
                        id="file"
                        ref={fileRef}
                        onChange={() => {
                          if (uploadUrl === "") {
                            getUplaodUrl();
                          }
                        }}
                        accept={"image/png, image/jpeg, image/jpg,.pdf"}
                        style={{
                          display: "none",
                        }}
                      />
                      <FileOpen
                        titleAccess="Select File"
                        color="warning"
                        style={{
                          width: 32,
                          height: 32,
                          color: AppCSS.GLAS_GREEN,
                        }}
                      />
                    </SFileUpload>
                  </Box>
                </Box>
                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleCancelFileForm}
          onCreate={
            isEditPhotoDetails
              ? handleSubmitEditPhotoFormData
              : handlePhotoFormData
          }
          submitText={
            isEditPhotoDetails ? "Update file details" : "Add file details"
          }
        />
      </ContractorDrawer>
    );
  };

  const showLaborItemsRowDetails = () => {
    return (
      <ViewRowDetailsDrawer
        openDrawer={openRowDetailsDrawer}
        handleDrawerClose={handleViewRowDetailsDrawerClose}
        rowDetailsData={viewRowDetailsData}
        labelTitle="Labor items Details"
        rowArrayTableComponent={(value: ContractorFile[], key?: string) => (
          <FileUploadTable value={value} key={key} />
        )}
        rowArrayTableComponentTwo={(
          value: ProductSystemForMiscField[],
          key?: string
        ) => <ProductSystemParentIdsTable value={value} key={key} />}
        detailsOfRowToBeDisplay={LABOR_ITEMS_DRAWER_DETAILS}
      />
    );
  };

  const LaborItemsTable = () => {
    return (
      <Container>
        <ColDiv
          style={{
            width: "100%",
            gap: "10px",
          }}
        >
          <ColDiv
            style={{
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "151px",
            }}
          >
            {" "}
            <SearchAndCreateComponent
              handleSearchIconClick={handleSearchIconClick}
              handleDrawerOpen={() => {
                handleDrawerOpen();
              }}
              setKeyword={setKeyword}
              inputRef={inputRef}
              searchPlaceHolder="Search labor pricing"
            />
            <Box
              sx={{
                height: "calc(100% - 65px)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeadsTableHeaderCard headers={LABOR_ITEMS_TABLE_HEADER_LIST} />
            </Box>
          </ColDiv>

          {filterLaborItems().length === 0 ? (
            <EmptyTable message="No labor items available" />
          ) : (
            filterLaborItems()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((laborItems, laborItemsIndex) => (
                <LeadsTableBodyCard
                  key={`Contractor ${laborItems.id}`}
                  containerWidth="100%"
                  bodyContent={[
                    {
                      text: `${laborItems.id}`,
                      width: "5%",
                      fontSize: "15px",
                      paddingLeft: "10px",
                    },
                    {
                      text: `${laborItems.description}`,
                      width: "20%",
                      fontSize: "15px",
                    },
                    {
                      text: (
                        <NumericFormat
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          value={laborItems.price}
                          prefix="$"
                          thousandSeparator
                        />
                      ),
                      width: "15%",
                      fontSize: "15px",
                    },
                    {
                      text: (
                        <NumericFormat
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          value={laborItems.coverageAmount}
                          thousandSeparator
                        />
                      ),
                      width: "20%",
                      fontSize: "15px",
                    },
                    {
                      text: `${laborItems.measurementUnit}`,
                      width: "25%",
                      fontSize: "15px",
                    },

                    {
                      text: "",
                      width: "15%",
                      isAction: true,
                      justifyContent: "end",
                      actionComponent: (
                        <TableViewDetails
                          rowData={laborItems}
                          onViewClick={viewRowDetails}
                          text={"View"}
                        />
                      ),
                    },
                  ]}
                />
              ))
          )}
        </ColDiv>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          filterData={filterLaborItems}
        />
      </Container>
    );
  };

  const laborItemsContainer = () => {
    return (
      <Container
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <ColDiv
          style={{
            justifyContent: "start",
          }}
        >
          {Array.isArray(laborItemsData) && LaborItemsTable()}
        </ColDiv>
        {createLaborItemsForm()}
        {showLaborItemsRowDetails()}
        {createFileForm()}
      </Container>
    );
  };

  return laborItemsContainer();
};
