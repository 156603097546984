import { useMutation } from "@apollo/client";
import {
  CreateContractorChangeOrderResponse,
  CreateContractorInput,
  CreateContractorOrderInput,
  CreateContractorReportInput,
  CreateContractorReportResponse,
  CreateContractorResponse,
  CreateLaborInput,
  CreateLaborMeasurementInput,
  CreateLaborMeasurementResponse,
  CreateLaborResponse,
  CreateMaterialCategoryInput,
  CreateMaterialCategoryResponse,
  CreateMaterialInput,
  CreateMaterialMeasurementInput,
  CreateMaterialMeasurementResponse,
  CreateMaterialResponse,
  CreateMiscFieldInput,
  CreateMiscFieldResponse,
  CreateProductSystemInput,
  CreateProductSystemResponse,
} from "../../../types/graphQlQuery.interface";
import {
  CREATE_CONTRACTOR,
  CREATE_CONTRACTOR_ORDER,
  CREATE_CONTRACTOR_REPORT,
  CREATE_LABOR,
  CREATE_LABOR_MEASUREMENT,
  CREATE_MATERIAL,
  CREATE_MATERIAL_CATEGORY,
  CREATE_MATERIAL_MEASUREMENT,
  CREATE_MISC_FIELD,
  CREATE_PRODUCT_SYSTEM_MUTATION,
} from "../../operation/mutations";

export const useCreateProductSystem = () => {
  const [createProductSystemMutation] = useMutation<
    CreateProductSystemResponse,
    { input: CreateProductSystemInput }
  >(CREATE_PRODUCT_SYSTEM_MUTATION);

  const createProductSystem = async (input: CreateProductSystemInput) => {
    try {
      const { data } = await createProductSystemMutation({
        variables: { input },
      });
      return data?.createProductSystem ?? false;
    } catch (error) {
      console.error("Error creating product system:", error);
      return false;
    }
  };

  return { createProductSystem };
};

export const useCreateMaterialCategory = () => {
  const [createMaterialCategoryMutation] = useMutation<
    CreateMaterialCategoryResponse,
    { input: CreateMaterialCategoryInput }
  >(CREATE_MATERIAL_CATEGORY);

  const createMaterialCategory = async (input: CreateMaterialCategoryInput) => {
    try {
      const { data } = await createMaterialCategoryMutation({
        variables: { input },
      });
      return data?.createMaterialCategory ?? false;
    } catch (error) {
      console.error("Error creating material category:", error);
      return false;
    }
  };

  return { createMaterialCategory };
};

export const useCreateMiscField = () => {
  const [createMiscField] = useMutation<
    CreateMiscFieldResponse,
    { input: CreateMiscFieldInput }
  >(CREATE_MISC_FIELD);

  const createMiscFields = async (input: CreateMiscFieldInput) => {
    try {
      const { data } = await createMiscField({
        variables: { input },
      });
      return data?.createMiscField ?? false;
    } catch (error) {
      console.error("Error creating misc field:", error);
      return false;
    }
  };

  return { createMiscFields };
};

export const useCreateLaborMeasurement = () => {
  const [createLaborMeasurementMutation] = useMutation<
    CreateLaborMeasurementResponse,
    { input: CreateLaborMeasurementInput }
  >(CREATE_LABOR_MEASUREMENT);

  const createLaborMeasurement = async (input: CreateLaborMeasurementInput) => {
    try {
      const { data } = await createLaborMeasurementMutation({
        variables: { input },
      });
      return data?.createLaborMeasurement ?? false;
    } catch (error) {
      console.error("Error creating labor measurement:", error);
      return false;
    }
  };

  return { createLaborMeasurement };
};

export const useCreateMaterialMeasurement = () => {
  const [createMaterialMeasurementMutation] = useMutation<
    CreateMaterialMeasurementResponse,
    { input: CreateMaterialMeasurementInput }
  >(CREATE_MATERIAL_MEASUREMENT);

  const createMaterialMeasurement = async (
    input: CreateMaterialMeasurementInput
  ) => {
    try {
      const { data } = await createMaterialMeasurementMutation({
        variables: { input },
      });
      return data?.createMaterialMeasurement ?? false;
    } catch (error) {
      console.error("Error creating material measurement:", error);
      return false;
    }
  };

  return { createMaterialMeasurement };
};

export const useCreateMaterial = () => {
  const [createMaterialMutation] = useMutation<
    CreateMaterialResponse,
    { input: CreateMaterialInput }
  >(CREATE_MATERIAL);

  const createMaterial = async (input: CreateMaterialInput) => {
    try {
      const { data } = await createMaterialMutation({
        variables: { input },
      });
      return data?.createMaterial ?? false;
    } catch (error) {
      console.error("Error creating material:", error);
      return false;
    }
  };

  return { createMaterial };
};

export const useCreateContractorReports = () => {
  const [createContractorReportsMutation] = useMutation<
    CreateContractorReportResponse,
    { input: CreateContractorReportInput }
  >(CREATE_CONTRACTOR_REPORT);

  const createContractorReport = async (input: CreateContractorReportInput) => {
    try {
      const { data } = await createContractorReportsMutation({
        variables: { input },
      });
      return data?.createContractorReport ?? false;
    } catch (error) {
      console.error("Error creating material:", error);
      return false;
    }
  };

  return { createContractorReport };
};

export const useCreateContractor = () => {
  const [createContractorMutation] = useMutation<
    CreateContractorResponse,
    { input: CreateContractorInput }
  >(CREATE_CONTRACTOR);

  const createContractor = async (input: CreateContractorInput) => {
    try {
      const { data } = await createContractorMutation({
        variables: { input },
      });
      return data?.createContractor ?? false;
    } catch (error) {
      console.error("Error creating material:", error);
      return false;
    }
  };

  return { createContractor };
};

export const useCreateLabor = () => {
  const [createLaborMutation] = useMutation<
    CreateLaborResponse,
    { input: CreateLaborInput }
  >(CREATE_LABOR);

  const createLabor = async (input: CreateLaborInput) => {
    try {
      const { data } = await createLaborMutation({
        variables: { input },
      });
      return data?.createLabor ?? false;
    } catch (error) {
      console.error("Error creating labor:", error);
      return false;
    }
  };

  return { createLabor };
};

export const useCreateContractorChangeOrder = () => {
  const [createContractorChangeOrderMutation] = useMutation<
    CreateContractorChangeOrderResponse,
    { input: CreateContractorOrderInput }
  >(CREATE_CONTRACTOR_ORDER);

  const createContractorChangeOrder = async (
    input: CreateContractorOrderInput
  ) => {
    try {
      const { data } = await createContractorChangeOrderMutation({
        variables: { input },
      });
      return data?.createContractorOrder ?? false;
    } catch (error) {
      console.error("Error creating contractor order:", error);
      return false;
    }
  };

  return { createContractorChangeOrder };
};
