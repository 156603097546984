/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import * as React from "react";
import { useDispatch } from "react-redux";
import { Container } from "../../../../globalstyled";
import {
  MatColor,
  Material,
  UpdateMaterial,
} from "../../../../redux/types/Material";
import { Chip, IconButton } from "@mui/material";
import PlaceHolder from "../../../../images/placeholder.jpeg";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import {
  GetMaterials,
  UpdateCurrentMaterial,
} from "../../../../redux/api/materials";
import { toast } from "react-toastify";
import { setMaterials } from "../../../../redux/reducers";
import {
  MATERIALS_COLOR_TABLE_HEADER,
  MATERIALS_ROOF_TABLE_HEADER,
} from "../MaterialsSectionHeader";
import MaterialRoofTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import MaterialRoofTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import { NumericFormat } from "react-number-format";
import MaterialEditText from "../../../../components/DashboardComponent/MaterialEditText";
import EmptyTable from "../../../../components/table/EmptyTable";

interface UserTableProps {
  materials: Material[];
  onTapAddCompany: Function;
}

export const MaterialTable: React.FC<UserTableProps> = ({
  materials,
  onTapAddCompany,
}) => {
  const dispatch = useDispatch();
  const [editRoof, setEditRoof] = useState(false);
  const [currentRoof, setCurrentRoof] = useState<Material>();
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [unit, setUnit] = useState("");
  const [home_photo, setHomePhoto] = useState("");
  const [warranty, setWarranty] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");

  const [viewColors, setViewColors] = React.useState(false);
  const [colors, setColors] = React.useState<MatColor[]>([]);

  const resetForm = () => {
    setBrand("");
    setProduct("");
    setDescription("");
    setPrice("");
    setUnit("");
    setHomePhoto("");
    setWarranty("");
  };

  const onFetchMaterials = async () => {
    try {
      dispatch(setMaterials([]));
      const data = await GetMaterials("roof");
      const userData = data as {
        message: string;
        data: {
          materials: Material[];
        };
      };
      if (Array.isArray(userData.data.materials)) {
        dispatch(setMaterials(userData.data.materials));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onTapSave = async () => {
    if (!currentRoof) {
      toast("Error while updating roof!", {
        type: "error",
      });
      return;
    }
    const input: UpdateMaterial = {
      price,
      product,
      description,
      unit,
      warranty,
    };
    try {
      const result = await UpdateCurrentMaterial(currentRoof.id, input);
      if (result === 200) {
        toast("Shingles Updated Successfully!", {
          type: "success",
        });
        await onFetchMaterials();
        setEditRoof(false);
        resetForm();
      } else {
        toast("Error while updating Shingles!", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterMaterialRoofs = () => {
    if (keyword.length > 0 && Array.isArray(materials)) {
      return materials.filter((item) => {
        return item.brand.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return materials;
  };

  const viewImage = (imageName?: string) => {
    return imageName ? imageName : PlaceHolder;
  };

  const MaterialColors = (colors: MatColor[]) => {
    return (
      <ColDiv
        style={{
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          marginBottom: "1%",
          gap: "10px",
        }}>
        <MaterialRoofTableHeaderCard headers={MATERIALS_COLOR_TABLE_HEADER} />

        {colors.map((row, index) => {
          const key = `key-Material-Color-${index}`;
          return (
            <MaterialRoofTableBodyCard
              key={key}
              bodyContent={[
                { text: row.color, width: "10%" },
                {
                  text: (
                    <p>
                      <img
                        src={viewImage(row.home_photo)}
                        width={80}
                        height={60}
                        alt="logo"
                      />
                    </p>
                  ),
                  width: "40%",
                  justifyContent: "center",
                },
                {
                  text: (
                    <p>
                      <img
                        src={row.close_up_photo}
                        width={80}
                        height={60}
                        alt="logo"
                      />
                    </p>
                  ),
                  width: "40%",
                  justifyContent: "center",
                },
                {
                  text: (
                    <IconButton
                      aria-label=""
                      style={{
                        display: "flex",
                        width: 48,
                        height: 48,
                        border: 1,
                        borderColor: AppCSS.GRAY_MILD,
                      }}>
                      <EditIcon style={{ color: AppCSS.RED }} />
                    </IconButton>
                  ),
                  width: "10%",
                  justifyContent: "center",
                },
              ]}
            />
          );
        })}
      </ColDiv>
    );
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "product", headerName: "Product Name", width: 130 },
    { field: "description", headerName: "Description", width: 300 },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 90,
    },
    {
      field: "Colors",
      headerName: "Colors",
      width: 90,
    },
    {
      field: "action",
      headerName: "Action",
      width: 90,
    },
  ];

  const EditRoofForm = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            marginTop: 20,
            background: "#fff",
            boxShadow: "1px 1px 5px 1px #DBDBDB",
            borderRadius: 5,
            paddingTop: 10,
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 20,
            marginBottom: "5%",
          }}>
          <MaterialEditText text={"Edit Gutter"} />
          {/* Selected Action row */}
          <Spacer direction="col" />
          <RowDiv
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}>
            <ColDiv>
              <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
              <TxtInput
                value={product}
                placeholder="Product"
                onChange={setProduct}
              />
            </ColDiv>
            <Spacer size={2} direction="row" />
            <ColDiv>
              <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
              <TxtInput
                value={description}
                placeholder="Description"
                onChange={setDescription}
              />
            </ColDiv>
          </RowDiv>
          <Spacer size={2} direction="col" />
          <RowDiv>
            <ColDiv>
              <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
              <TxtInput value={price} placeholder="Price" onChange={setPrice} />
            </ColDiv>
            <Spacer size={2} direction="row" />

            <ColDiv>
              <Lbl title="Warranty" color={AppCSS.GRAY_DARK} size={13} />
              <TxtInput
                value={warranty}
                placeholder="Warranty"
                onChange={setWarranty}
              />
            </ColDiv>
          </RowDiv>
          <Spacer size={2} direction="col" />

          <RowDiv
            style={{
              width: "80%",
              maxWidth: "900px",
              display: "row",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
            <TapButton
              title="Cancel"
              onTap={() => {
                setCurrentRoof(undefined);
                setEditRoof(false);
              }}
              width={120}
              bgColor={AppCSS.WHITE}
              borderColor={AppCSS.GLAS_BLUE}
              color={AppCSS.GLAS_BLUE}
              height={40}
            />
            <TapButton
              title={"Save"}
              bgColor={AppCSS.GLAS_BLUE}
              height={40}
              onTap={() => onTapSave()}
              width={120}
            />
            <Spacer size={5} direction="row" />
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  const MaterialsRoofsTable = () => {
    return (
      <ColDiv style={{ marginBottom: "5%" }}>
        <RowDiv
          style={{
            marginTop: 20,
            justifyContent: "flex-start",
            width: "100%",
          }}>
          <TxtInput
            width={280}
            placeholder={`Search Material Brand`}
            onChange={setKeyword}
          />
          <RowDiv style={{ justifyContent: "flex-end" }}>
            <TapButton
              title={"Add Shingles"}
              bgColor={AppCSS.WHITE}
              borderColor={AppCSS.GLAS_BLUE}
              color={AppCSS.GLAS_BLUE}
              onTap={() => onTapAddCompany()}
              width={120}
              height={40}
            />
          </RowDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        <ColDiv
          style={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            marginBottom: "1%",
            gap: "10px",
          }}>
          <MaterialRoofTableHeaderCard headers={MATERIALS_ROOF_TABLE_HEADER} />

          {filterMaterialRoofs().length === 0 ? (
            <EmptyTable message="No roof material available" />
          ) : (
            filterMaterialRoofs().map((row, index) => {
              const key = `key-Material-Roof-${index}`;
              return (
                <MaterialRoofTableBodyCard
                  key={key}
                  bodyContent={[
                    { text: row.brand, width: "10%" },
                    {
                      text: (
                        <>
                          {row.warranty ? (
                            <Chip
                              label={"View"}
                              variant="outlined"
                              onClick={() =>
                                window.open(`${row.warranty}`, "__blank")
                              }
                              style={{ background: AppCSS.GLAS_GREEN }}
                            />
                          ) : (
                            <p>N/A</p>
                          )}
                        </>
                      ),
                      width: "10%",
                      justifyContent: "center",
                    },
                    { text: row.product, width: "20%" },
                    {
                      text: (
                        <p
                          style={{
                            color: AppCSS.BLACK,
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            fontSize: "0.9rem",
                            margin: 0,
                            padding: "10px",
                          }}>
                          {row.description}
                        </p>
                      ),
                      width: "30%",
                    },
                    {
                      text: (
                        <NumericFormat
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                          }}
                          value={row.price}
                          prefix="$"
                          thousandSeparator
                        />
                      ),
                      width: "10%",
                    },
                    {
                      text: (
                        <Chip
                          label={`${
                            Array.isArray(row.Colors) ? row.Colors.length : 0
                          } Items`}
                          variant="outlined"
                          onClick={() => {
                            setColors(row.Colors);
                            setViewColors(true);
                          }}
                          style={{ width: 100, background: AppCSS.GLAS_GREEN }}
                        />
                      ),
                      width: "10%",
                      justifyContent: "center",
                    },
                    {
                      text: (
                        <IconButton
                          onClick={() => {
                            setPrice(row.price);
                            setProduct(row.product);
                            setDescription(row.description);
                            setCurrentRoof(row);
                            setWarranty(row.warranty);
                            setEditRoof(true);
                          }}
                          aria-label=""
                          style={{
                            display: "flex",
                            width: 20,
                            height: 20,
                            border: 1,
                            borderColor: AppCSS.GRAY_MILD,
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <EditIcon style={{ color: AppCSS.GLAS_GREEN }} />
                        </IconButton>
                      ),
                      width: "10%",
                      justifyContent: "center",
                    },
                  ]}
                  containerHeight="40px"
                />
              );
            })
          )}
        </ColDiv>
      </ColDiv>
    );
  };

  const displayMaterials = () => {
    return (
      <DataGrid
        rows={materials}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    );
  };

  const materialsContainer = () => {
    return (
      <Container>
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}>
            {/* {displayMaterials()} */}
            {Array.isArray(materials) && MaterialsRoofsTable()}
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  const colorsContainer = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          <RowDiv>
            <Lbl title="Color Variants" size={22} />
            <TapButton
              title="← Materials"
              onTap={() => {
                setViewColors(false);
                setColors([]);
              }}
              bgColor={AppCSS.RED_MILD}
              borderColor={AppCSS.WHITE}
              color={AppCSS.RED}
              width={120}
              radius={10}
            />
          </RowDiv>
          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}>
            <Spacer direction="col" />
            {Array.isArray(colors) && MaterialColors(colors)}
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  if (editRoof) {
    return EditRoofForm();
  } else if (viewColors) {
    return colorsContainer();
  } else {
    return materialsContainer();
  }
};
