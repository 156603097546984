import { RowDetailsDrawerDetails } from "../types/type";

export const PRODUCT_SYSTEM_DRAWER_DETAILS: RowDetailsDrawerDetails[] = [
  { label: "Name", key: "name", isDate: false, isArray: false },
  {
    label: "Duration of Project",
    key: "duration",
    isDate: false,
    isArray: false,
  },
  { label: "Priority", key: "priority", isDate: false, isArray: false },
  {
    label: "Payment Method",
    key: "paymentMethods",
    isDate: false,
    isArray: true,
    isColumn: true,
    componentNumber: "two",
  },
  {
    label: "Product Photo",
    key: "thumbnail",
    isDate: false,
    isArray: true,
    isColumn: true,
  },
];

export const MISC_FIELDS_DRAWER_DETAILS: RowDetailsDrawerDetails[] = [
  { label: "ID", key: "id", isDate: false, isArray: false },

  { label: "Name", key: "name", isDate: false, isArray: false },
  {
    label: "For Customer Display",
    key: "showToCustomer",
    isDate: false,
    isArray: false,
  },
  {
    label: "Product System Parent Ids",
    key: "parents",
    isDate: false,
    isArray: true,
    isColumn: false,
  },
];

export const MATERIAL_MEASUREMENT_DRAWER_DETAILS: RowDetailsDrawerDetails[] = [
  { label: "ID", key: "id", isDate: false, isArray: false },

  { label: "Name", key: "name", isDate: false, isArray: false },
  {
    label: "Measurement Unit",
    key: "unit",
    isDate: false,
    isArray: false,
  },
  {
    label: "Material Category Parent Ids",
    key: "categoryId",
    isDate: false,
    isArray: false,
  },
  {
    label: "Product System Parent Ids",
    key: "parents",
    isDate: false,
    isArray: true,
    isColumn: false,
  },
];

export const MATERIAL_ITEMS_DRAWER_DETAILS: RowDetailsDrawerDetails[] = [
  { label: "ID", key: "id", isDate: false, isArray: false },

  { label: "Name", key: "name", isDate: false, isArray: false },
  {
    label: "Brand",
    key: "brand",
    isDate: false,
    isArray: false,
  },
  {
    label: "Description",
    key: "description",
  },
  {
    label: "Quality",
    key: "quality",
  },
  {
    label: "Price",
    key: "price",
    isAmount: true,
  },
  {
    label: "Measurement Unit",
    key: "measurementUnit",
  },
  {
    label: "Coverage Amount",
    key: "coverageAmount",
    isAmount: true,
  },
  {
    label: "Material Measurement Parent Id",
    key: "measurement",
    isDate: false,
    isArray: false,
    isObject: true,
    isColumn: true,
    objectDetails: [
      {
        id: "id",
      },
      {
        name: "name",
      },
    ],
  },
  {
    label: "Pictures",
    key: "images",
    isDate: false,
    isArray: true,
    isColumn: true,
  },
];

export const LABOR_MEASUREMENT_DRAWER_DETAILS: RowDetailsDrawerDetails[] = [
  { label: "ID", key: "id", isDate: false, isArray: false },

  { label: "Name", key: "name", isDate: false, isArray: false },
  {
    label: "Measurement Unit",
    key: "unit",
    isDate: false,
    isArray: false,
  },

  {
    label: "Product System Parent Ids",
    key: "parents",
    isDate: false,
    isArray: true,
    isColumn: false,
  },
];

export const CONTRACTOR_DRAWER_DETAILS: RowDetailsDrawerDetails[] = [
  { label: "ID", key: "id", isDate: false, isArray: false },
  { label: "Name", key: "name", isDate: false, isArray: false },
  { label: "Website", key: "website", isDate: false, isArray: false },
  {
    label: "Description",
    key: "description",
    isDate: false,
    isArray: false,
  },
  {
    label: "Files",
    key: "files",
    isDate: false,
    isArray: true,
    isColumn: true,
    componentNumber: "one",
  },
  {
    label: "Trades",
    key: "trades",
    isDate: false,
    isArray: true,
    isColumn: true,
    componentNumber: "two",
  },
];

export const LABOR_ITEMS_DRAWER_DETAILS: RowDetailsDrawerDetails[] = [
  { label: "ID", key: "id", isDate: false, isArray: false },
  {
    label: "Contractor Parent ID",
    key: "contractorId",
    isDate: false,
    isArray: false,
  },
  { label: "Description", key: "description", isDate: false, isArray: false },
  { label: "Price", key: "price", isDate: false, isArray: false },
  {
    label: "Measurement Unit",
    key: "measurementUnit",
    isDate: false,
    isArray: false,
  },
  {
    label: "Coverage Amount",
    key: "coverageAmount",
    isDate: false,
    isArray: false,
  },
  {
    label: "Measurement Parent ID",
    key: "measurementId",
    isDate: false,
    isArray: false,
  },
  {
    label: "Product System Parent Ids",
    key: "parents",
    isDate: false,
    isArray: true,
    isColumn: false,
    componentNumber: "two",
  },
  {
    label: "Files",
    key: "files",
    isDate: false,
    isArray: true,
    isColumn: true,
  },
];

export const CONTRACTOR_REPORT_TYPES_DRAWER_DETAILS: RowDetailsDrawerDetails[] =
  [
    { label: "ID", key: "id", isDate: false, isArray: false },
    { label: "Name", key: "name", isDate: false, isArray: false },
    {
      label: "Responsibility",
      key: "responsibility",
      isDate: false,
      isArray: false,
    },
    { label: "Description", key: "description", isDate: false, isArray: false },
    {
      label: "Product System Parent IDs",
      key: "parents",
      isDate: false,
      isArray: true,
      isColumn: false,
    },
  ];

export const CONTRACTOR_CHANGE_ORDERS_DRAWER_DETAILS: RowDetailsDrawerDetails[] =
  [
    { label: "ID", key: "id", isDate: false, isArray: false },
    { label: "Name", key: "name", isDate: false, isArray: false },
    {
      label: "Material Price",
      key: "materialPrice",
      isDate: false,
      isArray: false,
    },
    { label: "Labor Price", key: "laborPrice", isDate: false, isArray: false },
    {
      label: "Material Parent ID",
      key: "materialId",
      isDate: false,
      isArray: false,
    },
    {
      label: "Labor Parent ID",
      key: "laborId",
      isDate: false,
      isArray: false,
    },
    {
      label: "Product System Parent IDs",
      key: "parents",
      isDate: false,
      isArray: true,
      isColumn: false,
    },
  ];

export const PROJECTS_STEP = [
  {
    title: "Step 1",
    value: 1,
  },
  {
    title: "Step 2",
    value: 2,
  },
  {
    title: "Step 3",
    value: 3,
  },
  {
    title: "Step 4",
    value: 4,
  },
  {
    title: "Step 5",
    value: 5,
  },
  {
    title: "Step 6",
    value: 6,
  },
];

export const PRIORITY_SELECT_INPUT_VALUE = [
  {
    title: "1",
    value: 1,
  },
  {
    title: "2",
    value: 2,
  },
  {
    title: "3",
    value: 3,
  },
  {
    title: "4",
    value: 4,
  },
  {
    title: "5",
    value: 5,
  },
  {
    title: "6",
    value: 6,
  },
  {
    title: "7",
    value: 7,
  },
  {
    title: "8",
    value: 8,
  },
  {
    title: "9",
    value: 9,
  },
  {
    title: "10",
    value: 10,
  },
];

export const PROJECT_DURATION_SELECT_INPUT_VALUE = [
  {
    title: "1 Week",
    value: "1 week",
  },
  {
    title: "2 Weeks",
    value: "2 weeks",
  },
  {
    title: "3 Weeks",
    value: "3 weeks",
  },
  {
    title: "4 Weeks",
    value: "4 weeks",
  },
  {
    title: "5 Weeks",
    value: "5 weeks",
  },
  {
    title: "6 Weeks",
    value: "6 weeks",
  },
  {
    title: "7 Weeks",
    value: "7 weeks",
  },
  {
    title: "8 Weeks",
    value: "8 weeks",
  },
  {
    title: "9 Weeks",
    value: "9 weeks",
  },
  {
    title: "10 Weeks",
    value: "10 weeks",
  },
];

export const CHECK_MATERIAL_QUALITY = [
  { id: 1, name: "Low" },
  { id: 2, name: "Medium" },
  { id: 3, name: "High" },
];

export const SELECT_CONTRACTOR_FILETYPE = [
  {
    id: "Document",
    label: "DOCUMENT",
  },
  {
    id: "License",
    label: "LICENSE",
  },
  {
    id: "Photo",
    label: "PHOTO",
  },
];
