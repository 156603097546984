import { FC, useState } from "react";
import { AppCSS, Lbl, TapButton } from "../../../../components";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";
import { Docs, Payment, Progress, Project } from "../../../../redux/types";
import { FormatString } from "../../../../utils";
import { toast } from "react-toastify";
import { TinyLbl } from "../../../../components/labels/labels.styled";
import { PAYMENT_DETAILS_TABLE_HEADER } from "../../../../contants/dashboardHeader";
import PaymentDetailsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import PaymentDetailsDetailsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import { Box, Container, Drawer, Modal } from "@mui/material";
import { LeadIcon } from "../../Leads/leads.styled";
import cancelIcon from "../../../../images/CancelIcon.png";
import { PAYMENT_DRAWER_DETAILS } from "../../../../contants/projectDetails";
import { rowDrawerDetails } from "../../../../types/type";
import DisplayLeadDetailsCard from "../../Leads/DisplayLeadDetailCard";

interface PaymentProps {
  paymentDetails: Payment[];
  onFetchProject: Function;
}

export const PaymentDetails: FC<PaymentProps> = ({
  paymentDetails,
  onFetchProject,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleDrawerOpen = (data: any) => {
    setSelectedRow(data);
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const showPaymentDetails = () => {
    return (
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            height: "100%",
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            overflowX: "hidden",
          },
        }}>
        <div
          onKeyDown={handleDrawerClose}
          style={{
            backgroundColor: AppCSS.WHITE,
            color: "black",
            height: "100%",
            display: "flex",
            width: "440px",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            padding: "20px",
            gap: "30px",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              gap: "10px",
            }}>
            <LeadIcon
              src={cancelIcon}
              onClick={handleDrawerClose}
              style={{
                width: "12px",
                height: "12px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                width: "100px",
                marginRight: "10px",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Lbl
                title={`Payment Details`}
                size={20}
                width={80}
                color={AppCSS.GRAY_DARK}
                bold={600}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
              paddingX: "20px",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "calc(100% - 20px)",
                marginRight: "20px",
                marginBottom: "20px",
              }}>
              {PAYMENT_DRAWER_DETAILS.map(
                (detail: rowDrawerDetails, index: number) => (
                  <DisplayLeadDetailsCard
                    key={`payment drawer details ${index}`}
                    label={detail.label}
                    value={
                      detail.isDate
                        ? selectedRow?.[detail.key]
                          ? new Date(
                              selectedRow?.[detail.key]
                            ).toLocaleDateString()
                          : ""
                        : selectedRow?.[detail.key]
                    }
                  />
                )
              )}
            </Box>
          </Box>
        </div>
      </Drawer>
    );
  };

  const handleApprovePayment = () => {
    onFetchProject();
  };

  return (
    <Container>
      <ColDiv
        style={{
          width: "100%",
          marginBottom: "1%",
          gap: "10px",
        }}>
        <PaymentDetailsTableHeaderCard headers={PAYMENT_DETAILS_TABLE_HEADER} />

        {Array.isArray(paymentDetails) &&
          paymentDetails.map((item, index) => {
            const key = `key-product-details-${index}`;
            return (
              <PaymentDetailsDetailsTableBodyCard
                key={key}
                bodyContent={[
                  {
                    text: <p>{item.transactionId}</p>,
                    width: "25%",
                    paddingLeft: "20px",
                  },
                  {
                    text: <p>{item.step}</p>,
                    width: "10%",
                  },
                  {
                    text: <p>{item.paymentType}</p>,
                    width: "15%",
                  },
                  {
                    text: <p>{item.amount.toFixed(2)}</p>,
                    width: "15%",
                  },
                  {
                    text: <p>{item.approved ? "Yes" : "No"}</p>,
                    width: "10%",
                  },
                  {
                    text: "",
                    width: "10%",
                    isAction: true,
                    actionComponent: (
                      <TapButton
                        title="View"
                        onTap={() => handleDrawerOpen(item)}
                        bgColor={"#65D191"}
                        borderColor={"#65D191"}
                        color={AppCSS.WHITE}
                        radius={5}
                        height={35}
                      />
                    ),
                  },
                  {
                    text: "",
                    width: "15%",
                    isAction: true,
                    actionComponent: (
                      <TapButton
                        title="approved"
                        onTap={() => {
                          handleApprovePayment();
                        }}
                        bgColor={"#65D191"}
                        borderColor={"#65D191"}
                        color={AppCSS.WHITE}
                        radius={5}
                        height={35}
                      />
                    ),
                  },
                ]}
                containerHeight="40px"
              />
            );
          })}
      </ColDiv>

      {showPaymentDetails()}
    </Container>
  );
};
