/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppAlert } from "../../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../../redux/hooks";
import { LaborItemsTable } from "./LaborItemsTable";
import {
  setContractors,
  setLaborItems,
  setMaterialMeasurements,
} from "../../../../redux/reducers";
import { Container } from "../../../../globalstyled";
import AppLoader from "../../../../components/loader";
import {
  useFetchContractor,
  useFetchLabor,
  useFetchMaterialMeasurements,
} from "../../../../apolloClient/hooks/itemCreation/fetchHooks";

interface LaborItemsProp {}

const LaborItem: React.FC<LaborItemsProp> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { laborItems, productSystems, materialMeasurements, contractors } =
    userReducer;

  const { loading, error, laborsData, refetch } = useFetchLabor({
    limit: 100,
    offset: 0,
  });

  const {
    loading: measurementLoading,
    error: measurementError,
    materialMeasurementsData,
  } = useFetchMaterialMeasurements({
    limit: 100,
    offset: 0,
  });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    onFetchLaborItems();
  }, [loading, error, laborsData, dispatch, handleRefetch]);

  useEffect(() => {
    if (!measurementLoading && !measurementError && materialMeasurementsData) {
      dispatch(setMaterialMeasurements(materialMeasurementsData));
    } else if (measurementError) {
      console.error("Error fetching Material misc field:", measurementError);
    }
  }, [measurementLoading, measurementError, materialMeasurementsData]);

  const onFetchLaborItems = () => {
    if (!loading && !error && laborsData) {
      dispatch(setLaborItems(laborsData));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Material misc field:", error);
      setIsLoading(false);
    }
  };

  const {
    loading: contractorLoading,
    error: contractorError,
    contractorsData,
  } = useFetchContractor({
    limit: 100,
    offset: 0,
  });

  useEffect(() => {
    onFetchContractor();
  }, [contractorLoading, contractorError, contractorsData, dispatch]);

  const onFetchContractor = async () => {
    if (!contractorLoading && !contractorError && contractorsData) {
      dispatch(setContractors(contractorsData));
    } else if (error) {
      console.error("Error fetching Contractors", error);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <LaborItemsTable
          laborItemsData={laborItems}
          refetchLabor={handleRefetch}
          productsIds={productSystems}
          contractors={contractors}
          materialMeasurements={materialMeasurements}
        />
      </Container>
    );
  };

  return hostContainer();
};

export default LaborItem;
