import { useQuery } from "@apollo/client";
import { GetAdminUserResponse } from "../../../redux/types/user-model";
import { GET_ADMIN_USERS } from "../../operation/querys";

interface queryInput {
  limit: number;
  offset: number;
  isSkip?: boolean;
}

export const useFetchAdminUser = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetAdminUserResponse,
    queryInput
  >(GET_ADMIN_USERS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    adminUsersData: data?.adminUsers,
    refetch,
  };
};
