import { HeaderList } from "../../../types/interface";

export const MATERIALS_ROOF_TABLE_HEADER: HeaderList[] = [
  { text: "Brand", width: "10%" },
  { text: "Warranty", width: "10%", justifyContent: "center" },
  { text: "Product Name", width: "20%" },
  { text: "Description", width: "30%", paddingLeft: "10px" },
  { text: "Price", width: "10%" },
  { text: "Colors", width: "10%", justifyContent: "center" },
  { text: "Action", width: "10%", justifyContent: "center" },
];

export const MATERIALS_VENTS_TABLE_HEADER: HeaderList[] = [
  { text: "Brand", width: "13%", paddingLeft: "10px" },
  { text: "Product", width: "22%" },
  { text: "Description", width: "30%", paddingLeft: "10px" },
  { text: "Price", width: "13%" },
  { text: "Unit", width: "12%", justifyContent: "center" },
  { text: "Action", width: "10%", justifyContent: "center" },
];

export const MATERIALS_PIPE_TABLE_HEADER: HeaderList[] = [
  { text: "Brand", width: "13%", paddingLeft: "10px" },
  { text: "Product", width: "22%" },
  { text: "Description", width: "30%", paddingLeft: "10px" },
  { text: "Price", width: "13%" },
  { text: "Unit", width: "12%", justifyContent: "center" },
  { text: "Action", width: "10%", justifyContent: "center" },
];

export const MATERIALS_LABOR_TABLE_HEADER: HeaderList[] = [
  { text: "Brand", width: "10%" },
  { text: "Warranty", width: "10%", justifyContent: "center" },
  { text: "Product Name", width: "20%" },
  { text: "Description", width: "30%", paddingLeft: "10px" },
  { text: "Price", width: "10%" },
  { text: "Colors", width: "10%", justifyContent: "center" },
  { text: "Action", width: "10%", justifyContent: "center" },
];

export const MATERIALS_INCLUDES_TABLE_HEADER: HeaderList[] = [
  { text: "Brand", width: "15%", paddingLeft: "20px" },
  { text: "Description", width: "35%", paddingLeft: "10px" },
  { text: "Price", width: "10%" },
  { text: "Coverage Size", width: "20%" },
  { text: "Unit", width: "10%" },
  { text: "Action", width: "10%", justifyContent: "center" },
];

export const MATERIALS_GUTTERS_TABLE_HEADER: HeaderList[] = [
  { text: "Brand", width: "10%" },
  { text: "Warranty", width: "10%", justifyContent: "center" },
  { text: "Product Name", width: "20%" },
  { text: "Description", width: "30%", paddingLeft: "10px" },
  { text: "Price", width: "10%" },
  { text: "Colors", width: "10%", justifyContent: "center" },
  { text: "Action", width: "10%", justifyContent: "center" },
];

export const MATERIALS_FLASH_TABLE_HEADER: HeaderList[] = [
  { text: "Product", width: "20%" },
  { text: "Description", width: "30%", paddingLeft: "10px" },
  { text: "Price", width: "10%" },
  { text: "Coverage Size", width: "20%" },
  { text: "Unit", width: "10%" },
  { text: "Action", width: "10%", justifyContent: "center" },
];

export const MATERIALS_COLOR_TABLE_HEADER: HeaderList[] = [
  { text: "Color", width: "10%" },
  { text: "Home Photo", width: "40%", justifyContent: "center" },
  { text: "Closeup", width: "40%", justifyContent: "center" },
  { text: "Action", width: "10%", justifyContent: "center" },
];

export const MATERIALS_GUTTER_COLOR_TABLE_HEADER: HeaderList[] = [
  { text: "Color", width: "20%" },
  { text: "Home Photo", width: "60%", justifyContent: "center" },
  { text: "Action", width: "20%", justifyContent: "center" },
];
