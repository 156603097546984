import { AxiosResponse } from "axios";
import axios from "axios";
import { BASE_URL } from "./appConst";
import { ResponseModel, UploadImageResponse } from "../types/common";

export const handleResponse = async (
  response: AxiosResponse
): Promise<ResponseModel> => {
  if (response.status === 200) {
    return {
      msg: "success",
      data: response.data.data,
    };
  }
  return { msg: "error occured" };
};

export const axiosAuth = () => {
  const token = localStorage.getItem("token") as string;
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadImage = async (
  uploadUrl: string,
  file: File
): Promise<UploadImageResponse> => {
  const options = {
    headers: {
      "Content-Type": file.type,
      "X-Amz-Acl": "public-read",
      "Content-Disposition": "inline",
    },
  };

  try {
    const response = await axios.put(uploadUrl, file, options);
    if (response.status === 200) {
      return { success: true };
    }
  } catch (error) {
    console.error("Error uploading image", error);
    return { success: false, error };
  }

  return { success: false };
};
