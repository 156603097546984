import { InsuranceScopeSection } from "../redux/types/InsuranceCompany";
import { FileType, ProjectFile } from "../redux/types/Project";

export const getFilesByType = (
  files: ProjectFile[],
  fileType: FileType
): ProjectFile[] => {
  if (!files) return [];
  return files.filter((file) => file.type === fileType);
};

export interface ExtractedData {
  projectId: number;
  materialId: number;
  quantity: number | null;
}

export const extractMeasurementsData = (
  insuranceScope: InsuranceScopeSection
): ExtractedData[] => {
  const result: ExtractedData[] = [];

  insuranceScope.materialMeasurements.forEach((material) => {
    material.measurements.forEach((measurement) => {
      result.push({
        projectId: insuranceScope.projectId,
        materialId: measurement.id,
        quantity: measurement.value.quantity,
      });
    });
  });

  return result;
};
