import { MaterialCategories } from "../redux/types/creation";

export const FormatString = (str) => {
  return str
    .split("_") // Split string on underscores
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
    .join(" "); // Join the words with spaces
};

export const extractCheckedIds = (checkboxStates: {
  [key: number]: boolean;
}): number[] => {
  return Object.keys(checkboxStates)
    .filter((key) => checkboxStates[key])
    .map((key) => parseInt(key));
};

export const getMaterialCategoryParentIdsOptions = (
  materialCategoriesArr: MaterialCategories[]
): { id: number; label: string }[] => {
  if (!materialCategoriesArr || materialCategoriesArr.length === 0) {
    return [];
  }
  return materialCategoriesArr.map((item) => ({
    id: item.id,
    label: item.name,
  }));
};
