import React from "react";
import { HeaderBox, CardTypography } from "./table.styled";

interface Header {
  text: string;
  width: string;
  justifyContent?: string;
  paddingLeft?: string;
}

interface HeaderCardProps {
  headers: Header[];
  containerWidth?: string;
  fontSize?: string;
}

const HeaderCard: React.FC<HeaderCardProps> = ({
  headers,
  containerWidth,
  fontSize,
}) => {
  return (
    <HeaderBox width={containerWidth}>
      {headers.map((header, index) => (
        <CardTypography
          key={index}
          width={header.width}
          fontSize={fontSize ? fontSize : "15px"}
          color="black"
          paddingLeft={header.paddingLeft}
          style={{
            wordWrap: "break-word",

            whiteSpace: "normal",

            justifyContent: header.justifyContent ? header.justifyContent : "",
          }}
          variant="body1"
        >
          {header.text}
        </CardTypography>
      ))}
    </HeaderBox>
  );
};

export default HeaderCard;
