/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppAlert } from "../../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../../redux/hooks";
import { LaborMeasurementsTable } from "./LaborMeasurementsTable";
import { setLaborMeasurements } from "../../../../redux/reducers";
import { Container } from "../../../../globalstyled";
import AppLoader from "../../../../components/loader";
import { useFetchLaborMeasurements } from "../../../../apolloClient/hooks/itemCreation/fetchHooks";

interface LaborMeasurementsProp {}

const LaborMeasurement: React.FC<LaborMeasurementsProp> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { laborMeasurements, productSystems } = userReducer;

  const { loading, error, laborMeasurementData, refetch } =
    useFetchLaborMeasurements({
      limit: 100,
      offset: 0,
    });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    onFetchLaborMeasurement();
  }, [loading, error, laborMeasurementData, dispatch, handleRefetch]);

  const onFetchLaborMeasurement = async () => {
    if (!loading && !error && laborMeasurementData) {
      dispatch(setLaborMeasurements(laborMeasurementData));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Labor Measurement field:", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <LaborMeasurementsTable
          laborMeasurementsData={laborMeasurements}
          refetchLaborMeasurement={handleRefetch}
          productsIds={productSystems}
        />
      </Container>
    );
  };

  return hostContainer();
};

export default LaborMeasurement;
