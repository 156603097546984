import { gql } from "@apollo/client";

export const CREATE_PRODUCT_SYSTEM_MUTATION = gql`
  mutation CreateProductSystem($input: CreateProductSystemInput!) {
    createProductSystem(input: $input)
  }
`;

export const CREATE_MATERIAL_CATEGORY = gql`
  mutation CreateMaterialCategory($input: CreateMaterialCategoryInput!) {
    createMaterialCategory(input: $input)
  }
`;

export const CREATE_MISC_FIELD = gql`
  mutation CreateMiscField($input: CreateMiscFieldInput!) {
    createMiscField(input: $input)
  }
`;

export const CREATE_LABOR_MEASUREMENT = gql`
  mutation CreateLaborMeasurement($input: CreateLaborMeasurementInput!) {
    createLaborMeasurement(input: $input)
  }
`;

export const CREATE_MATERIAL_MEASUREMENT = gql`
  mutation CreateMaterialMeasurement($input: CreateMaterialMeasurementInput!) {
    createMaterialMeasurement(input: $input)
  }
`;

export const CREATE_CONTRACTOR_REPORT = gql`
  mutation CreateContractorReport($input: CreateContractorReportInput!) {
    createContractorReport(input: $input)
  }
`;

export const CREATE_CONTRACTOR = gql`
  mutation CreateContractor($input: CreateContractorInput!) {
    createContractor(input: $input)
  }
`;

export const CREATE_LABOR = gql`
  mutation CreateLabor($input: CreateLaborInput!) {
    createLabor(input: $input)
  }
`;

export const CREATE_CONTRACTOR_ORDER = gql`
  mutation CreateContractorOrder($input: CreateContractorOrderInput!) {
    createContractorOrder(input: $input)
  }
`;
