/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppAlert } from "../../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../../redux/hooks";
import { MiscFieldsTable } from "./MiscFieldsTable";
import { setMiscFields } from "../../../../redux/reducers";
import { Container } from "../../../../globalstyled";
import AppLoader from "../../../../components/loader";
import { useFetchMaterialMisc } from "../../../../apolloClient/hooks/itemCreation/fetchHooks";

interface MiscFieldsProp {}

const MiscField: React.FC<MiscFieldsProp> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSkip, setIsSkip] = useState<boolean>(false);
  const refetchCalled = useRef<boolean>(false);
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { miscFields, productSystems } = userReducer;

  const isSkidFetchMiscFields = miscFields.length > 0 ? true : false;

  const {
    loading,
    error,
    miscFields: miscFieldsData,
    refetch,
  } = useFetchMaterialMisc({
    limit: 100,
    offset: 0,
    isSkip: isSkip,
  });

  const handleRefetch = useCallback(() => {
    setIsSkip(false);
    refetchCalled.current = true;
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (isSkidFetchMiscFields && !refetchCalled.current) {
      setIsSkip(true);
      setIsLoading(false);
    }
    onFetchMiscFields();
  }, [loading, error, miscFieldsData, dispatch, handleRefetch]);

  const onFetchMiscFields = () => {
    if (!loading && !error && miscFieldsData) {
      dispatch(setMiscFields(miscFieldsData));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Material misc field:", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <MiscFieldsTable
          miscFieldsData={miscFields}
          refetchMiscField={handleRefetch}
          productsIds={productSystems}
        />
      </Container>
    );
  };

  return hostContainer();
};

export default MiscField;
