import styled from "@emotion/styled";
import { StandardTextFieldProps, TextField } from "@material-ui/core";
import { Button } from "@mui/material";

interface SInputProps extends StandardTextFieldProps {
  placeholderFontSize?: number;
}

export const SInputField = styled(TextField)<SInputProps>`
  display: flex;
  color: #1a1824;
  width: 99%;

  @media only screen and (max-width: 1024px) {
    & input::placeholder {
      font-size: ${(props) =>
        props.placeholderFontSize ? `${props.placeholderFontSize}px` : "14px"};
      color: black;
    }
  }
`;

export const SInput = styled(TextField)`
  & {
    display: flex;
    width: 100%;
    color: #1a1824;
  }
`;

export const SMultilineInput = styled(TextField)`
  & {
    display: flex;
    width: 90%;
    min-height: 80px;
    color: #1a1824;
  }
`;

export const SPhoneDiv = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const SFlagDiv = styled.div`
  display: flex;
  height: 50px;
  justify-content: space-around;
  align-items: center;
`;

export const SFileUpload = styled(Button)`
  & {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: #e7e7e7;
    color: #1a1824;
  }
`;
