/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppCSS,
  Lbl,
  Spacer,
  TxtInput,
  TapButton,
} from "../../../../components";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import { Customer, Progress, Project } from "../../../../redux/types";
import { FC, useEffect, useState } from "react";
import { Prompt } from "../../../../components/dialogue/Dialogue";
import { EditProgressSteps } from "../../../../redux/api";

interface CustomerProps {
  onFetchProgress: Function;
  progress?: Progress[];
  project?: Project;
  customerDetails?: Customer;
}

interface StepChangeAction {
  step: string;
  forward: boolean;
}

export const CustomerDetails: FC<CustomerProps> = ({
  onFetchProgress,
  project,
  customerDetails,
}) => {
  // customer details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [email, setEmail] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [stepToChange, setStepToChange] = useState<StepChangeAction>();
  const [stepChangeMsg, setStepChangeMsg] = useState(
    "This will revert or advance the project to the selected step."
  );

  useEffect(() => {
    if (customerDetails) {
      const { firstName, lastName, phone, email, address } = customerDetails;

      if (firstName) {
        setFirstName(firstName);
      }
      if (lastName) {
        setLastName(lastName);
      }
      if (phone) {
        setPhoneNumber(phone);
      }
      if (email) {
        setEmail(email);
      }

      if (address && address.length > 0) {
        const { street: streetAddress, city, state } = address[0];

        if (streetAddress) {
          setStreetAddress(streetAddress);
        }
        if (city) {
          setCityName(city);
        }
        if (state) {
          setStateName(state);
        }
      }
    }
  }, [customerDetails]);
  const onTapUpdateProfile = () => {
    setIsEdit(false);
  };

  const changeCustomerStage = (stage: StepChangeAction) => {
    setStepChangeMsg(
      `This will ${
        stage.forward ? "advance" : "revert"
      } the project to the selected step.`
    );
    setOpenConfirm(true);
    setStepToChange(stage);
  };

  const customerChangeConfirmation = async () => {
    setOpenConfirm(false);
    try {
      const status = await EditProgressSteps(
        project.id,
        stepToChange.step,
        stepToChange.forward ? "forward" : "reverse"
      );
      if (status === 200) {
        onFetchProgress();
      }
      // TODO: show errors
    } catch (error) {}
  };

  return (
    <>
      <RowDiv
        style={{
          display: "row",
          justifyContent: "space-around",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Prompt
          message={stepChangeMsg}
          onConfirm={() => {
            customerChangeConfirmation();
          }}
          onClose={() => {
            setStepToChange({} as StepChangeAction);
            setOpenConfirm(false);
          }}
          open={openConfirm}
        />
        <ColDiv
          style={{
            width: "60%",
            marginRight: "100px",
          }}
        >
          {/* right side */}
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <ColDiv>
              <Lbl title="First Name" color={AppCSS.GRAY_DARK} size={13} />
              <div>{firstName}</div>
            </ColDiv>
            <Spacer size={1} direction="row" />
            <ColDiv>
              <Lbl title="Last Name" color={AppCSS.GRAY_DARK} size={13} />
              <div>{lastName}</div>
            </ColDiv>
          </RowDiv>
          <ColDiv>
            <Lbl title="Phone Number" color={AppCSS.GRAY_DARK} size={13} />
            <div>{phoneNumber}</div>
          </ColDiv>
          <ColDiv>
            <Lbl title="Email" color={AppCSS.GRAY_DARK} size={13} />

            <div>{email}</div>
          </ColDiv>
          <Spacer size={1} direction="col" />
        </ColDiv>
        <ColDiv
          style={{
            width: "60%",
            marginRight: "50px",
            alignItems: "center",
          }}
        >
          <ColDiv>
            <RowDiv
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Lbl title="Address" color={AppCSS.GRAY_DARK} size={13} />
            </RowDiv>

            <div>{streetAddress}</div>
          </ColDiv>
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <ColDiv>
              <Lbl title="City Name" color={AppCSS.GRAY_DARK} size={13} />

              <div>{cityName}</div>
            </ColDiv>
            <Spacer size={1} direction="row" />
            <ColDiv>
              <Lbl title="State Name" color={AppCSS.GRAY_DARK} size={13} />
              <div>{stateName}</div>
            </ColDiv>
          </RowDiv>

          <Spacer size={2} direction="col" />
        </ColDiv>
      </RowDiv>
      {/* <RowDiv style={{ justifyContent: "flex-end" }}>
        <TapButton
          title="Save"
          onTap={() => onTapUpdateProfile()}
          bgColor={AppCSS.GLAS_BLUE}
          width={120}
          height={38}
        />
      </RowDiv> */}
      <Spacer size={2} direction="col" />
      {/* <RowDiv
        style={{
          flexDirection: "column",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            marginBottom: 10,
            fontSize: 20,
          }}
        >
          Customer Steps
        </div>
      </RowDiv> */}
    </>
  );
};
