/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState } from "react";
import {
  AppCSS,
  Lbl,
  Spacer,
  TxtInput,
  TapButton,
} from "../../../../components";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import { Project } from "../../../../redux/types";
import moment from "moment";
import { CustomerProjectDetails } from "../../../../redux/types/projectDetails";

interface InstallationProps {
  project?: CustomerProjectDetails;
}

export const InstallationDetails: FC<InstallationProps> = ({ project }) => {
  const [isEdit, setIsEdit] = useState(false);

  const createDate = (date: string) => {
    const newDate = new Date(date);

    return newDate.toDateString();
  };

  return (
    <>
      <RowDiv
        style={{
          display: "row",
          justifyContent: "space-around",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ColDiv
          style={{
            width: "60%",
            marginRight: "100px",
          }}
        >
          <Spacer size={1} direction="col" />
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <ColDiv>
              <Lbl
                title="Installation Date"
                color={AppCSS.GRAY_DARK}
                size={18}
                bold={600}
              />
            </ColDiv>
            <Spacer size={1} direction="row" />
          </RowDiv>
        </ColDiv>
        <ColDiv
          style={{
            width: "60%",
            marginRight: "50px",
            alignItems: "center",
          }}
        >
          <ColDiv></ColDiv>

          <Spacer size={2} direction="col" />
        </ColDiv>
      </RowDiv>
      <div>
        {project?.installationDetails.installationDate.length > 0
          ? createDate(project?.installationDetails.installationDate)
          : "Date hasn't been selected by customer yet."}
      </div>
    </>
  );
};
