import React, { FC } from "react";
import { Box } from "@mui/system";
import { LeadIcon, RowLeadIconDiv } from "../../Leads/leads.styled";
import { Lbl } from "../../../../components/labels";
import cancelIcon from "../../../../images/CancelIcon.png";

interface SideDrawerHeaderProps {
  handleDrawerClose: () => void;
  labelTitle: string;
}

const SideDrawerHeader: FC<SideDrawerHeaderProps> = ({
  handleDrawerClose,
  labelTitle,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <LeadIcon
        src={cancelIcon}
        onClick={handleDrawerClose}
        style={{
          width: "12px",
          height: "12px",
          marginRight: "10px",
          cursor: "pointer",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "160px",
          marginRight: "10px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Lbl title={labelTitle} size={18} width={100} />
      </Box>
    </Box>
  );
};

export default SideDrawerHeader;
