import { FC, useRef, useState } from "react";
import {
  AppCSS,
  Lbl,
  Spacer,
  TxtInput,
  TapButton,
  ButtonWithIcon,
} from "../../../../components";
import { SFileUpload } from "../../../../components/input/input.styled";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import axios from "axios";
import { GetUploadUrl, UpdateDocs } from "../../../../redux/api";
import { toast } from "react-toastify";
import {
  CreateProjectFileInput,
  FileType,
  Project,
  ProjectFile,
} from "../../../../redux/types";
import { DownloadDoneOutlined, FileOpen } from "@mui/icons-material";
import { UpdateSignedDocs } from "../../../../redux/api/estimate";
import { DOCUMENTS_TABLE_HEADER } from "../../../../contants/dashboardHeader";
import DocumentsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import DocumentsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import {
  useCreateProjectFile,
  useCreateUploadUrl,
} from "../../../../apolloClient/hooks/general/createHook";
import { CreateUploadFileInput } from "../../../../types/graphQlQuery.interface";
import { uploadImage } from "../../../../redux/api/common";
import { CustomerProjectDetails } from "../../../../redux/types/projectDetails";
import { Box } from "@mui/material";
import { SelectInput } from "../../../../components/input/SelectInput";
import { SELECT_FILETYPE } from "../../../../contants/projectDetails";

interface DocumentsProps {
  onFetchProject: Function;
  project?: CustomerProjectDetails;
  docs?: ProjectFile[];
}

export const DocumentDetails: FC<DocumentsProps> = ({
  onFetchProject,
  project,
  docs,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [uploadUrl, setUploadUrl] = useState("");
  const [fileUrl, setFileUrl] = useState<string>("");
  const [documentType, setDocumentType] = useState<string>("");

  const { createUploadUrl } = useCreateUploadUrl();

  const { createProjectFile } = useCreateProjectFile();

  const getUplaodUrl = async () => {
    try {
      const file = fileRef.current?.files[0];

      const input: CreateUploadFileInput = {
        contentType: file?.type,
      };

      const success = await createUploadUrl(input);
      if (success) {
        setUploadUrl(success.url);
        setFileUrl(success.fileName);
        return;
      } else {
        throw new Error("Failed to create Upload url.");
      }
    } catch (error) {
      console.error("Error getting upload URL: ", error);
      toast("Error getting upload URL", {
        type: "error",
        position: "top-left",
        className: "toast-message",
      });
    }
  };

  const handleCreateProjectFile = async () => {
    const input: CreateProjectFileInput = {
      projectId: project?.projectId,
      url: fileUrl,
      type: documentType as FileType,
    };

    try {
      const success = await createProjectFile(input);
      if (success) {
        setUploadUrl("");
        setDocumentType("");
        setFileUrl("");
        if (fileRef.current) {
          fileRef.current.value = "";
        }
        onFetchProject();
        toast("Document uploaded successfully", {
          type: "success",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    } catch (error) {
      throw error;
    }
  };

  const onUpload = async () => {
    const file = fileRef.current?.files[0];
    if (!file) {
      toast("An image have not been selected", {
        type: "error",
        position: "top-left",
        className: "toast-message",
      });
      return;
    }

    try {
      const result = await uploadImage(uploadUrl, file);
      if (result.success) {
        await handleCreateProjectFile();
      }
    } catch (error) {
      console.error("Error uploading image ", error);
      toast("Error uploading document", {
        type: "error",
        position: "top-left",
        className: "toast-message",
      });
    }
  };

  const onChangeFileTypeSelectInput = async (fileType: any) => {
    setDocumentType(fileType);
  };

  function formatString(str) {
    return str
      .split("_") // Split string on underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join the words with spaces
  }

  const onTapUpdateSignedDocs = async (docType: string) => {
    try {
      const status = await UpdateSignedDocs(project.projectId, docType);
      if (status === 200) {
        toast(`Updated signed copy of ${docType}!`, {
          type: "success",
        });
      }
    } catch (error) {
      toast("File upload error!", {
        type: "error",
      });
    }
  };

  return (
    <>
      <RowDiv
        style={{
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <ColDiv
          style={{
            width: "70%",
            marginBottom: "1%",
            gap: "10px",
          }}
        >
          <DocumentsTableHeaderCard headers={DOCUMENTS_TABLE_HEADER} />

          {Array.isArray(docs) &&
            docs.map((item, index) => {
              const key = `key-Document-${index}`;
              return (
                <DocumentsTableBodyCard
                  key={key}
                  bodyContent={[
                    {
                      text: <p>{formatString(item.type)}</p>,
                      width: "50%",
                      paddingLeft: "20px",
                    },
                    {
                      text: (
                        <a
                          href={`${item.url}`}
                          target="_blank"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 15,
                            color: AppCSS.GLAS_BLUE,
                          }}
                          rel="noreferrer"
                        >
                          <p>View File</p>
                        </a>
                      ),
                      width: "15%",
                      justifyContent: "center",
                    },
                  ]}
                  containerHeight="40px"
                />
              );
            })}
        </ColDiv>
        <Spacer size={3} direction="row" />
        <ColDiv
          style={{
            width: "30%",
          }}
        >
          <RowDiv
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <ColDiv>
              <Lbl title="Document Type" color={AppCSS.GRAY_DARK} size={13} />
              <RowDiv>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SelectInput
                    options={SELECT_FILETYPE}
                    onHandleChange={onChangeFileTypeSelectInput}
                    value={documentType}
                    required={true}
                    backgroundColor="inherit"
                  />
                </Box>
                <SFileUpload
                  style={{
                    border: fileUrl.length > 0 ? `3px solid #A9CBB7` : "none",
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    if (fileRef.current) {
                      fileRef.current.click();
                    }
                  }}
                >
                  <input
                    type="file"
                    id="file"
                    ref={fileRef}
                    onChange={() => {
                      if (uploadUrl === "") {
                        getUplaodUrl();
                      }
                    }}
                    accept={"image/png, image/jpeg, image/jpg, application/pdf"}
                    style={{
                      display: "none",
                    }}
                  />
                  <FileOpen
                    titleAccess="Select File"
                    color="warning"
                    style={{ width: 32, height: 32, color: AppCSS.GLAS_GREEN }}
                  />
                </SFileUpload>
              </RowDiv>
            </ColDiv>
          </RowDiv>

          <RowDiv>
            <ColDiv
              style={{
                width: "30%",
              }}
            >
              <Spacer size={1} direction="col" />
              <TapButton
                bgColor={
                  fileUrl.length < 1 ? AppCSS.GRAY_LIGHT : AppCSS.GLAS_BLUE
                }
                disable={fileUrl.length < 1}
                width={120}
                height={42}
                title="Upload"
                onTap={() => onUpload()}
              />
            </ColDiv>
          </RowDiv>
        </ColDiv>
      </RowDiv>
      <Spacer size={3} direction="col" />
    </>
  );
};
