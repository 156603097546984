/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { useState } from "react";
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import { toast } from "react-toastify";

import { InsuranceCompany } from "../../../redux/types/InsuranceCompany";
import {
  CREATE_INSURANCE_COMPANY,
  EDIT_INSURANCE_COMPANY,
} from "../../../apolloClient/operation/mutations/mutation";
import { useMutation } from "@apollo/client";
import { CreateInsuranceCompanyInput } from "../../../types/graphQlQuery.interface";

interface CreateCompanyProps {
  onTapAdd: Function;
  company?: InsuranceCompany;
  onCancel: Function;
}

export const AddCompany: React.FC<CreateCompanyProps> = ({
  onTapAdd,
  company,
  onCancel,
}) => {
  const [initialValues, setInitialValues] = useState<InsuranceCompany | null>(
    null
  );
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [logo, setLogo] = useState("");

  const [createInsuranceCompany] = useMutation<CreateInsuranceCompanyInput>(
    CREATE_INSURANCE_COMPANY,
    {
      onCompleted: () => {
        toast("Insurance Company  added!", { type: "success" });
        onTapAdd();
      },
      onError: (error) => {
        toast("Error creating insurance company!", { type: "error" });
        console.error("Error creating user:", error);
      },
    }
  );

  const [editInsuranceCompany] = useMutation<
    boolean,
    { id: number; input: CreateInsuranceCompanyInput }
  >(EDIT_INSURANCE_COMPANY, {
    onCompleted: () => {
      toast("Insurance Company updated successfully!", {
        type: "success",
        className: "toast-message",
      });
      onTapAdd();
    },
    onError: (error) => {
      toast("Error Updating insurance company!", {
        type: "error",
        className: "toast-message",
      });
      console.error("Error updating insurance company:", error);
    },
  });

  useEffect(() => {
    if (company) {
      setInitialValues(company);
      setName(company.companyName);
      setLink(company.claimLink);
      setLogo(company.companyLogo);
    }
  }, [company]);

  const getUpdatedFields = (): Partial<CreateInsuranceCompanyInput> => {
    const updatedFields: Partial<CreateInsuranceCompanyInput> = {};

    if (company && initialValues) {
      if (name !== initialValues.companyName) {
        updatedFields.companyName = name;
      }
      if (link !== initialValues.claimLink) {
        updatedFields.claimLink = link;
      }
      if (logo !== initialValues.companyLogo) {
        updatedFields.companyLogo = logo;
      }
    }

    return updatedFields;
  };

  const handleSubmitForm = async () => {
    if (!name || !link || !logo) {
      toast("Please enter mandatory fields!", {
        type: "error",
      });
      return;
    }

    const updatedFields = getUpdatedFields();

    if (company) {
      try {
        if (Object.keys(updatedFields).length === 0) {
          onTapAdd();
          toast("No changes made!", {
            type: "info",
          });
          return;
        }
        await editInsuranceCompany({
          variables: {
            id: company.id,
            input: updatedFields,
          },
        });
      } catch (error) {
        console.error("Error updating insurance company:", error);
      }
    } else {
      try {
        await createInsuranceCompany({
          variables: {
            input: {
              companyName: name,
              claimLink: link,
              companyLogo: logo,
            },
          },
        });
        onTapAdd();
      } catch (error) {
        console.error("Error creating insurance company:", error);
      }
    }
  };

  const hostContainer = () => {
    return (
      <ColDiv
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spacer size={1} direction="col" />

          <RowDiv
            style={{
              width: "100%",
              marginTop: "5%",
            }}
          >
            <Lbl
              title={
                company ? "Edit insurance company" : "Create insurance company"
              }
              size={25}
              bold={600}
              backgroundColor="#f3f3f3"
            />
          </RowDiv>

          <Spacer size={1} direction="col" />

          <RowDiv
            style={{
              width: "100%",
            }}
          >
            <ColDiv
              style={{
                width: "100%",
              }}
            >
              <TxtInput
                value={name}
                placeholder="Company Name"
                onChange={setName}
              />
              <Spacer size={1} direction="col" />
              <TxtInput
                value={link}
                placeholder="Claim Link"
                onChange={setLink}
              />
              <Spacer size={1} direction="col" />

              <TxtInput
                value={logo}
                placeholder="Company Logo"
                onChange={setLogo}
              />
              <Spacer size={1} direction="col" />
            </ColDiv>
          </RowDiv>
          <Spacer size={2} direction="col" />
          <RowDiv
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TapButton
              title="Cancel"
              onTap={() => onCancel()}
              width={120}
              bgColor={AppCSS.WHITE}
              borderColor={AppCSS.BLUE}
              color={AppCSS.BLUE}
              height={40}
            />
            <TapButton
              title={company ? "Save" : "Create"}
              bgColor={company ? AppCSS.BLUE : AppCSS.BLUE}
              onTap={() => handleSubmitForm()}
              width={120}
              height={40}
            />

            <Spacer size={5} direction="row" />
          </RowDiv>
        </div>
      </ColDiv>
    );
  };

  return hostContainer();
};
