import React, { useEffect, useState } from "react";
import { AppCSS } from "../../../../components";
import {
  AddClaimInput,
  CustomerProjectDetails,
} from "../../../../redux/types/projectDetails";
import { useMutation } from "@apollo/client";
import { ADD_CLAIM_DETAILS } from "../../../../apolloClient/operation/mutations";
import { toast } from "react-toastify";

interface MaterialProps {
  project: CustomerProjectDetails;
}

export default function InsuranceInfo({ project }: MaterialProps) {
  const [insurer, setInsurer] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const [claimDate, setClaimDate] = useState("");
  const [totalPayout, setTotalPayout] = useState(null);
  const [firstCheck, setFirstCheck] = useState(null);
  const [secondCheck, setSecondCheck] = useState(null);

  const [addClaimDetails] = useMutation<
    {
      addClaimDetails: boolean;
    },
    { input: AddClaimInput }
  >(ADD_CLAIM_DETAILS);

  useEffect(() => {
    if (project) {
      console.log(
        "the necessary",
        project.insuranceScope.insuranceClaim.Insurer
      );
      setInsurer(project?.insuranceScope.insuranceClaim.Insurer);
      setClaimNumber(project?.insuranceScope.insuranceClaim.ClaimNumber);
      setClaimDate(project?.insuranceScope.insuranceClaim.ClaimDate);
      setTotalPayout(project?.insuranceScope.insuranceClaim.TotalPayout);
      setFirstCheck(project?.insuranceScope.insuranceClaim.FirstCheck);
      setSecondCheck(project?.insuranceScope.insuranceClaim.SecondCheck);
    }
  }, [project]);

  const saveNewItem = (item: string) => {
    addClaimDetails({
      variables: {
        input: {
          projectId: project.projectId,
          insurer: insurer,
          claimNumber: claimNumber,
          claimDate: claimDate,
          totalPayout: totalPayout,
          firstCheck: firstCheck,
          secondCheck: secondCheck,
        },
      },
    })
      .then(() => {
        toast(`${item} saved successfully`, { type: "success" });
      })
      .catch((error) => {
        toast(`Error saving ${item}. Please try again.`, { type: "error" });
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 5,
          marginLeft: 10,
        }}
      >
        <div
          style={{
            color: "black",
            display: "flex",
            flexDirection: "row",
          }}
        >
          Insurer
        </div>
        <div
          style={{
            width: 200,
            height: 35,
            borderWidth: 1,
            borderColor: "black",
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            placeholder={insurer}
            className="placeholder-black"
            onBlur={() => saveNewItem("Insurer")}
            onChange={(e) => setInsurer(e.target.value)}
            style={{
              width: 200,
              height: "100%",
              paddingLeft: 5,
              borderColor: "black",
              borderRadius: 5,
              outline: "none",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 5,
          marginLeft: 10,
        }}
      >
        <div
          style={{
            color: "black",
            display: "flex",
            flexDirection: "row",
          }}
        >
          Claim number
        </div>
        <div
          style={{
            width: 200,
            height: 35,
            borderWidth: 1,
            borderColor: "black",
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            placeholder={claimNumber}
            className="placeholder-black"
            onChange={(e) => setClaimNumber(e.target.value)}
            onBlur={() => saveNewItem("Claim Number")}
            style={{
              width: 200,
              height: "100%",
              paddingLeft: 5,
              borderColor: "black",
              borderRadius: 5,
              outline: "none",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 5,
          marginLeft: 10,
        }}
      >
        <div
          style={{
            color: "black",
            display: "flex",
            flexDirection: "row",
          }}
        >
          Claim Date
        </div>
        <div
          style={{
            width: 200,
            height: 35,
            borderWidth: 1,
            borderColor: "black",
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            placeholder={claimDate}
            className="placeholder-black"
            onChange={(e) => setClaimDate(e.target.value)}
            onBlur={() => saveNewItem("Claim Date")}
            style={{
              width: 200,
              height: "100%",
              paddingLeft: 5,
              borderColor: "black",
              borderRadius: 5,
              outline: "none",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 5,
          marginLeft: 10,
        }}
      >
        <div
          style={{
            color: "black",
            display: "flex",
            flexDirection: "row",
          }}
        >
          First check
        </div>
        <div
          style={{
            width: 200,
            height: 35,
            borderWidth: 1,
            borderColor: "black",
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            placeholder={firstCheck}
            type="number"
            className="placeholder-black"
            onChange={(e) => setFirstCheck(Number(e.target.value))}
            onBlur={() => saveNewItem("First Check")}
            style={{
              width: 200,
              height: "100%",
              paddingLeft: 5,
              borderColor: "black",
              borderRadius: 5,
              outline: "none",
              color: "black",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 5,
          marginLeft: 10,
        }}
      >
        <div
          style={{
            color: "black",
            display: "flex",
            flexDirection: "row",
          }}
        >
          Second check
        </div>
        <div
          style={{
            width: 200,
            height: 35,
            borderWidth: 1,
            borderColor: "black",
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            placeholder={secondCheck}
            className="placeholder-black"
            type="number"
            onChange={(e) => setSecondCheck(Number(e.target.value))}
            onBlur={() => saveNewItem("Second Check")}
            style={{
              width: 200,
              height: "100%",
              paddingLeft: 5,
              borderColor: "black",
              borderRadius: 5,
              outline: "none",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 5,
          marginLeft: 10,
        }}
      >
        <div
          style={{
            color: "black",
            display: "flex",
            flexDirection: "row",
          }}
        >
          Total payout
        </div>
        <div
          style={{
            width: 200,
            height: 35,
            borderWidth: 1,
            borderColor: "black",
            borderRadius: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input
            placeholder={totalPayout}
            className="placeholder-black"
            type="number"
            onChange={(e) => setTotalPayout(Number(e.target.value))}
            onBlur={() => saveNewItem("Total Payout")}
            style={{
              width: 200,
              height: "100%",
              paddingLeft: 5,
              borderColor: "black",
              borderRadius: 5,
              outline: "none",
            }}
          />
        </div>
      </div>
    </div>
  );
}
