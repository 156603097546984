import { LeadModel } from "./LeadModel";
import { Material } from "./Material";
import { Payment, PaymentMethodForProjectDetails } from "./payment";
export interface ProjectProgressType {
  id: number;
  step: number;
  projectId: number;
  title: string;
  description: string;
  remarks?: string;
  approved: boolean;
  completed: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface Customer {
  id: number;
  email: string;
  password?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  resetCode?: number;
  expiry?: string;
  verified?: boolean;
  createdAt: string;
  updatedAt: string;
  leads: LeadModel;
  address: Address[];
  projectId: number;
}

export interface Address {
  id: number;
  userId: number;
  street: string;
  zipCode: string;
  city: string;
  state: string;
  lat?: number;
  lng?: number;
  streetAddress?: string;
}

export interface Docs {
  id: number;
  project_id: number;
  uploaded_by: string;
  file_url: string;
  file_type: string;
  meta_data: string;
  visible: boolean;
  created_at: string;
  updated_at: string;
}
export interface Product {
  id: number;
  name: string;
  paymentMethods: PaymentMethodForProjectDetails[];
  thumbnail: string;
  duration: string;
  priority: number;
  createdAt: string;
  updatedAt: string;
}

export interface ProjectFiles {
  id: number;
  projectId: number;
  url: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface Project {
  id: number;
  customerId: number;
  customer?: Customer;
  status: string;
  progress?: Progress[];
  products?: Product[];
  files?: ProjectFile[];
  createdAt?: string;
  updatedAt?: string;
  paymentType?: PayType;
}

export interface ProjectDetails {
  project: Project;
  progress: Progress[];
  docs?: Docs[];
  projectMaterials?: Material[];
}

export interface GetProjectsDetailsData {
  projects: Project[];
}

export interface Progress {
  id: number;
  step: number;
  projectId: number;
  title: string;
  description: string;
  remarks?: string;
  approved: boolean;
  completed: boolean;
  payment?: Payment;
  createdAt?: string;
  updatedAt?: string;
}

export interface GetCustomerResponse {
  customers: Customer[];
}

export interface GetCustomerByIDResponse {
  customer: Customer;
}
export enum PayType {
  INSURANCE = "INSURANCE",
  CASH = "CASH",
}
export enum FileType {
  PHOTO = "PHOTO",
  LICENSE = "LICENSE",
  DOCUMENT = "DOCUMENT",
  INSURANCE = "INSURANCE",
  INVOICE = "INVOICE",
}

export interface ProjectFile {
  id: number;
  projectId: number;
  url: string;
  type: FileType;
  createdAt: string;
  updatedAt: string;
}

export interface CreateProjectFileInput {
  projectId: number;
  url: string;
  type: FileType;
}
