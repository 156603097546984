import React, { ReactNode } from "react";
import { Drawer } from "@mui/material";
import { AppCSS } from "../../../../components";

interface DrawerProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

const CustomDrawer: React.FC<DrawerProps> = ({ open, onClose, children }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          height: "100%",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          overflowX: "hidden",
        },
      }}
    >
      <div
        style={{
          backgroundColor: AppCSS.WHITE,
          color: "black",
          height: "100%",
          display: "flex",
          width: "640px",
          flexDirection: "column",
          alignItems: "start",
          padding: "20px",
          marginBottom: "40px",
          gap: "30px",
          paddingLeft: "40px",
        }}
      >
        {children}
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
