/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import { TapButton, AppCSS, Spacer, TxtInput } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import avatar from "../../../images/avatar.png";
import {
  DeleteButton,
  EditButton,
} from "../../../components/buttons/ButtonWithIcon";
import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useDispatch } from "react-redux";
import { AdminUserModel } from "../../../redux/types";

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
  },
  // {
  //   id: "email",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Email",
  // },
  {
    id: "role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },

  // {
  //   id: "password",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Password",
  // },
  // {
  //   id: "status",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Status",
  // },
  // {
  //   id: "action",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Action",
  // },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AdminUserModel
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Lbl
              title={headCell.label}
              size={17}
              color={AppCSS.BLACK}
              backgroundColor="#f3f3f3"
            />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface UserTableProps {
  onTapAddUser?: Function;
  users: AdminUserModel[];
  title?: string;
  onTapEditUser?: Function;
  onTapResetPassword?: Function;
}

export const UserTable: React.FC<UserTableProps> = ({
  onTapAddUser,
  users,
  title,
  onTapEditUser,
  onTapResetPassword,
}) => {
  const dispatch = useDispatch();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof AdminUserModel>("firstName");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [keyword, setKeyword] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [deleteUser, setDeleteUser] = React.useState("");
  const [deleteId, setDeleteId] = React.useState(0);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AdminUserModel
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterUsers = () => {
    if (keyword.length > 0 && Array.isArray(users)) {
      return users.filter((item) => {
        const firstName = item.firstName?.toLowerCase() || "";
        const lastName = item.lastName?.toLowerCase() || "";
        const fullName = `${firstName} ${lastName}`;

        return (
          firstName.includes(keyword.toLowerCase()) ||
          lastName.includes(keyword.toLowerCase()) ||
          fullName.includes(keyword.toLowerCase())
        );
      });
    }
    return users;
  };

  function capitalizeFirstLetter(str: string) {
    let newString = str.toLowerCase();
    return newString.charAt(0).toUpperCase() + str.slice(1);
  }

  const employeeTable = () => (
    <ColDiv
      style={{
        width: "100%",
      }}
    >
      <Spacer size={2} direction="col" />
      <RowDiv
        style={{
          justifyContent: "flex-start",
        }}
      >
        <TxtInput
          width={280}
          borderColor={AppCSS.BLUE}
          placeholder={`Search Users`}
          onChange={setKeyword}
        />
      </RowDiv>
      <TableContainer
        sx={{
          width: "100%",
        }}
      >
        <Table
          sx={{ width: "100%" }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy} // need to fix
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={users.length}
          />
          <TableBody style={{ width: "60%" }}>
            {
              // stableSort(users, getComparator(order, orderBy))
              filterUsers()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row.firstName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.firstName)}
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.firstName}

                      // selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        <RowDiv
                          style={{
                            width: "130px",
                            alignItems: "center",
                          }}
                        >
                          <Lbl
                            title={`${
                              capitalizeFirstLetter(row.firstName) +
                              " " +
                              capitalizeFirstLetter(row.lastName)
                            }`}
                            color={AppCSS.BLACK}
                            backgroundColor="#f3f3f3"
                            size={13}
                          />
                        </RowDiv>
                      </TableCell>

                      <TableCell>
                        <Lbl
                          title={`${capitalizeFirstLetter(
                            row.role.toLowerCase()
                          )}`}
                          color={AppCSS.BLACK}
                          backgroundColor="#f3f3f3"
                          size={13}
                        />
                      </TableCell>
                      {/* <TableCell>
                        <TapButton
                          height={22}
                          width={160}
                          onTap={() =>
                            onTapResetPassword
                              ? onTapResetPassword(row.email)
                              : {}
                          }
                          title="Send reset email"
                          color={AppCSS.GRAY}
                          bgColor={AppCSS.GRAY_MILD}
                        />
                      </TableCell> */}
                      {/* <TableCell>
                        <Lbl
                          title={`${row.Status > 0 ? "Active" : "Inactive"}`}
                          color={AppCSS.GRAY}
                          size={11}
                        />
                      </TableCell> */}
                    </TableRow>
                  );
                })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </ColDiv>
  );

  const userContainer = () => {
    return (
      <ColDiv>
        <Spacer size={2} direction="col" />
        <AppAlert
          open={open}
          title={`Delete (${deleteUser})`}
          message={"Are you sure to delete user?"}
          onClose={() => setOpen(false)}
          onConfirm={() => {
            // dispatch(onDeleteUser(deleteId));
            setKeyword("");
            setDeleteId(0);
            setDeleteUser("");
            setOpen(false);
          }}
        />

        <ColDiv
          style={{
            width: "100%",
            display: "row",
            maxWidth: "1100px",

            justifyContent: "space-around",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <RowDiv>
            <Lbl
              title={title ? title : "Users"}
              size={25}
              bold={600}
              backgroundColor="#f3f3f3"
            />
          </RowDiv>
          <Spacer size={2} direction="col" />
          {onTapAddUser && (
            <RowDiv
              style={{
                justifyContent: "flex-end",
              }}
            >
              <TapButton
                bgColor={AppCSS.BLUE}
                height={42}
                onTap={() => onTapAddUser && onTapAddUser()}
                title="Create New User"
                width={200}
              />
            </RowDiv>
          )}

          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            <Spacer direction="col" />
            {Array.isArray(users) && employeeTable()}
          </RowDiv>
        </ColDiv>
      </ColDiv>
    );
  };

  return userContainer();
};
