import React from "react";
import RowDetailsDrawer from "../SideDrawer/index";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import Box from "@mui/material/Box";
import DisplayRowDetailsCard from "../ItemCreationComponent/DisplayRowDetails";

interface ViewRowDetailsDrawerProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  rowDetailsData?: Record<string, any>;
  detailsOfRowToBeDisplay: any[];
  rowArrayTableComponent?: (value: any[], key?: string) => JSX.Element | string;
  rowArrayTableComponentTwo?: (
    value: any[],
    key?: string
  ) => JSX.Element | string;
  labelTitle: string;
  key?: string;
}

const ViewRowDetailsDrawer: React.FC<ViewRowDetailsDrawerProps> = ({
  openDrawer,
  handleDrawerClose,
  rowDetailsData,
  labelTitle,
  rowArrayTableComponent,
  rowArrayTableComponentTwo,
  detailsOfRowToBeDisplay,
  key = labelTitle,
}) => {
  return (
    <RowDetailsDrawer open={openDrawer} onClose={handleDrawerClose}>
      <SideDrawerHeader
        handleDrawerClose={handleDrawerClose}
        labelTitle={labelTitle}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          paddingX: "20px",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "calc(100% - 20px)",
            marginRight: "20px",
            marginBottom: "20px",
          }}
        >
          {detailsOfRowToBeDisplay.map((detail, index) => {
            const value =
              rowDetailsData?.[detail.key] === true
                ? "True"
                : rowDetailsData?.[detail.key] === false
                ? "False"
                : rowDetailsData?.[detail.key];

            return (
              <DisplayRowDetailsCard
                key={`${labelTitle}  ${index}`}
                label={detail.label}
                value={
                  detail.isArray && value
                    ? detail.componentNumber === "two"
                      ? rowArrayTableComponentTwo(value, key)
                      : rowArrayTableComponent(value, key)
                    : value
                }
                isArray={detail.isArray}
                isObject={detail.isObject}
                objectDetails={detail.objectDetails}
                isColumn={detail.isColumn}
                isAmount={detail.isAmount}
              />
            );
          })}
        </Box>
      </Box>
    </RowDetailsDrawer>
  );
};

export default ViewRowDetailsDrawer;
