/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from "react";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Container } from "../../../../globalstyled";
import { AppCSS, Spacer, TxtInput } from "../../../../components";
import { ColDiv } from "../../../../components/misc/misc.styled";
import LeadsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import LeadsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import { MATERIAL_CATEGORIES_TABLE_HEADER_LIST } from "../../../../contants/dashboardHeader";
import { MaterialCategories } from "../../../../redux/types/creation";
import MaterialCategoriesDrawer from "../SideDrawer/index";
import { FormTitleTypography } from "../itemCreation.styled";
import CancelAndSubmitButtons from "../ItemCreationComponent/SubmitAndCancelButton";
import SearchAndCreateComponent from "../ItemCreationComponent/SearchAndCreateComponent";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import Pagination from "../ItemCreationComponent/Pagination";
import { CreateMaterialCategoryInput } from "../../../../types/graphQlQuery.interface";
import { useCreateMaterialCategory } from "../../../../apolloClient/hooks/itemCreation/createHooks";
import EmptyTable from "../../../../components/table/EmptyTable";

interface MaterialCategoriesTableProps {
  materialCategoriesData: MaterialCategories[];
  refetchMaterialCategories?: Function;
}

export const MaterialCategoriesTable: React.FC<
  MaterialCategoriesTableProps
> = ({ materialCategoriesData, refetchMaterialCategories }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const [materialCategoriesName, setMaterialCategoriesName] =
    useState<string>("");

  const { createMaterialCategory } = useCreateMaterialCategory();

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterMaterialCategories = () => {
    if (keyword.length > 0 && Array.isArray(materialCategoriesData)) {
      return materialCategoriesData.filter((item) => {
        const materialCategoryName = `${item.name}`;
        return materialCategoryName
          .toLowerCase()
          .includes(keyword.toLowerCase());
      });
    }
    return materialCategoriesData;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterMaterialCategories().length / rowsPerPage) - 1
      )
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const onTapSubmitForm = async () => {
    const validationRules = [
      {
        condition: !materialCategoriesName,
        message: "Please enter a material categories name!",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    const input: CreateMaterialCategoryInput = {
      name: materialCategoriesName,
    };

    const success = await createMaterialCategory(input);
    if (success) {
      toast("Material Category created successfully.", {
        type: "success",
        className: "toast-message",
      });
      refetchMaterialCategories();
      handleDrawerClose();
      setMaterialCategoriesName("");
      return;
    } else {
      console.error("Failed to create product system.");
    }
  };

  const createMiscFieldsForm = () => {
    return (
      <MaterialCategoriesDrawer open={openDrawer} onClose={handleDrawerClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            height: "80%",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={handleDrawerClose}
            labelTitle="Create new material category"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <ColDiv
                style={{
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>
                    Material Category Name
                  </FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter category name"
                  onChange={setMaterialCategoriesName}
                  required={true}
                  value={materialCategoriesName}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleDrawerClose}
          onCreate={onTapSubmitForm}
        />
      </MaterialCategoriesDrawer>
    );
  };

  const MaterialCategoriesTable = () => {
    return (
      <Container>
        <ColDiv
          style={{
            width: "100%",
            gap: "10px",
          }}
        >
          <ColDiv
            style={{
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "151px",
            }}
          >
            <SearchAndCreateComponent
              handleSearchIconClick={handleSearchIconClick}
              handleDrawerOpen={() => {
                handleDrawerOpen();
              }}
              setKeyword={setKeyword}
              inputRef={inputRef}
              searchPlaceHolder="Search material categories"
            />
            <Box
              sx={{
                height: "calc(100% - 65px)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeadsTableHeaderCard
                headers={MATERIAL_CATEGORIES_TABLE_HEADER_LIST}
              />
            </Box>
          </ColDiv>
          {filterMaterialCategories().length === 0 ? (
            <EmptyTable message="No material category available" />
          ) : (
            filterMaterialCategories()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <LeadsTableBodyCard
                  key={`Material categories field ${rowIndex}`}
                  bodyContent={[
                    {
                      text: `${row.id}`,
                      width: "40%",
                      fontSize: "15px",
                      paddingLeft: "50px",
                    },
                    {
                      text: `${row.name}`,
                      width: "60%",
                      paddingLeft: "50px",
                    },
                  ]}
                />
              ))
          )}
        </ColDiv>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          filterData={filterMaterialCategories}
        />
      </Container>
    );
  };

  const materialCategoriesContainer = () => {
    return (
      <Container
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <ColDiv
          style={{
            justifyContent: "start",
          }}
        >
          {Array.isArray(materialCategoriesData) && MaterialCategoriesTable()}
        </ColDiv>
        {createMiscFieldsForm()}
      </Container>
    );
  };

  return materialCategoriesContainer();
};
