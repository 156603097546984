import { HeaderList } from "../types/interface";

export const LEADSTABLEHEADERLIST: HeaderList[] = [
  { text: "Address", width: "30%" },
  { text: "Type", width: "10%" },

  { text: "Created At", width: "10%" },
];

export const WAITLISTSTABLEHEADERLIST: HeaderList[] = [
  { text: "City", width: "13%" },
  { text: "State", width: "16%" },
  { text: "Created At", width: "12%" },
];

export const PROJECTSTABLEHEADERLIST: HeaderList[] = [
  { text: "Project ID", width: "10%" },
  { text: "Customer ID", width: "20%" },
  { text: "Name", width: "20%" },
  { text: "Date Created", width: "35%" },
  { text: "", width: "8%", justifyContent: "center" },
];

export const PRODUCT_SYSTEM_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "30%", paddingLeft: "50px" },
  { text: "Name", width: "40%", paddingLeft: "50px" },
  { text: "", width: "30%", justifyContent: "end" },
];

export const PAYMENT_METHOD_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "5%" },
  { text: "Payment Name", width: "30%" },
  { text: "% / Fixed Amount  of Project Total", width: "50%" },
  { text: "Step", width: "15%" },
];

export const PAYMENT_METHOD_TABLE_WITH_DELETE_ACTION_HEADER_LIST: HeaderList[] =
  [
    { text: "Payment Name", width: "30%" },
    { text: "% of Project Total", width: "25%" },
    { text: "Fixed Amount", width: "15%" },
    { text: "Step", width: "15%" },
    { text: "", width: "15%", justifyContent: "center" },
  ];

export const MISC_FIELDS_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "20%", paddingLeft: "50px" },
  { text: "Name", width: "40%", paddingLeft: "50px" },
  { text: "", width: "40%", justifyContent: "end" },
];

export const PRODUCT_SYSTEM_PARENT_IDS_HEADER_LIST: HeaderList[] = [
  { text: "Product system parent Ids", width: "100%" },
];

export const MATERIAL_CATEGORIES_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "40%", paddingLeft: "50px" },
  { text: "Name", width: "60%", paddingLeft: "50px" },
];

export const MATERIAL_MEASUREMENTS_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "20%", paddingLeft: "50px" },
  { text: "Name", width: "30%" },
  { text: "Measurement Unit", width: "30%" },
  { text: "", width: "20%", justifyContent: "end" },
];

export const MATERIAL_ITEMS_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "10%", paddingLeft: "30px" },
  { text: "Name", width: "20%" },
  { text: "Brand", width: "20%" },
  { text: "Price", width: "20%" },
  { text: "Measurement Unit", width: "25%" },
  { text: "", width: "15%", justifyContent: "end" },
];

export const MATERIAL_ITEMS_PHOTO_TABLE_HEADER_LIST_HEADER_LIST: HeaderList[] =
  [{ text: "Photo", width: "100%" }];

export const LABOR_MEASUREMENTS_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "10%", paddingLeft: "30px" },
  { text: "Name", width: "30%", paddingLeft: "50px" },
  { text: "Measurement Unit", width: "30%" },
  { text: "", width: "30%", justifyContent: "end" },
];

export const CONTRACTORS_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "10%", paddingLeft: "30px" },
  { text: "Name", width: "20%" },
  { text: "Website", width: "20%" },
  { text: "Description", width: "35%" },
  { text: "", width: "15%", justifyContent: "end" },
];

export const LICENSES_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "Name", width: "30%" },
  { text: "Description", width: "50%" },
  { text: "Photo", width: "20%" },
];

export const FILE_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "10%" },
  { text: "Name", width: "15%" },
  { text: "Description", width: "30%" },
  { text: "Type", width: "20%" },
  { text: "Action", width: "25%", justifyContent: "center" },
];

export const REVIEWS_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "10%", paddingLeft: "50px" },
  { text: "Customer ID", width: "20%" },
  { text: "Rating", width: "20%" },
  { text: "Review", width: "50%" },
];

export const TRADES_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "10%" },
  { text: "Name", width: "15%" },
  { text: "Description", width: "30%" },
  { text: "Years Doing Trade", width: "30%" },
  { text: "Action", width: "15%", justifyContent: "center" },
];

export const LABOR_ITEMS_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "5%", paddingLeft: "10px" },
  { text: "Description", width: "20%" },
  { text: "Price", width: "15%" },
  { text: "Measurement Unit", width: "25%" },
  { text: "Coverage Amount", width: "20%" },
  { text: "", width: "15%", justifyContent: "end" },
];

export const CONTRACTOR_REPORT_TYPES_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "10%", paddingLeft: "10px" },
  { text: "Name", width: "20%" },
  { text: "Responsibility", width: "20%" },
  { text: "Description", width: "30%" },
  { text: "", width: "20%", justifyContent: "end" },
];

export const CONTRACTOR_CHANGE_ORDERS_TABLE_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "5%", paddingLeft: "10px" },
  { text: "Name", width: "15%" },
  { text: "Material Price", width: "20%" },
  { text: "Labor Price", width: "15%" },
  {
    text: "Material Id",
    width: "15%",
  },
  {
    text: "Labor Id",
    width: "15%",
  },

  { text: "", width: "15%", justifyContent: "end" },
];

export const PRODUCT_SYSTEM_PARENT_IDS_TABLE_ROW_HEADER_LIST: HeaderList[] = [
  { text: "Parent Id", width: "100%", paddingLeft: "50px" },
];

export const FILE_UPLOAD_TABLE_ROW_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "5%" },
  { text: "Name", width: "20%" },
  { text: "Description", width: "30%" },
  { text: "File Type", width: "25%" },
  { text: "Action", width: "20%" },
];

export const MATERIAL_ITEMS_PICTURES_TABLE_ROW_HEADER_LIST: HeaderList[] = [
  { text: "Photo", width: "100%", paddingLeft: "50px" },
];

export const PHOTOS_ROW_HEADERS: HeaderList[] = [
  { text: "Photo Name", width: "30%" },
  { text: "Description", width: "40%" },
  { text: "Photo", width: "30%" },
];

export const TRADE_TABLE_VIEW_ROW_HEADER_LIST: HeaderList[] = [
  { text: "ID", width: "5%" },
  { text: "Trade Name", width: "25%" },
  { text: "Description", width: "40%" },
  { text: "Years Doing Trade", width: "30%" },
];

export const INSURANCE_COMPANY_TABLE_HEADER: HeaderList[] = [
  { text: "Company Name", width: "30%", paddingLeft: "30px" },
  { text: "Company Logo", width: "20%", justifyContent: "center" },
  { text: "Claim Link", width: "30%", paddingLeft: "30px" },
  { text: "Action", width: "20%", justifyContent: "center" },
];

export const MATERIAL_DETAILS_TABLE_HEADER: HeaderList[] = [
  { text: "Material Brand", width: "25%", paddingLeft: "20px" },
  { text: "Description", width: "40%" },
  { text: "Price", width: "20%" },
  { text: "Warranty", width: "15%", justifyContent: "center" },
];

export const ESTIMATE_TABLE_HEADER: HeaderList[] = [
  { text: "Document Name", width: "50%", paddingLeft: "20px" },
  { text: "Document Link", width: "50%" },
];

export const PAYMENT_DETAILS_TABLE_HEADER: HeaderList[] = [
  { text: "Transaction ID", width: "25%", paddingLeft: "20px" },
  { text: "Step", width: "10%" },
  { text: "Payment Type", width: "15%" },
  { text: "Payment Amount", width: "15%" },
  { text: "Approved", width: "10%" },
  { text: "", width: "10%", justifyContent: "center" },
  { text: "", width: "15%", justifyContent: "center" },
];

export const DOCUMENTS_TABLE_HEADER: HeaderList[] = [
  { text: "Document Type", width: "40%", paddingLeft: "20px" },
  { text: "Document Link", width: "30%", justifyContent: "center" },
];
