import styled from "@emotion/styled";
import { Typography, TypographyProps } from "@mui/material";
import { Box } from "@mui/system";

interface TitleTypographyProps extends TypographyProps {
  mobileFontSize?: string;
  // Add other custom properties if needed
}

export const ArrowIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const FormTitleTypography = styled(Typography)`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
`;

export const StandardIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const ProjectIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const TabsBox = styled(Box)`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  overflow-y: auto;
  font-size: 12px @media only screen and (min-width: 640px) {
    font-size: 16px;
  }
`;

export const LeadIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const SearchIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const SearchInputBox = styled(Box)`
  width: 100%;
  height: 35px;
  @media only screen and (min-width: 480px) {
    width: 342px;
  }
`;

export const PreviousIcon = styled.img`
  width: 8px;
  height: 12px;
`;

export const RowLeadIconDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 480px) {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const TitleTypography = styled(Typography)<TitleTypographyProps>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 10px;
  ${({ fontSize, color, paddingLeft, mobileFontSize }) => `
padding-left:${paddingLeft ? paddingLeft : "0px"};
font-size: ${mobileFontSize ? mobileFontSize : "12px"};
// color: ${color ? color : "black"};
    @media only screen and (min-width: 640px) {
      font-size: ${fontSize ? fontSize : "16px"};
    }
  `}
`;
