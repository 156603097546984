/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { AppCSS, TxtInput, ChkInputBox } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "../../../../globalstyled";

import { Box, Typography, Autocomplete, TextField } from "@mui/material";
import UpArrowIcon from "../../../../images/UploadIcon.png";
import DownArrowIcon from "../../../../images/DownloadIcon.png";

import LeadsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import LeadsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import { MATERIAL_MEASUREMENTS_TABLE_HEADER_LIST } from "../../../../contants/dashboardHeader";

import {
  MaterialCategories,
  MaterialMeasurements,
  ProductSystem,
  ProductSystemForMiscField,
} from "../../../../redux/types/creation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MaterialMeasurementsDrawer from "../SideDrawer/index";
import TableViewDetails from "../../../../components/table/TableViewDetails";
import { ArrowIcon, FormTitleTypography } from "../itemCreation.styled";
import CancelAndSubmitButtons from "../ItemCreationComponent/SubmitAndCancelButton";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import SearchAndCreateComponent from "../ItemCreationComponent/SearchAndCreateComponent";
import Pagination from "../ItemCreationComponent/Pagination";
import ViewRowDetailsDrawer from "../ItemCreationComponent/ViewFieldDetails";
import { MATERIAL_MEASUREMENT_DRAWER_DETAILS } from "../../../../contants/itemsCreation";
import {
  extractCheckedIds,
  getMaterialCategoryParentIdsOptions,
} from "../../../../utils/formatting";
import { useCreateMaterialMeasurement } from "../../../../apolloClient/hooks/itemCreation/createHooks";
import { CreateMaterialMeasurementInput } from "../../../../types/graphQlQuery.interface";
import EmptyTable from "../../../../components/table/EmptyTable";
import ProductSystemParentIdsTable from "../ItemCreationComponent/ProductSystemParentIdsTable";

interface MaterialMeasurementsTableProps {
  materialMeasurementsData: MaterialMeasurements[];
  refetchMaterialMeasurement?: Function;
  productsIds?: ProductSystem[];
  materialCategories?: MaterialCategories[];
}

export const MaterialMeasurementsTable: React.FC<
  MaterialMeasurementsTableProps
> = ({
  materialMeasurementsData,
  refetchMaterialMeasurement,
  productsIds,
  materialCategories,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [keyword, setKeyword] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openRowDetailsDrawer, setOpenRowDetailsDrawer] =
    useState<boolean>(false);
  const [viewRowDetailsData, setViewRowDetailsData] =
    useState<MaterialMeasurements | null>(null);
  const [force, setForce] = useState(false);

  const [materialMeasurementName, setMaterialMeasurementName] =
    useState<string>("");
  const [measurementUnit, setMeasurementUnit] = useState<string>("");
  const [materialCategoriesId, setMaterialCategoriesId] = useState<
    number | null
  >(null);
  const [showProductSystemParent, setShowProductSystemParent] =
    useState<boolean>(false);

  const [checkboxProductSystemStates, setCheckboxProductSystemStates] =
    useState<{
      [key: number]: boolean;
    }>({});

  const { createMaterialMeasurement } = useCreateMaterialMeasurement();

  const handleSelectMaterialCategoryParentId = (
    event,
    selectedMaterialCategory
  ) => {
    if (selectedMaterialCategory) {
      setMaterialCategoriesId(selectedMaterialCategory.id);
    }
  };

  const viewRowDetails = (rowData: MaterialMeasurements) => {
    setViewRowDetailsData(rowData);
    handleViewRowDetailsDrawerOpen();
  };

  const handleViewRowDetailsDrawerOpen = () => {
    setOpenRowDetailsDrawer(true);
  };

  const handleViewRowDetailsDrawerClose = () => {
    setOpenRowDetailsDrawer(false);
  };

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterMaterialMeasurement = () => {
    if (keyword.length > 0 && Array.isArray(materialMeasurementsData)) {
      return materialMeasurementsData.filter((item) => {
        const materialMeasurementsName = `${item.name}`;
        const materialMeasurementsUnit = `${item.unit}`;
        return (
          materialMeasurementsName
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          materialMeasurementsUnit.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    }
    return materialMeasurementsData;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterMaterialMeasurement().length / rowsPerPage) - 1
      )
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const onTapSubmitForm = async () => {
    const validationRules = [
      {
        condition: !materialMeasurementName,
        message: "Please Enter the material measurement name",
      },
      {
        condition: !measurementUnit,
        message: "Please Enter the material measurement unit",
      },
      {
        condition: !extractCheckedIds(checkboxProductSystemStates).length,
        message: "Please select and add the product system id",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    const checkedParentIds = extractCheckedIds(checkboxProductSystemStates);

    const input: CreateMaterialMeasurementInput = {
      name: materialMeasurementName,
      unit: measurementUnit,
      parentIds: checkedParentIds,
      categoryId: materialCategoriesId,
      forceCustomerSelection: force,
    };

    const success = await createMaterialMeasurement(input);
    if (success) {
      toast("Material measurement created successfully.", {
        type: "success",
        className: "toast-message",
        position: "top-center",
      });
      refetchMaterialMeasurement();
      handleDrawerClose();
      setMaterialMeasurementName("");
      setMeasurementUnit("");
      setMaterialCategoriesId(null);
      setCheckboxProductSystemStates({});
      setShowProductSystemParent(!showProductSystemParent);
      return;
    } else {
      console.log("Failed to create material measurement.");
    }
  };

  const createMaterialMeasurementsForm = () => {
    return (
      <MaterialMeasurementsDrawer open={openDrawer} onClose={handleDrawerClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={handleDrawerClose}
            labelTitle="Create new material measurement"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <ColDiv
                style={{
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <FormTitleTypography>Name</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter measurement name"
                  onChange={setMaterialMeasurementName}
                  required={true}
                  value={materialMeasurementName}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <FormTitleTypography>
                    Force Customer Selection
                  </FormTitleTypography>
                </Box>
                <input
                  type="checkbox"
                  className="w-5 h-5"
                  onChange={() => setForce(!force)}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <FormTitleTypography>Measurement unit</FormTitleTypography>
                </Box>

                <TxtInput
                  placeholder="Enter measurement unit"
                  onChange={setMeasurementUnit}
                  required={true}
                  value={measurementUnit}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Lbl
                    title="Associated product systems"
                    size={16}
                    width={80}
                    bold={600}
                  />
                </Box>

                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "inherit",
                    borderRadius: "10px",
                    border: "1px solid rgba(173, 173, 173, 1)",
                    height: "fit-content",
                    minHeight: "50px",
                    paddingY: "10px",
                  }}
                  onClick={() =>
                    setShowProductSystemParent(!showProductSystemParent)
                  }
                >
                  <RowDiv
                    style={{
                      width: "100%",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Lbl
                        title="Select associated product systems"
                        size={16}
                        mobileFontSize={16}
                        isFullWidth={true}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "40%",
                        alignItems: "center",
                        justifyContent: "end",
                        paddingRight: "20px",
                      }}
                    >
                      {showProductSystemParent ? (
                        <ArrowIcon src={UpArrowIcon} />
                      ) : (
                        <ArrowIcon src={DownArrowIcon} />
                      )}
                    </Box>
                  </RowDiv>

                  {showProductSystemParent && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "start",
                        width: "100%",
                        paddingX: "20px",
                      }}
                    >
                      {productsIds.map((item) => (
                        <Box
                          key={`checkProductsSystemParent misc ${item.id}`}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            cursor: "pointer",
                            justifyContent: "start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <ChkInputBox
                            onChange={(value: boolean) => {
                              setCheckboxProductSystemStates(
                                (prevCheckboxStates) => ({
                                  ...prevCheckboxStates,
                                  [item.id]: value,
                                })
                              );
                              setShowProductSystemParent(true);
                            }}
                            placeholder=""
                            width={50}
                            marginTop={0}
                            marginBottom={0}
                            height={20}
                            background="inherit"
                            checked={
                              checkboxProductSystemStates[item.id] || false
                            }
                          />
                          <Typography>{item.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <FormTitleTypography>
                    Select associated material category
                  </FormTitleTypography>
                </Box>
                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    background: "inherit",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={getMaterialCategoryParentIdsOptions(
                        materialCategories
                      )}
                      sx={{ width: "100%" }}
                      onChange={handleSelectMaterialCategoryParentId}
                      renderInput={(params) => (
                        <TextField {...params} label="Search categories" />
                      )}
                    />
                  </Box>
                </Box>
              </ColDiv>
            </Box>
          </Box>
        </Box>
        <CancelAndSubmitButtons
          onCancel={handleDrawerClose}
          onCreate={onTapSubmitForm}
        />
      </MaterialMeasurementsDrawer>
    );
  };

  const showMaterialMeasurementRowDetails = () => {
    return (
      <ViewRowDetailsDrawer
        openDrawer={openRowDetailsDrawer}
        handleDrawerClose={handleViewRowDetailsDrawerClose}
        rowDetailsData={viewRowDetailsData}
        labelTitle="Material Measurements Details"
        rowArrayTableComponent={(
          value: ProductSystemForMiscField[],
          key?: string
        ) => <ProductSystemParentIdsTable value={value} key={key} />}
        detailsOfRowToBeDisplay={MATERIAL_MEASUREMENT_DRAWER_DETAILS}
      />
    );
  };

  const MaterialMeasurementTable = () => {
    return (
      <Container>
        <ColDiv
          style={{
            width: "100%",
            gap: "10px",
          }}
        >
          <ColDiv
            style={{
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "151px",
            }}
          >
            <SearchAndCreateComponent
              handleSearchIconClick={handleSearchIconClick}
              handleDrawerOpen={() => {
                handleDrawerOpen();
              }}
              setKeyword={setKeyword}
              inputRef={inputRef}
              searchPlaceHolder="Search material measurements"
            />
            <Box
              sx={{
                height: "calc(100% - 65px)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeadsTableHeaderCard
                headers={MATERIAL_MEASUREMENTS_TABLE_HEADER_LIST}
              />
            </Box>
          </ColDiv>

          {filterMaterialMeasurement().length === 0 ? (
            <EmptyTable message="No material measurement available" />
          ) : (
            filterMaterialMeasurement()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <LeadsTableBodyCard
                  key={`Material measurement ${rowIndex}`}
                  bodyContent={[
                    {
                      text: `${row.id}`,
                      width: "20%",
                      fontSize: "15px",
                      paddingLeft: "50px",
                    },
                    {
                      text: row.name,
                      width: "30%",
                    },
                    {
                      text: row.unit,
                      width: "30%",
                    },
                    {
                      text: "",
                      width: "20%",
                      justifyContent: "end",
                      isAction: true,
                      actionComponent: (
                        <TableViewDetails
                          rowData={row}
                          onViewClick={viewRowDetails}
                          text={"View"}
                        />
                      ),
                    },
                  ]}
                />
              ))
          )}
        </ColDiv>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          filterData={filterMaterialMeasurement}
        />
      </Container>
    );
  };

  const materialMeasurementContainer = () => {
    return (
      <Container
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <ColDiv
          style={{
            justifyContent: "start",
          }}
        >
          {Array.isArray(materialMeasurementsData) &&
            MaterialMeasurementTable()}
        </ColDiv>
        {createMaterialMeasurementsForm()}
        {showMaterialMeasurementRowDetails()}
      </Container>
    );
  };

  return materialMeasurementContainer();
};
