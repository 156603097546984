/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppAlert } from "../../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../../redux/hooks";
import { ContractorsTable } from "./ContractorsTable";
import { setContractors } from "../../../../redux/reducers";
import { Container } from "../../../../globalstyled";
import AppLoader from "../../../../components/loader";
import { useFetchContractor } from "../../../../apolloClient/hooks/itemCreation/fetchHooks";

interface ContractorsProp {}

const Contractor: React.FC<ContractorsProp> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { contractors } = userReducer;
  const { materialMeasurements, productSystems, materialCategories } =
    userReducer;

  const { loading, error, contractorsData, refetch } = useFetchContractor({
    limit: 100,
    offset: 0,
  });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    onFetchContractor();
  }, [loading, error, contractorsData, dispatch, handleRefetch]);

  const onFetchContractor = async () => {
    if (!loading && !error && contractorsData) {
      dispatch(setContractors(contractorsData));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Contractors", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <ContractorsTable
          contractorsData={contractors}
          refetchContractors={handleRefetch}
          productsIds={productSystems}
        />
      </Container>
    );
  };

  return hostContainer();
};

export default Contractor;
