import React from "react";
import { ReactNode } from "react";
import { BodyBox, CardTypography } from "./table.styled";

interface EmptyTableProps {
  message?: string | ReactNode;
  containerWidth?: string;
  containerHeight?: string;
}

const EmptyTable: React.FC<EmptyTableProps> = ({
  message,
  containerWidth,
  containerHeight,
}) => {
  return (
    <BodyBox
      width={containerWidth}
      height={containerHeight}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <CardTypography variant="body1" color="black" width={"100%"}>
        {message || "No data available"}
      </CardTypography>
    </BodyBox>
  );
};

export default EmptyTable;
