/* eslint-disable jsx-a11y/alt-text */
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  IconButton,
} from "@mui/material";
import { AppCSS, Lbl, Spacer, TapButton } from "../../../../components";
import { Prompt } from "../../../../components/dialogue/Dialogue";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { SFileUpload } from "../../../../components/input/input.styled";
import {
  RowDiv,
  ColDiv,
  UnderlineRowDiv,
} from "../../../../components/misc/misc.styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FC, useState } from "react";
import { RemoveProject } from "../../../../redux/api";
import { toast } from "react-toastify";
import { Docs, Project } from "../../../../redux/types";
import { useMutation } from "@apollo/client";
import { DELETE_PROJECT } from "../../../../apolloClient/operation/mutations";
import { CustomerProjectDetails } from "../../../../redux/types/projectDetails";

interface CustomerProjectProps {
  project: CustomerProjectDetails;
  docs?: Docs[];
  onDelete: Function;
}

export const DeleteProject: FC<CustomerProjectProps> = ({
  project,
  docs,
  onDelete,
}) => {
  const [openConfirm, setOpenConfirm] = useState(false);

  const [deleteProject] = useMutation<
    { deleteProject: boolean },
    { id: number }
  >(DELETE_PROJECT);

  const onTapDelete = async () => {
    if (!project) {
      toast("Project can not delete!", {
        type: "error",
      });
      return;
    }
    setOpenConfirm(false);
    try {
      deleteProject({ variables: { id: project.projectId } })
        .then(() => toast("Successfully deleted project.", { type: "success" }))
        .catch((error) => toast("Error deleting project", { type: "error" }));
    } catch (error) {
      toast("Error deleting project", { type: "error" });
    }
  };

  const removeSection = () => {
    return (
      <>
        <UnderlineRowDiv style={{ marginLeft: 20 }}>
          <h2>Danger Zone</h2>
        </UnderlineRowDiv>
        <Spacer size={1} direction="col" />
        <Prompt
          message="Delete complete project?"
          onConfirm={() => onTapDelete()}
          onClose={() => setOpenConfirm(false)}
          open={openConfirm}
        />
        <RowDiv
          style={{
            justifyContent: "flex-start",
            width: "100%",
            marginLeft: 30,
          }}
        >
          <ColDiv
            style={{
              width: "30%",
            }}
          >
            <RowDiv>
              <TapButton
                height={40}
                width={200}
                bgColor={AppCSS.WHITE}
                borderColor={AppCSS.DANGER}
                color={AppCSS.DANGER}
                onTap={() => setOpenConfirm(true)}
                title="Archive Project"
              />
            </RowDiv>
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
      </>
    );
  };

  return removeSection();
};
