/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useState } from "react";
import { AppCSS } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { FileType } from "../../../redux/types";
import {
  setCustomerDetails,
  setCustomerProjectDetails,
  setEstimate,
} from "../../../redux/reducers";

import { CertificateOfCompletion } from "./CertificateOfCompletion";
import { ProgressSlider } from "./ProgressSlider";
import { CustomerDetails } from "./CustomerDetails";
import { InsuranceDetails } from "./InsuranceDetails";
import { MaterialDetails } from "./MaterialDetails";
import { RoofDetails } from "./RoofDetails";
import { InstallationDetails } from "./InstallationDetails";
import { PaymentDetails } from "./PaymentDetails";
import { DocumentDetails } from "./Documents";
import { EstimateDetails } from "./Estimate";
import { GetEstimate, GetEstimateAddOns } from "../../../redux/api/estimate";
import { Estimate, EstimateAddOns } from "../../../redux/types/Estimate";
import { DeleteProject } from "./Delete Project";
import { Tab, Tabs, Box } from "@mui/material";
import { ProjectIcon, TabsBox } from "./projectsDetails.styled";
import LeftArrowIcon from "../../../images/leftArrowIcon.png";
import AppLoader from "../../../components/loader/index";
import {
  INSURANCE_SCOPE_DETAILS,
  TABLABELS,
} from "../../../contants/projectDetails";
import {
  useCustomerById,
  useFetchProjectDetails,
  useProjectById,
} from "../../../apolloClient/hooks/general/fetchHook";
import { getFilesByType } from "../../../utils/project";
import { CustomerProjectDetails } from "../../../redux/types/projectDetails";
import InsuranceScopeTap from "./InsuranceDetails/InsuranceScopeTab";

interface DashboardProps {}

export const ProjectDescription: React.FC<DashboardProps> = ({}) => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { customerProjectDetails, projectDetails, estimate, estimateAddOns } =
    useAppSelector((state) => state.userReducer);

  const { project, progress, docs, projectMaterials } = projectDetails;
  const [value, setValue] = useState(0);
  const [userFullName, setUsersFullName] = useState<string>("");
  const [projectId, setProjectId] = useState<string>(id);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSkip, setIsSkip] = useState<boolean>(true);
  const [insuranceFileUrl, setInsuranceFileUrl] = useState<string>("");
  const [userProjectDetails, setuserProjectDetails] =
    useState<CustomerProjectDetails>(null);

  const {
    projectDetails: projectDetailsData,
    loading: projectDetailsLoading,
    error: projectDetailsError,
    refetch,
  } = useFetchProjectDetails({
    projectId: Number(id),
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRefetchProjectDetails = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    onFetchProjectDetails();
  }, [
    projectDetailsLoading,
    projectDetailsError,
    projectDetailsData,
    dispatch,
    handleRefetchProjectDetails,
  ]);

  useEffect(() => {
    if (!projectDetailsLoading && !projectDetailsError && projectDetailsData) {
      setIsLoading(false);
    }
  }, [
    projectDetailsLoading,
    projectDetailsError,
    projectDetailsData,
    dispatch,
  ]);

  const onFetchProjectDetails = () => {
    if (!projectDetailsLoading && !projectDetailsError && projectDetailsData) {
      dispatch(setCustomerProjectDetails(projectDetailsData));
      const userInsurancescope = getFilesByType(
        projectDetailsData.documents,
        FileType.INSURANCE
      );
      setInsuranceFileUrl(userInsurancescope[0]?.url);
      const userFullName = `${projectDetailsData.customerDetails.firstName} ${projectDetailsData.customerDetails.lastName}`;
      setUsersFullName(userFullName);
    } else if (projectDetailsError) {
      console.error("Error fetching projects:", projectDetailsError);
      setIsLoading(false);
    }
  };

  const onFetchEstimate = async () => {
    try {
      const data = await GetEstimate(Number(id));
      const response = data.data as Estimate;
      if (response) {
        dispatch(setEstimate(response));
      }
    } catch (error) {
      console.log(error);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const renderTabPanel = (index: number) => {
    switch (index) {
      case 0:
        return (
          <CustomerDetails
            onFetchProgress={() => {}}
            project={project}
            progress={progress}
            customerDetails={customerProjectDetails.customerDetails}
          />
        );
      case 1:
        return (
          <InsuranceDetails
            project={project}
            estimateAddOns={estimateAddOns}
            insuranceScopeUrl={insuranceFileUrl}
            insuranceScopeDetails={projectDetailsData.insuranceScope}
            customerProjectDetails={projectDetailsData}
            handleRefetchProjectDetails={handleRefetchProjectDetails}
          />
        );
      case 2:
        return <MaterialDetails materials={customerProjectDetails.materials} />;
      case 3:
        return (
          <EstimateDetails
            onFetchProject={onFetchEstimate}
            projectId={+id}
            estimate={estimate}
            docs={docs}
          />
        );
      case 4:
        return <InstallationDetails project={customerProjectDetails} />;
      case 5:
        return (
          <PaymentDetails
            paymentDetails={projectDetailsData.paymentDetails}
            onFetchProject={handleRefetchProjectDetails}
          />
        );
      case 6:
        return (
          <DocumentDetails
            onFetchProject={handleRefetchProjectDetails}
            project={projectDetailsData}
            docs={projectDetailsData.documents}
          />
        );
      case 7:
        return (
          <CertificateOfCompletion
            project={project}
            docs={docs}
            estimateAddOns={estimateAddOns}
            onFetchProject={() => {}}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: AppCSS.GRAY_LIGHTER,
        justifyContent: "center",
        flex: 1,
        paddingTop: 20,
        paddingBottom: 20,
        height: "100vh",
      }}
    >
      <ColDiv
        style={{
          width: "97%",
          height: "95%",
          marginBottom: "5%",
        }}
      >
        <RowDiv
          style={{
            width: "100%",
            height: "80px",
          }}
        >
          <ColDiv
            style={{
              width: "20%",
              height: "100%",
            }}
          >
            <RowDiv
              style={{
                justifyContent: "start",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <ProjectIcon
                src={LeftArrowIcon}
                onClick={() => {
                  navigate(-1);
                }}
                style={{
                  marginTop: "15px",
                  cursor: "pointer",
                }}
              />
              <ColDiv
                style={{
                  fontSize: "36px",
                }}
              >
                <Lbl
                  title={userFullName}
                  size={30}
                  bold={400}
                  mobileFontSize={12}
                  backgroundColor="#f3f3f3"
                />
                <Lbl
                  title={projectId}
                  size={12}
                  bold={200}
                  backgroundColor="#f3f3f3"
                />
              </ColDiv>
            </RowDiv>
          </ColDiv>
          <ColDiv
            style={{
              width: "80%",
              height: "100%",
            }}
          >
            <ProgressSlider progress={progress} />
          </ColDiv>
        </RowDiv>
        <RowDiv
          style={{
            width: "100%",
            height: "calc(100% - 100px)",
            gap: "10px",
            marginRight: "10px",
            marginBottom: "20px",
          }}
        >
          <ColDiv
            style={{
              width: "20%",
              height: "100%",
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
          >
            <TabsBox
              sx={{
                width: "100%",
                height: "100%",
                marginTop: "20px",
                overflowY: "auto",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                orientation="vertical"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                    background: "none",
                    border: "none",
                  },
                  hidden: true,
                }}
                aria-label="full width tabs example"
                variant="scrollable"
              >
                {TABLABELS.map((label, index) => (
                  <Tab
                    key={index}
                    label={label}
                    {...a11yProps(index)}
                    sx={{
                      width: "100%",
                      height: "38px",
                      background: value === index ? AppCSS.BLUE : "inherit",
                      marginRight: "30px",
                      marginBottom: "2px",
                      marginTop: "2px",
                      justifyContent: "center",
                      alignContent: "left",
                      alignItems: "flex-start",
                      color: AppCSS.BLACK,
                      "&.Mui-selected": {
                        color: AppCSS.WHITE,
                        background: AppCSS.BLUE,
                        borderRadius: "10px",
                      },
                    }}
                  />
                ))}
              </Tabs>
            </TabsBox>
          </ColDiv>
          <ColDiv
            style={{
              width: "80%",
              height: "100%",
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Box
              style={{
                width: "97%",
                marginRight: "3%",
                padding: "20px",
              }}
            >
              {renderTabPanel(value)}
            </Box>

            <DeleteProject
              project={customerProjectDetails}
              docs={docs}
              onDelete={() => navigate(-1)}
            />
          </ColDiv>
        </RowDiv>
      </ColDiv>
    </div>
  );
};
