/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";

import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../redux/hooks";
import { WaitlistTable } from "./WaitlistTable";
import { setWaitlist } from "../../../redux/reducers";
import { Container } from "../../../globalstyled";
import AppLoader from "../../../components/loader";
import { LeadsData } from "../../../types/graphQlQuery.interface";
import {
  GET_LEADS,
  GET_WAIT_LIST,
} from "../../../apolloClient/operation/querys/userQuery";

interface DashboardProps {}

interface WaitListData {
  waitlist: LeadsData[];
}

export const Waitlist: React.FC<DashboardProps> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { waitlist } = userReducer;

  const { loading, error, data } = useQuery<WaitListData>(GET_WAIT_LIST, {
    variables: { limit: 1000, offset: 0 },
  });

  useEffect(() => {
    onFetchWaitLists();
  }, [loading, error, data, dispatch]);

  console.log("waitlist", data);

  const onFetchWaitLists = () => {
    if (!loading && !error && data) {
      dispatch(setWaitlist(data.waitlist));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching leads:", error);
      setIsLoading(false);
    }
  };

  if (isLoading || !data) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <WaitlistTable />
      </Container>
    );
  };

  return hostContainer();
};
