import { Box } from "@mui/material";
import { TapButton, AppCSS } from "../../../../components";
import { RowDiv } from "../../../../components/misc/misc.styled";

interface CancelAndSubmitButtonsProps {
  onCancel: () => void;
  onCreate: () => void;
  cancelText?: string;
  submitText?: string;
  isDisabled?: boolean;
  bgColor?: string;
}

const CancelAndSubmitButtons: React.FC<CancelAndSubmitButtonsProps> = ({
  onCancel,
  onCreate,
  cancelText = "Cancel",
  submitText,
  isDisabled = false,
  bgColor,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <RowDiv
        style={{
          justifyContent: "center",
          gap: "20px",
          marginBottom: "50px",
        }}
      >
        <Box
          sx={{
            width: "200px",
          }}
        >
          <TapButton
            onTap={onCancel}
            title={cancelText}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.BLUE}
            color={AppCSS.BLUE}
            height={40}
            radius={10}
          />
        </Box>
        <Box
          sx={{
            width: "200px",
          }}
        >
          <TapButton
            onTap={onCreate}
            title={submitText ? submitText : "Create"}
            bgColor={AppCSS.BLUE}
            borderColor={bgColor ? bgColor : AppCSS.BLUE}
            color={AppCSS.WHITE}
            height={40}
            radius={10}
            disable={isDisabled}
          />
        </Box>
      </RowDiv>
    </Box>
  );
};

export default CancelAndSubmitButtons;
