/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import { useState, FC } from "react";
import { Container } from "../../../../globalstyled";
import {
  CreateVentsInput,
  MatColor,
  Material,
  UpdateMaterial,
} from "../../../../redux/types/Material";
import PlaceHolder from "../../../../images/placeholder.jpeg";
import {
  CreateVents,
  GetMaterials,
  UpdateCurrentMaterial,
} from "../../../../redux/api/materials";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { setMaterials } from "../../../../redux/reducers";
import { MATERIALS_FLASH_TABLE_HEADER } from "../MaterialsSectionHeader";
import MaterialFlashTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import MaterialFlashTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import { NumericFormat } from "react-number-format";
import MaterialEditText from "../../../../components/DashboardComponent/MaterialEditText";
import EmptyTable from "../../../../components/table/EmptyTable";

interface UserTableProps {
  materials: Material[];
  onUploadFlashing: Function;
}

export const FlashTable: FC<UserTableProps> = ({
  materials,
  onUploadFlashing,
}) => {
  const dispatch = useDispatch();

  const [keyword, setKeyword] = useState("");

  const [isAddFlash, setIsAddFlash] = useState(false);
  const [editFlashing, setEditFlashing] = useState(false);
  const [currentFlashing, setCurrentFlashing] = useState<Material>();
  const material_type = "flashing";
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [coverage_size, setCoverageSize] = useState("");
  const [unit, setUnit] = useState("");

  const resetForm = () => {
    setBrand("");
    setProduct("");
    setDescription("");
    setPrice("");
    setUnit("");
    setCoverageSize("");
  };

  const onTapCreateFlashing = async () => {
    const input: CreateVentsInput = {
      material_type,
      brand,
      product,
      price: price,
      description,
      coverage_size: +coverage_size,
      unit: unit,
      home_photo: "",
    };
    try {
      const result = await CreateVents(input);
      if (result) {
        setIsAddFlash(false);
        toast("New Flashing created!", {
          type: "success",
        });
        resetForm();
      } else {
        setIsAddFlash(false);
        toast("Error while creating Flashing!", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchMaterials = async () => {
    try {
      dispatch(setMaterials([]));
      const data = await GetMaterials(material_type);
      const userData = data as {
        message: string;
        data: {
          materials: Material[];
        };
      };
      if (Array.isArray(userData.data.materials)) {
        dispatch(setMaterials(userData.data.materials));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onTapSave = async () => {
    if (!currentFlashing) {
      toast("Error while updating Flashing!", {
        type: "error",
      });
      return;
    }
    const input: UpdateMaterial = {
      price: price,
      description: description,
      product: product,
      unit: unit,
      coverage_size: coverage_size,
    };
    try {
      const result = await UpdateCurrentMaterial(currentFlashing.id, input);
      if (result === 200) {
        toast("Flashing Updated Successfully!", {
          type: "success",
        });
        await onFetchMaterials();
        setEditFlashing(false);
        resetForm();
      } else {
        toast("Error while updating Flashing!", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AddFlashing = () => {
    return (
      <ColDiv
        style={{
          marginTop: 20,
          background: "#fff",
          boxShadow: "1px 1px 5px 1px #DBDBDB",
          borderRadius: 5,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 20,
          marginBottom: "5%",
        }}>
        <MaterialEditText text={"Create Flashing"} />
        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}>
          <ColDiv>
            <Lbl title="Brand" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={brand} placeholder="Brand" onChange={setBrand} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={product}
              placeholder="Product"
              onChange={setProduct}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}>
          <ColDiv>
            <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={description}
              placeholder="Description"
              onChange={setDescription}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={price} placeholder="Price" onChange={setPrice} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Coverage Size" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={coverage_size}
              placeholder="Coverage Size"
              onChange={setCoverageSize}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Unit" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={unit} placeholder="Unit" onChange={setUnit} />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />

        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          <TapButton
            title="Cancel"
            onTap={() => setIsAddFlash(false)}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            title={"Create"}
            bgColor={AppCSS.GLAS_BLUE}
            onTap={() => onTapCreateFlashing()}
            width={120}
            height={40}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  const EditFlashing = () => {
    return (
      <ColDiv
        style={{
          marginTop: 20,
          background: "#fff",
          boxShadow: "1px 1px 5px 1px #DBDBDB",
          borderRadius: 5,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 20,
          marginBottom: "5%",
        }}>
        <MaterialEditText text={"Edit Flashing"} />
        {/* Selected Action row */}
        <Spacer direction="col" />
        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}>
          <ColDiv>
            <Lbl title="Brand" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={brand} placeholder="Brand" onChange={setBrand} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Product" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={product}
              placeholder="Product"
              onChange={setProduct}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}>
          <ColDiv>
            <Lbl title="Description" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={description}
              placeholder="Description"
              onChange={setDescription}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv>
          <ColDiv>
            <Lbl title="Price" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={price} placeholder="Price" onChange={setPrice} />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Coverage Size" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput
              value={coverage_size}
              placeholder="Coverage Size"
              onChange={setCoverageSize}
            />
          </ColDiv>
          <Spacer size={2} direction="row" />
          <ColDiv>
            <Lbl title="Unit" color={AppCSS.GRAY_DARK} size={13} />
            <TxtInput value={unit} placeholder="Unit" onChange={setUnit} />
          </ColDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />

        <RowDiv
          style={{
            width: "80%",
            maxWidth: "900px",
            display: "row",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          <TapButton
            title="Cancel"
            onTap={() => {
              setIsAddFlash(false);
              setEditFlashing(false);
            }}
            width={120}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            height={40}
          />
          <TapButton
            title={"Save"}
            bgColor={AppCSS.GLAS_BLUE}
            onTap={() => onTapSave()}
            width={120}
            height={40}
          />

          <Spacer size={5} direction="row" />
        </RowDiv>
      </ColDiv>
    );
  };

  const filterMaterialsFlash = () => {
    if (keyword.length > 0 && Array.isArray(materials)) {
      return materials.filter((item) => {
        return item.product.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return materials;
  };

  const viewImage = (imageName?: string) => {
    return imageName ? imageName : PlaceHolder;
  };

  const MaterialsFlashTable = () => {
    return (
      <div style={{ width: "100%" }}>
        <RowDiv
          style={{
            marginTop: 20,
            justifyContent: "flex-start",
            width: "100%",
          }}>
          <TxtInput
            width={280}
            placeholder={`Search flashing`}
            onChange={setKeyword}
          />
          <RowDiv style={{ justifyContent: "flex-end" }}>
            <TapButton
              title={"Upload"}
              bgColor={AppCSS.WHITE}
              borderColor={AppCSS.GLAS_BLUE}
              color={AppCSS.GLAS_BLUE}
              onTap={() => {
                setIsAddFlash(false);
                onUploadFlashing();
              }}
              width={80}
              height={40}
            />
            <TapButton
              title={"Add Flashing"}
              bgColor={AppCSS.GLAS_BLUE}
              onTap={() => {
                resetForm();
                setIsAddFlash(true);
              }}
              width={120}
              height={40}
            />
          </RowDiv>
        </RowDiv>
        <Spacer size={1} direction="col" />
        <ColDiv
          style={{
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            marginBottom: "1%",
            gap: "10px",
          }}>
          <MaterialFlashTableHeaderCard
            headers={MATERIALS_FLASH_TABLE_HEADER}
          />

          {filterMaterialsFlash().length === 0 ? (
            <EmptyTable message="No flashing material available" />
          ) : (
            filterMaterialsFlash().map((row, index) => {
              const key = `key-Material-Flash-${index}`;
              return (
                <MaterialFlashTableBodyCard
                  key={key}
                  bodyContent={[
                    { text: row.product, width: "20%" },
                    {
                      text: (
                        <p
                          style={{
                            color: AppCSS.BLACK,
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            fontSize: "0.9rem",
                            margin: 0,
                            padding: 10,
                          }}>
                          {row.description}
                        </p>
                      ),
                      width: "30%",
                    },
                    {
                      text: (
                        <NumericFormat
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          value={row.price}
                          prefix="$"
                          thousandSeparator
                        />
                      ),
                      width: "10%",
                    },
                    {
                      text: row.coverage_size,
                      width: "20%",
                    },
                    { text: row.unit, width: "10%" },
                    {
                      text: (
                        <IconButton
                          onClick={() => {
                            setPrice(row.price);
                            setCoverageSize(`${row.coverage_size}`);
                            setUnit(row.unit);
                            setProduct(row.product);
                            setDescription(row.description);
                            setCurrentFlashing(row);
                            setEditFlashing(true);
                          }}
                          aria-label=""
                          style={{
                            display: "flex",
                            width: 20,
                            height: 20,
                            border: 1,
                            borderColor: AppCSS.GRAY_MILD,
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <EditIcon style={{ color: AppCSS.GLAS_GREEN }} />
                        </IconButton>
                      ),
                      width: "10%",
                      justifyContent: "center",
                    },
                  ]}
                  containerHeight="40px"
                />
              );
            })
          )}
        </ColDiv>
      </div>
    );
  };

  const materialsContainer = () => {
    return (
      <Container>
        <ColDiv
          style={{
            display: "row",
            maxWidth: "1100px",
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          {Array.isArray(materials) && MaterialsFlashTable()}
        </ColDiv>
      </Container>
    );
  };

  if (isAddFlash) {
    return AddFlashing();
  } else if (editFlashing) {
    return EditFlashing();
  } else {
    return materialsContainer();
  }
};
