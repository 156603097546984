/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../redux/hooks";
import { ProjectTable } from "./ProjectTable";
import { setProjects } from "../../../redux/reducers";
import { Container } from "../../../globalstyled";
import AppLoader from "../../../components/loader";
import { useFetchProjects } from "../../../apolloClient/hooks/general/fetchHook";

interface DashboardProps {}

export const Projects: React.FC<DashboardProps> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const userReducer = useAppSelector((state) => state.userReducer);
  const { projects } = userReducer;

  const {
    loading,
    error,
    projects: projectsDetails,
    refetch,
  } = useFetchProjects();

  useEffect(() => {
    onFetchProjects();
  }, [loading, error, projectsDetails, dispatch]);

  const onFetchProjects = () => {
    setIsLoading(false);
    if (!loading && !error && projectsDetails) {
      dispatch(setProjects(projectsDetails));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching projects:", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <ProjectTable projects={projectsDetails} />
      </Container>
    );
  };

  return hostContainer();
};
