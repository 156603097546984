/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { AppCSS, Spacer, TxtInput, ChkInputBox } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import * as React from "react";
import { useDispatch } from "react-redux";
import { Container } from "../../../../globalstyled";

import { Box, Typography, TextField, Autocomplete } from "@mui/material";

import plusIcon from "../../../../images/PlusIcon.png";
import deleteIcon from "../../../../images/DeleteIcon.png";

import LeadsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import { NumericFormat } from "react-number-format";
import MaterialItemsDrawer from "../SideDrawer/index";
import {
  ArrowIcon,
  FormTitleTypography,
  StandardIcon,
} from "../itemCreation.styled";
import UpArrowIcon from "../../../../images/UploadIcon.png";
import DownArrowIcon from "../../../../images/DownloadIcon.png";

import LeadsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import PicturesTableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";

import {
  MATERIAL_ITEMS_TABLE_HEADER_LIST,
  MATERIAL_ITEMS_PICTURES_TABLE_ROW_HEADER_LIST,
} from "../../../../contants/dashboardHeader";

import {
  MaterialItems,
  MaterialMeasurements,
} from "../../../../redux/types/creation";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SFileUpload } from "../../../../components/input/input.styled";
import { FileOpen } from "@mui/icons-material";
import TableViewDetails from "../../../../components/table/TableViewDetails";
import CancelAndSubmitButtons from "../ItemCreationComponent/SubmitAndCancelButton";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import SearchAndCreateComponent from "../ItemCreationComponent/SearchAndCreateComponent";
import Pagination from "../ItemCreationComponent/Pagination";
import MaterialItemsProductSystemTableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";
import ViewRowDetailsDrawer from "../ItemCreationComponent/ViewFieldDetails";
import {
  CHECK_MATERIAL_QUALITY,
  MATERIAL_ITEMS_DRAWER_DETAILS,
} from "../../../../contants/itemsCreation";
import EmptyTable from "../../../../components/table/EmptyTable";
import { useCreateMaterial } from "../../../../apolloClient/hooks/itemCreation/createHooks";
import {
  CreateMaterialInput,
  CreateUploadFileInput,
} from "../../../../types/graphQlQuery.interface";
import { MaterialItemFieldInput } from "../../../../types/ItemsCreation";
import axios from "axios";
import { useFetchImageUrl } from "../../../../apolloClient/hooks/general/fetchHook";
import { uploadImage } from "../../../../redux/api/common";
import { useCreateUploadUrl } from "../../../../apolloClient/hooks/general/createHook";

interface MaterialMeasurementsTableProps {
  materialItemsData: MaterialItems[];
  refetchMaterials: Function;
  materialMeasurementIds: MaterialMeasurements[];
}

const photoSampleData: { photo: string }[] = [
  { photo: "First photo" },
  { photo: "Second photo" },
  { photo: "Third photo" },
];

export const MaterialItemsTable: React.FC<MaterialMeasurementsTableProps> = ({
  materialItemsData,
  materialMeasurementIds,
  refetchMaterials,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openRowDetailsDrawer, setOpenRowDetailsDrawer] =
    React.useState<boolean>(false);

  const [viewRowDetailsData, setViewRowDetailsData] =
    useState<MaterialItems | null>(null);

  const [materialItem, setMaterialItem] = useState<MaterialItemFieldInput>({
    materialMeasurementParentId: 0,
    name: "",
    brand: "",
    description: "",
    quality: null,
    price: null,
    measurementUnit: "",
    coverageAmount: null,
    forceCustomerSelection: false,
    pictures: [],
  });

  const fileRef = useRef<HTMLInputElement>(null);
  const [uploadUrl, setUploadUrl] = useState<string>("");
  const [showMaterialQuality, setShowMaterialQuality] = useState(false);
  const [openPhotoFormDrawer, setOpenPhotoFormDrawer] = useState(false);

  const [photosData, setPhotosData] = useState<
    { fileName: string; fileUrl: string }[]
  >([]);

  const [selectedQualityId, setSelectedQualityId] = useState<number | null>(
    null
  );
  const [photoUploaded, setPhotoUploaded] = useState(false);

  const [fileUrl, setFileUrl] = useState<string>("");

  const { createMaterial } = useCreateMaterial();

  const { createUploadUrl } = useCreateUploadUrl();

  const getUplaodUrl = async () => {
    const input: CreateUploadFileInput = {
      contentType: "image/jpeg",
    };

    const success = await createUploadUrl(input);
    if (success) {
      setPhotoUploaded(true);
      setUploadUrl(success.url);
      setFileUrl(success.fileName);
      return;
    } else {
      console.error("Failed to create Upload url.");
    }
  };

  const handleSubmitImage = async () => {
    const file = fileRef.current?.files[0];
    const fileName = file?.name;
    if (!file) {
      toast("An image have not been selected", {
        type: "error",
        position: "top-left",
        className: "toast-message",
      });
      return;
    }

    try {
      const result = await uploadImage(uploadUrl, file);
      if (result.success) {
        setPhotosData((prevData) => [
          ...prevData,
          { fileName: fileName, fileUrl: fileUrl },
        ]);
        setOpenPhotoFormDrawer(false);
        setUploadUrl("");
      }
    } catch (error) {
      console.error("Error uploading image ", error);
    }
  };

  const onTapSubmitPhotoForm = async () => {
    await handleSubmitImage();
  };

  const getQualityValue = (id: number): string => {
    return (
      CHECK_MATERIAL_QUALITY.find((quality) => quality.id === id)?.name || ""
    );
  };

  const handleCheckboxChange = (id: number) => {
    setSelectedQualityId(id === selectedQualityId ? null : id);
    setShowMaterialQuality(true);
    const qualityValue = getQualityValue(id);
    setMaterialItem((prevItem) => ({
      ...prevItem,
      quality: qualityValue,
    }));
  };

  const viewRowDetails = (rowData: MaterialItems) => {
    setViewRowDetailsData(rowData);
    handleViewRowDetailsDrawerOpen();
  };

  const handleViewRowDetailsDrawerOpen = () => {
    setOpenRowDetailsDrawer(true);
  };

  const handleViewRowDetailsDrawerClose = () => {
    setOpenRowDetailsDrawer(false);
  };

  const handleChange = (value: string, name: string) => {
    setMaterialItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterMaterialItems = () => {
    if (keyword.length > 0 && Array.isArray(materialItemsData)) {
      console.log(materialItemsData);
      return materialItemsData.filter((item) => {
        const materialItemsName = `${item.name}`;
        return materialItemsName.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    console.log(materialItemsData);
    return materialItemsData;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterMaterialItems().length / rowsPerPage) - 1
      )
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleSelectMaterialMeasurementParentId = (event, selectedFilm) => {
    if (selectedFilm) {
      setMaterialItem((prevItem) => ({
        ...prevItem,
        materialMeasurementParentId: selectedFilm.id,
      }));
    }
  };

  const getMaterialMeasurementParentIdsOptions = (
    sampleDataArray: any[]
  ): { id: number; label: string }[] => {
    return sampleDataArray.map((item) => ({ id: item.id, label: item.name }));
  };

  const handleSetPrice = (value) => {
    if (value) {
      setMaterialItem((prevItem) => ({
        ...prevItem,
        price: value,
      }));
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handlePhotoDrawerClose = () => {
    setOpenPhotoFormDrawer(false);
  };

  const handleDeleteDocument = (
    photoToDelete: string,
    rowIndex: number
  ): void => {
    setPhotosData((prevData) =>
      prevData.filter(
        (item, index) => index !== rowIndex && item.fileName !== photoToDelete
      )
    );
  };

  const onTapSubmitForm = async () => {
    const validationRules = [
      {
        condition: !materialItem.name,
        message: "Please Enter the material name",
      },
      {
        condition: !materialItem.description,
        message: "Please Enter the material description",
      },
      {
        condition: !materialItem.price,
        message: "Please Enter the material price",
      },
      {
        condition: !materialItem.measurementUnit,
        message: "Please Enter the material measurement unit",
      },
      {
        condition: !materialItem.coverageAmount,
        message: "Please Enter the material coverage amount",
      },
      {
        condition: !materialItem.brand,
        message: "Please Enter the material brand",
      },
      {
        condition: !materialItem.materialMeasurementParentId,
        message: "Please Select the material measurement parent id",
      },
      {
        condition: photosData.length === 0,
        message: "Please Enter the material photo",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    const input: CreateMaterialInput = {
      name: materialItem.name,
      measurementId: materialItem.materialMeasurementParentId,
      brand: materialItem.brand,
      description: materialItem.description,
      price: materialItem.price,
      quality: materialItem.quality,
      measurementUnit: materialItem.measurementUnit,
      coverageAmount: materialItem.coverageAmount,
      images: photosData.map((item) => item.fileUrl),
    };

    console.log("input!!!!", input);

    const success = await createMaterial(input);
    if (success) {
      toast("Material items created successfully.", {
        type: "success",
        className: "toast-message",
        position: "top-left",
      });
      handleDrawerClose();
      setMaterialItem({
        materialMeasurementParentId: 0,
        name: "",
        brand: "",
        description: "",
        quality: null,
        price: null,
        measurementUnit: "",
        coverageAmount: null,
        forceCustomerSelection: false,
        pictures: [],
      });
      setPhotosData([]);
      setSelectedQualityId(null);
      setShowMaterialQuality(false);
      refetchMaterials();
      return;
    } else {
      console.error("Failed to create Material Items.");
    }
  };

  const createPhotoFormDrawer = (PhotoData = null) => {
    return (
      <MaterialItemsDrawer
        open={openPhotoFormDrawer}
        onClose={() => setOpenPhotoFormDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            height: "80%",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={() => setOpenPhotoFormDrawer(false)}
            labelTitle="Upload photo of material"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "97%",
                marginRight: "3%",
                justifyContent: "space-between",
              }}
            >
              <ColDiv
                style={{
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    marginX: "2%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",

                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "white",
                    }}
                  >
                    <Lbl
                      title={
                        photoUploaded
                          ? "Click save to complete"
                          : "Upload Photo"
                      }
                      size={16}
                      backgroundColor="white"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SFileUpload
                      style={{
                        border: "none",
                        marginLeft: 10,
                        height: "35px",
                        width: "100px",
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        if (fileRef.current) {
                          fileRef.current.click();
                        }
                      }}
                    >
                      <input
                        type="file"
                        id="file"
                        ref={fileRef}
                        onChange={() => {
                          if (uploadUrl === "") {
                            getUplaodUrl();
                          }
                        }}
                        accept={"image/png, image/jpeg, image/jpg"}
                        style={{
                          display: "none",
                        }}
                      />
                      <FileOpen
                        titleAccess="Select File"
                        color="warning"
                        style={{
                          width: 32,
                          height: 32,
                          color: AppCSS.GLAS_GREEN,
                        }}
                      />
                    </SFileUpload>
                  </Box>
                </Box>
                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handlePhotoDrawerClose}
          onCreate={onTapSubmitPhotoForm}
          submitText="Save photo"
          isDisabled={uploadUrl === "" ? true : false}
          bgColor={uploadUrl === "" ? AppCSS.GRAY_MILD : AppCSS.GLAS_GREEN}
        />
      </MaterialItemsDrawer>
    );
  };

  const createMaterialItemsForm = () => {
    return (
      <MaterialItemsDrawer
        open={openDrawer}
        onClose={() => handleDrawerClose()}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={handleDrawerClose}
            labelTitle="Create new material"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "97%",
                marginRight: "3%",
                justifyContent: "space-between",
              }}
            >
              <ColDiv
                style={{
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <FormTitleTypography>Name</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter material name"
                  onChange={handleChange}
                  required={true}
                  value={materialItem.name}
                  name="name"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <FormTitleTypography
                  style={{
                    display: "felx",
                    width: "60%",
                    justifyContent: "start",
                    textAlign: "start",
                  }}
                >
                  Select associated material measurement
                </FormTitleTypography>
                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    background: "inherit",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "end",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={getMaterialMeasurementParentIdsOptions(
                        materialMeasurementIds
                      )}
                      sx={{ width: "100%" }}
                      onChange={handleSelectMaterialMeasurementParentId}
                      renderInput={(params) => (
                        <TextField {...params} label="Search measurement" />
                      )}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <FormTitleTypography>Brand</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter brand's name"
                  onChange={handleChange}
                  required={true}
                  value={materialItem.brand}
                  name="brand"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <FormTitleTypography>Description</FormTitleTypography>
                </Box>
                <TxtInput
                  placeholder="Enter a description"
                  onChange={handleChange}
                  required={true}
                  value={materialItem.description}
                  name="description"
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  lines={4}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "start",
                    marginTop: "5px",
                    width: "98%",
                    marginRight: "2%",
                  }}
                >
                  <RowDiv
                    style={{
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                        alignItems: "start",
                        justifyContent: "start",
                        gap: "10px",
                      }}
                    >
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <FormTitleTypography>Price</FormTitleTypography>
                      </Box>
                      <TxtInput
                        type={"number"}
                        placeholder="Enter price"
                        onChange={(item) => handleSetPrice(item)}
                        required={true}
                        value={materialItem.price}
                        name="price"
                        background="inherit"
                        borderColor="rgba(173, 173, 173, 1)"
                        borderRadius={10}
                        height={50}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <FormTitleTypography>
                          Measurement Unit
                        </FormTitleTypography>
                      </Box>
                      <TxtInput
                        placeholder="Enter unit"
                        onChange={handleChange}
                        required={true}
                        value={materialItem.measurementUnit}
                        name="measurementUnit"
                        background="inherit"
                        borderColor="rgba(173, 173, 173, 1)"
                        borderRadius={10}
                        height={50}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                        alignItems: "start",
                        justifyContent: "start",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                          justifyContent: "start",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <FormTitleTypography>Quality</FormTitleTypography>
                      </Box>

                      <Box
                        sx={{
                          width: "96%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "inherit",
                          borderRadius: "10px",
                          border: "1px solid rgba(173, 173, 173, 1)",
                          height: "fit-content",
                          minHeight: "50px",
                          paddingY: "10px",
                        }}
                        onClick={() =>
                          setShowMaterialQuality(!showMaterialQuality)
                        }
                      >
                        <RowDiv
                          style={{
                            width: "100%",
                            paddingLeft: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Lbl
                              title="Select a Quality"
                              size={16}
                              mobileFontSize={16}
                              isFullWidth={true}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "40%",
                              alignItems: "center",
                              justifyContent: "end",
                              paddingRight: "20px",
                            }}
                          >
                            {showMaterialQuality ? (
                              <ArrowIcon src={UpArrowIcon} />
                            ) : (
                              <ArrowIcon src={DownArrowIcon} />
                            )}
                          </Box>
                        </RowDiv>

                        {showMaterialQuality && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              cursor: "pointer",
                              justifyContent: "center",
                              alignItems: "start",
                              width: "100%",
                              paddingX: "20px",
                            }}
                          >
                            {CHECK_MATERIAL_QUALITY.map((item) => (
                              <Box
                                key={`checkProductsSystemParent ${item.id}`}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  cursor: "pointer",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <ChkInputBox
                                  onChange={(value: boolean) => {
                                    handleCheckboxChange(item.id);
                                  }}
                                  placeholder=""
                                  width={50}
                                  marginTop={0}
                                  marginBottom={0}
                                  height={20}
                                  background="inherit"
                                  checked={item.id === selectedQualityId}
                                />
                                <Typography>{item.name}</Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          cursor: "pointer",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: "10px",
                          marginTop: "5px",
                        }}
                      >
                        <FormTitleTypography>
                          Coverage Amount
                        </FormTitleTypography>
                      </Box>
                      <TxtInput
                        placeholder="Enter amount"
                        onChange={handleChange}
                        required={true}
                        value={materialItem.coverageAmount}
                        name="coverageAmount"
                        background="inherit"
                        borderColor="rgba(173, 173, 173, 1)"
                        borderRadius={10}
                        height={50}
                      />
                    </Box>
                  </RowDiv>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <FormTitleTypography>Photo</FormTitleTypography>
                  <StandardIcon
                    src={plusIcon}
                    onClick={() => {
                      setOpenPhotoFormDrawer(true);
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingY: "10px",
                  }}
                >
                  {photosData.map((row, rowIndex) => (
                    <PicturesTableWithoutHeader
                      key={`Material items photo ${rowIndex}`}
                      bodyContent={[
                        {
                          text: (
                            <a
                              href={`${row.fileUrl}`}
                              target="_blank"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                fontSize: 15,
                                color: AppCSS.GLAS_BLUE,

                                borderRadius: "10px",
                                padding: "5px",
                              }}
                              rel="noreferrer"
                              download={false}
                            >
                              <p>View File</p>
                            </a>
                          ),
                          width: "20%",
                          fontSize: "15px",
                        },
                        {
                          text: (
                            <StandardIcon
                              src={deleteIcon}
                              onClick={() => {
                                handleDeleteDocument(row.fileName, rowIndex);
                              }}
                            />
                          ),
                          width: "10%",
                          fontSize: "15px",
                        },
                      ]}
                    />
                  ))}
                </Box>
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleDrawerClose}
          onCreate={onTapSubmitForm}
        />
      </MaterialItemsDrawer>
    );
  };

  const picturesTable = (value: any) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        {/* <LeadsTableHeaderCard
          headers={MATERIAL_ITEMS_PICTURES_TABLE_ROW_HEADER_LIST}
          fontSize="14px"
        /> */}

        {value.map((row, rowIndex) => (
          <MaterialItemsProductSystemTableWithoutHeader
            key={`material items pictures ${rowIndex}`}
            bodyContent={[
              {
                text: (
                  <a
                    href={`${row.url}`}
                    target="_blank"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "center",
                      fontSize: 15,
                      color: AppCSS.GLAS_BLUE,
                    }}
                    rel="noreferrer"
                    download={false}
                  >
                    <p>View image</p>
                  </a>
                ),
                width: "100%",
                fontSize: "14px",
                paddingLeft: "50px",
              },
            ]}
          />
        ))}
      </Box>
    );
  };

  const showMaterialItemsRowDetails = () => {
    return (
      <ViewRowDetailsDrawer
        openDrawer={openRowDetailsDrawer}
        handleDrawerClose={handleViewRowDetailsDrawerClose}
        rowDetailsData={viewRowDetailsData}
        labelTitle="Material Items Details"
        rowArrayTableComponent={picturesTable}
        detailsOfRowToBeDisplay={MATERIAL_ITEMS_DRAWER_DETAILS}
      />
    );
  };

  const MaterialItemsTable = () => {
    return (
      <Container>
        <ColDiv
          style={{
            width: "100%",
            marginBottom: "1%",
            gap: "10px",
            overflowX: "auto",
          }}
        >
          <ColDiv
            style={{
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "151px",
            }}
          >
            <SearchAndCreateComponent
              handleSearchIconClick={handleSearchIconClick}
              handleDrawerOpen={() => {
                handleDrawerOpen();
              }}
              setKeyword={setKeyword}
              inputRef={inputRef}
              searchPlaceHolder="Search materials"
            />
            <Box
              sx={{
                height: "calc(100% - 65px)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeadsTableHeaderCard
                headers={MATERIAL_ITEMS_TABLE_HEADER_LIST}
              />
            </Box>
          </ColDiv>
          {filterMaterialItems().length === 0 ? (
            <EmptyTable message="No material items available" />
          ) : (
            filterMaterialItems()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <LeadsTableBodyCard
                  key={`Material Items ${rowIndex}`}
                  containerWidth="100%"
                  bodyContent={[
                    {
                      text: `${row.id}`,
                      width: "10%",
                      fontSize: "15px",
                      paddingLeft: "30px",
                    },
                    {
                      text: `${row.name}`,
                      width: "20%",
                      fontSize: "15px",
                    },
                    {
                      text: `${row.brand}`,
                      width: "20%",
                      fontSize: "15px",
                    },
                    {
                      text: `${"$" + row.price}`,
                      width: "20%",
                      fontSize: "15px",
                    },

                    {
                      text: `${row.measurementUnit}`,
                      width: "25%",
                      fontSize: "15px",
                    },
                    {
                      text: "",
                      width: "15%",
                      justifyContent: "end",
                      isAction: true,
                      actionComponent: (
                        <TableViewDetails
                          rowData={row}
                          onViewClick={viewRowDetails}
                          text={"View"}
                        />
                      ),
                    },
                  ]}
                />
              ))
          )}
        </ColDiv>

        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          filterData={filterMaterialItems}
        />
      </Container>
    );
  };

  const materialItemsTableContainer = () => {
    return (
      <Container
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <ColDiv
          style={{
            justifyContent: "start",
          }}
        >
          {Array.isArray(materialItemsData) && MaterialItemsTable()}
        </ColDiv>
        {createMaterialItemsForm()}
        {showMaterialItemsRowDetails()}
        {createPhotoFormDrawer()}
      </Container>
    );
  };

  return materialItemsTableContainer();
};
