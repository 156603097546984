import { useMutation } from "@apollo/client";
import {
  CreateUploadFileInput,
  uploadUrlResponse,
} from "../../../types/graphQlQuery.interface";
import {
  ADD_PROJECT_MATERIAL_MEASUREMENT,
  APPROVE_SCOPE_MUTATION,
  CREATE_PROJECT_FILE,
  CREATE_UPLOAD_URL,
} from "../../operation/mutations";
import {
  ApproveScopeResponse,
  ScopeApprovalInput,
} from "../../../redux/types/InsuranceCompany";
import {
  AddProjectMaterialMeasurementResponse,
  ProjectMeasurementInput,
} from "../../../redux/types/projectDetails";
import { CreateProjectFileInput } from "../../../redux/types/Project";

export const useCreateUploadUrl = () => {
  const [createContractorReportsMutation] = useMutation<
    uploadUrlResponse,
    { input: CreateUploadFileInput }
  >(CREATE_UPLOAD_URL);

  const createUploadUrl = async (input: CreateUploadFileInput) => {
    try {
      const { data } = await createContractorReportsMutation({
        variables: { input },
      });

      if (!data) {
        throw new Error("Data is null");
      }
      return data.createUploadUrl;
    } catch (error) {
      console.error("Error Creating Upload url:", error);
      return false;
    }
  };

  return { createUploadUrl };
};

export const useApproveProjectScope = () => {
  const [ApproveScope] = useMutation<
    ApproveScopeResponse,
    { input: ScopeApprovalInput }
  >(APPROVE_SCOPE_MUTATION);
  const approveScope = async (input: ScopeApprovalInput): Promise<boolean> => {
    try {
      const { data } = await ApproveScope({
        variables: { input },
      });
      if (!data) {
        throw new Error("Data is null");
      }
      return data.approveScope;
    } catch (error) {
      console.error("Error approving scope:", error);
      return false;
    }
  };
  return {
    approveScope,
  };
};

export const useAddProjectMaterialMeasurement = () => {
  const [addProjectMeasurementMutation, { data, loading, error }] = useMutation<
    AddProjectMaterialMeasurementResponse,
    { input: ProjectMeasurementInput[] }
  >(ADD_PROJECT_MATERIAL_MEASUREMENT);

  const addProjectMaterialMeasurement = async (
    input: ProjectMeasurementInput[]
  ) => {
    return await addProjectMeasurementMutation({
      variables: { input },
    });
  };

  return {
    addProjectMaterialMeasurement,
    data,
    loading,
    error,
  };
};

export const useCreateProjectFile = () => {
  const [createProjectFileMutation] = useMutation<
    { createProjectFile: boolean },
    { input: CreateProjectFileInput }
  >(CREATE_PROJECT_FILE);

  const createProjectFile = async (
    input: CreateProjectFileInput
  ): Promise<boolean> => {
    try {
      const { data } = await createProjectFileMutation({
        variables: { input },
      });
      if (!data?.createProjectFile) {
        throw new Error(
          "Failed to create project file: Project file data is not available"
        );
      }
      return data.createProjectFile;
    } catch (error) {
      console.error("Error creating project file:", error);
      throw error;
    }
  };

  return { createProjectFile };
};
