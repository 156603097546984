import { CreateUploadFileInput } from "../types/graphQlQuery.interface";

export const getUploadFileDetails = async ({
  createUploadUrl,
  contentType,
}: {
  createUploadUrl: (
    input: CreateUploadFileInput
  ) => Promise<{ url: string; fileName: string } | false>;
  contentType: string;
}) => {
  const input: CreateUploadFileInput = {
    contentType: contentType,
  };
  const success = await createUploadUrl(input);
  if (!success) {
    throw new Error("Failed to create Upload url.");
  }
  return success;
};

export const validateFile = (
  file: File
): { valid: boolean; error?: string; type?: string } => {
  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

  const extension = file.name.split(".").pop()?.toLowerCase();
  if (!extension || !allowedExtensions.includes(extension)) {
    return { valid: false, error: "Invalid file extension" };
  }

  if (!allowedTypes.includes(file.type)) {
    return { valid: false, error: "Invalid file type" };
  }

  return { valid: true, type: file.type };
};
