import { useQuery } from "@apollo/client";
import {
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMERS,
  GET_MATERIALS_BY_CATEGORY,
  GET_PROJECT_BY_ID,
  GET_PROJECT_DETAILS,
  GET_PROJECTS,
  GET_UPLOAD_URL,
} from "../../operation/querys";
import {
  getUploadUrlResponse,
  ProjectResponse,
  ProjectsResponse,
} from "../../../types/graphQlQuery.interface";
import {
  GetCustomerByIDResponse,
  GetCustomerResponse,
} from "../../../redux/types/Project";
import { CustomerByIdResponse } from "../../../redux/types/customers";
import {
  ProjectDetailsQueryData,
  ProjectDetailsQueryVars,
} from "../../../redux/types/projectDetails";
import {
  MaterialCategoryQueryVars,
  MaterialResponse,
} from "../../../redux/types/Material";

interface queryInput {
  isSkip?: boolean;
  limit?: number;
  offset?: number;
}

export const useFetchImageUrl = ({ isSkip }: queryInput) => {
  const { loading, error, data, refetch } = useQuery<getUploadUrlResponse>(
    GET_UPLOAD_URL,
    {
      skip: isSkip,
    }
  );

  return {
    loading,
    error,
    uploadUrl: data?.getUploadUrl?.url,
    refetch,
  };
};

export const useFetchCustomers = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetCustomerResponse,
    queryInput
  >(GET_CUSTOMERS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    customersData: data?.customers,
    refetch,
  };
};

export const useFetchCustomerByID = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetCustomerByIDResponse,
    queryInput
  >(GET_CUSTOMERS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    customerData: data?.customer,
    refetch,
  };
};

export const useFetchProjects = (limit: number = 100, offset: number = 0) => {
  const { loading, error, data, refetch } = useQuery<ProjectsResponse>(
    GET_PROJECTS,
    {
      variables: { limit, offset },
    }
  );

  return {
    loading,
    error,
    projects: data?.projects,
    refetch,
  };
};

export const useProjectById = (id: number | string) => {
  const { loading, error, data, refetch } = useQuery<
    ProjectResponse,
    { id: number }
  >(GET_PROJECT_BY_ID, {
    variables: { id: Number(id) },
  });
  return {
    loading,
    error,
    project: data?.project,
    refetch,
  };
};

export const useCustomerById = ({
  id,
  isSkip = false,
}: {
  id: number;
  isSkip?: boolean;
}) => {
  const { data, loading, error, refetch } = useQuery<
    CustomerByIdResponse,
    { id: number }
  >(GET_CUSTOMER_BY_ID, {
    variables: { id },
    skip: isSkip,
  });

  return {
    customer: data?.customer,
    loading,
    error,
    refetch,
  };
};

export const useFetchProjectDetails = ({
  projectId,
}: {
  projectId: number;
  isSkip?: boolean;
}) => {
  const { data, loading, error, refetch } = useQuery<
    ProjectDetailsQueryData,
    ProjectDetailsQueryVars
  >(GET_PROJECT_DETAILS, { variables: { projectId } });

  return {
    projectDetails: data?.projectDetails,
    loading,
    error,
    refetch,
  };
};

export const useMaterialsByCategory = (categoryId: number) => {
  const { data, loading, error } = useQuery<
    MaterialResponse,
    MaterialCategoryQueryVars
  >(GET_MATERIALS_BY_CATEGORY, {
    variables: { id: categoryId },
  });

  return {
    materials: data?.materialsByCategory,
    loading,
    error,
  };
};
