import React from "react";
import { RowDiv } from "../../../../components/misc/misc.styled";
import { PreviousIcon } from "../../dashboard.styled";

import previousIcon from "../../../../images/previousIcon.png";
import nextIcon from "../../../../images/nextIcon.png";

interface PaginationProps {
  page: number;
  rowsPerPage: number;
  goToPreviousPage: () => void;
  goToNextPage: () => void;
  filterData: () => any[];
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  rowsPerPage,
  goToPreviousPage,
  goToNextPage,
  filterData,
}) => {
  const totalItems = filterData().length / rowsPerPage;
  return (
    <RowDiv
      style={{
        justifyContent: "end",
        marginTop: "20px",
        marginBottom: "5%",
        alignItems: "center",
        marginRight: "0%",
      }}
    >
      <PreviousIcon src={previousIcon} onClick={() => goToPreviousPage()} />
      <span style={{ margin: "0 15px" }}>
        Page {page + 1} of {Math.ceil(totalItems)}
      </span>
      <PreviousIcon src={nextIcon} onClick={() => goToNextPage()} />
    </RowDiv>
  );
};

export default Pagination;
