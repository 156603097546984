import React from "react";
import { Typography, Box } from "@mui/material";
import { AppCSS } from "../../../components";

const DisplayLeadDetailsCard = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingX: "10px",
        gap: "5px",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          width: "45%",
          paddingRight: "10px",
          fontSize: "16px",
        }}>
        <Typography
          variant="body1"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            fontWeight: "bold",
          }}>
          {label}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "right",
            fontSize: "16px",
            alignSelf: "right",
            fontWeight: "bold",
          }}>
          {`  :`}
        </Typography>
      </Box>

      <Typography
        variant="body1"
        sx={{
          textAlign: "left",
          width: "65%",
          fontSize: "14px",
          paddingLeft: "5px",
        }}>
        {value}
      </Typography>
    </Box>
  );
};

export default DisplayLeadDetailsCard;
