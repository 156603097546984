import * as React from "react";
import { Spacer } from "../../../../components";
import { Container } from "../../../../globalstyled";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

interface MaterialTableProps {
  materialMeasurements: any[];
}

const truncateText = (text: string, length: number) => {
  if (!text) return ""; // Return empty string if no text
  return text.length > length ? text.substring(0, length) + "..." : text;
};

export const MaterialTable: React.FC<MaterialTableProps> = ({
  materialMeasurements,
}) => {
  const Table = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            width: "95%",
            marginBottom: "1%",
            gap: "10px",
            overflowX: "hidden",
          }}
        >
          {materialMeasurements?.map((measurementCategory, index) => {
            const selectedMaterials = measurementCategory?.measurements
              .flatMap(
                (measurement: any) => measurement?.associatedMaterials || []
              )
              .filter((material: any) => material?.isSelected);

            if (selectedMaterials.length === 0) {
              return null;
            }

            return (
              <div key={index}>
                <h2 className="underline underline-offset-2 mb-3 font-bold text-center text-xl">
                  {measurementCategory?.name}
                </h2>
                <table
                  className="table-auto"
                  style={{ width: "100%", borderCollapse: "collapse" }}
                >
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="font-semibold py-2 text-gray-700">
                        Brand
                      </th>
                      <th className="font-semibold py-2 text-gray-700">
                        Description
                      </th>
                      <th className="font-semibold py-2 text-gray-700">
                        Price
                      </th>
                      <th className="font-semibold py-2 text-gray-700">
                        Quality
                      </th>
                      <th className="font-semibold py-2 text-gray-700">
                        Needed Quantity
                      </th>
                      <th className="font-semibold py-2 text-gray-700">
                        Coverage
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-50">
                    {selectedMaterials.map((material, idx) => (
                      <tr key={idx} className="border-t hover:bg-gray-100">
                        <td className="text-center py-2 text-gray-800">
                          {material?.brand || "N/A"}
                        </td>
                        <td className="text-center py-2 text-gray-800">
                          {truncateText(material?.description, 30)}
                        </td>
                        <td className="text-center py-2 text-gray-800">
                          ${material?.price}
                        </td>
                        <td className="text-center py-2 text-gray-800">
                          {material?.quality}
                        </td>
                        <td className="text-center py-2 text-gray-800">
                          {measurementCategory?.measurements?.find(
                            (measurement: any) =>
                              measurement.id === material.measurementId
                          )?.value?.quantity || "N/A"}
                        </td>
                        <td className="text-center py-2 text-gray-800">
                          {material?.coverageAmount} {material?.measurementUnit}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Spacer size={2} direction="col" />
              </div>
            );
          })}
        </ColDiv>
      </Container>
    );
  };

  return Table();
};
