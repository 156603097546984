export * from "./input";
export * from "./buttons";
export * from "./misc";
export * from "./nav/NavBar";
export * from "./Stepper/Stepper";
export * from "./labels/index";
export * from "./loader/Loader";
export * from "./misc/NotificationBadge";
export * from "./loader/index";

export const AppCSS = {
  WHITE: "#fff",
  RED: "#DC2342",
  GRAY: "#979797",
  RED_LIGHT: `rgba(220,35,66,0.2)`,
  GRAY_LIGHT: `#e4e4e4`,
  GRAY_MILD: `#FAF9F7`,
  GRAY_DARK: `#434343`,
  TEXTFIELD_BG: `#faf9f7`,
  BLACK: `#2E2C34`,
  BLUE: `#4B87ED`,
  ORANGE: `#F0D189`,
  BLUE_LIGHT: `rgba(85, 66, 246,0.3)`,
  GREEN: `#65D191`,
  WARNING: `#ffc107`,
  YELLOW_MILD: `#FFECBA`,
  DANGER: `#dc3545`,
  GREEN_MILD: `#BFEBAD`,
  RED_MILD: `#FFEAE9`,
  PARTY_COLOR: `#8DCFAA`,
  TABLE_BUSY: `#AFAFAF`,
  TABLE_EMPTY: `#61D08E`,
  TABLE_CAN_USE: `#F54A0A`,
  GLAS_BLUE: "#3E6990",
  GLAS_GREEN: "#A9CBB7",
  GLAS_YELLOW: "#FFBE0B",
  GRAY_LIGHTER: "#f3f3f3",
};
