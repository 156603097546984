/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";

import { ColDiv } from "../../../../components/misc/misc.styled";
import { FormTitleTypography } from "../itemCreation.styled";
import { Container } from "../../../../globalstyled";

import editIcon from "../../../../images/EditIcon.png";
import plusIcon from "../../../../images/PlusIcon.png";
import deleteIcon from "../../../../images/DeleteIcon.png";

import { AppCSS, Spacer, TxtInput, ChkInputBox } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import LeadsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import LeadsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import PaymentMethodWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";
import {
  PAYMENT_METHOD_TABLE_HEADER_LIST,
  PRODUCT_SYSTEM_TABLE_HEADER_LIST,
} from "../../../../contants/dashboardHeader";
import ProductDrawer from "../SideDrawer/index";
import TableViewDetails from "../../../../components/table/TableViewDetails";
import CancelAndSubmitButtons from "../ItemCreationComponent/SubmitAndCancelButton";
import { ProductSystem } from "../../../../redux/types/creation";
import { StandardIcon } from "../itemCreation.styled";
import { SelectInput } from "../../../../components/input/SelectInput";
import {
  PRIORITY_SELECT_INPUT_VALUE,
  PRODUCT_SYSTEM_DRAWER_DETAILS,
  PROJECT_DURATION_SELECT_INPUT_VALUE,
  PROJECTS_STEP,
} from "../../../../contants/itemsCreation";
import SearchAndCreateComponent from "../ItemCreationComponent/SearchAndCreateComponent";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import Pagination from "../ItemCreationComponent/Pagination";
import ViewRowDetailsDrawer from "../ItemCreationComponent/ViewFieldDetails";
import {
  CreateProductSystemInput,
  PaymentMethodInput,
} from "../../../../types/graphQlQuery.interface";
import { useCreateProductSystem } from "../../../../apolloClient/hooks/itemCreation/createHooks";
import EmptyTable from "../../../../components/table/EmptyTable";
import { FileOpen } from "@mui/icons-material";
import { SFileUpload } from "../../../../components/input/input.styled";
import MaterialItemsDrawer from "../SideDrawer/index";
import { uploadImage } from "../../../../redux/api/common";
import { useCreateUploadUrl } from "../../../../apolloClient/hooks/general/createHook";
import PicturesTableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";
import DisplayPhoto from "../ItemCreationComponent/DisplayPhoto";
import {
  getUploadFileDetails,
  validateFile,
} from "../../../../utils/uploadImage";

interface UserTableProps {
  productSystemData: ProductSystem[];
  refetchProduct?: Function;
}

export const ProductSystemsTable: React.FC<UserTableProps> = ({
  productSystemData,
  refetchProduct,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(8);
  const [keyword, setKeyword] = useState("");

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPaymentMethodDrawer, setOpenPaymentMethodDrawer] = useState(false);
  const [openPaymentMethodFormDrawer, setOpenPaymentMethodFormDrawer] =
    useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [productSystemName, setProductSystemName] = useState<string>("");
  const [paymentName, setPaymentName] = useState<string>("");
  const [percentageOfProjectTotal, setPercentageOfProjectTotal] = useState<
    number | null
  >(null);
  const [fixedAmount, setFixedAmount] = useState<number | null>(null);
  const [paymentMethodData, setPaymentMethodData] = useState<
    PaymentMethodInput[]
  >([]);
  const [viewRowDetailsData, setViewRowDetailsData] =
    useState<ProductSystem | null>(null);

  const [checkPercentage, setCheckPercentage] = useState<boolean>(false);
  const [checkFixedAmount, setCheckFixedAmount] = useState<boolean>(false);
  const [projectsStep, setProjectsStep] = useState<number | null>(
    Number(PROJECTS_STEP[0].value)
  );
  const [priority, setPriority] = useState<number | null>(null);

  const [projectDuration, setProjectDuration] = useState<string | null>(null);

  const [isEditPaymentDetails, setIsEditPaymentDetails] =
    useState<boolean>(false);
  const [editPaymentIndex, setEditPaymentIndex] = useState<number | null>(null);

  const [openPhotoFormDrawer, setOpenPhotoFormDrawer] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>("");

  const [photosDetails, setPhotosDetails] = useState<{
    fileName: string;
    fileUrl: string;
  }>({
    fileName: "",
    fileUrl: "",
  });
  const fileRef = useRef<HTMLInputElement>(null);
  const [uploadUrl, setUploadUrl] = useState<string>("");

  const { createProductSystem } = useCreateProductSystem();
  const { createUploadUrl } = useCreateUploadUrl();

  const handlePhotoDrawerClose = () => {
    setOpenPhotoFormDrawer(false);
  };

  const handleDeleteDocument = (): void => {
    setPhotosDetails({
      fileName: "",
      fileUrl: "",
    });
  };

  const getUploadUrl = async () => {
    try {
      const file = fileRef.current?.files[0];
      if (!file) {
        throw new Error("No file selected");
      }

      const validationResult = validateFile(file);
      if (!validationResult.valid) {
        throw new Error(validationResult.error);
      }

      const imageType = validationResult.type!;
      const result = await getUploadFileDetails({
        createUploadUrl,
        contentType: imageType,
      });
      setUploadUrl(result.url);
      setFileUrl(result.fileName);
    } catch (err) {
      console.error("Error getting upload url ", err);
      toast("Error getting upload url", {
        type: "error",
        position: "top-left",
        className: "toast-message",
      });
      setUploadUrl("");
      setFileUrl("");
    }
  };

  const handleSubmitImage = async () => {
    const file = fileRef.current?.files[0];
    const fileName = file?.name;
    if (!file) {
      toast("An image have not been selected", {
        type: "error",
        position: "top-left",
        className: "toast-message",
      });
      return;
    }

    try {
      const result = await uploadImage(uploadUrl, file);
      if (result.success) {
        setPhotosDetails({ fileName: fileName, fileUrl: fileUrl });
        setOpenPhotoFormDrawer(false);
        setUploadUrl("");
        setFileUrl("");
      }
    } catch (error) {
      console.error("Error uploading image ", error);
    }
  };

  const onTapSubmitPhotoForm = async () => {
    await handleSubmitImage();
  };

  const handleCreateProductSystem = async () => {
    const input: CreateProductSystemInput = {
      name: productSystemName,
      paymentMethods: paymentMethodData,
      thumbnail: photosDetails.fileUrl,
      duration: projectDuration,
      priority: priority,
    };

    const success = await createProductSystem(input);
    if (success) {
      toast("Product system created successfully.", { type: "success" });
      refetchProduct();
      handleDrawerClose();
      setProductSystemName("");
      setPaymentMethodData([]);
      setPhotosDetails({
        fileName: "",
        fileUrl: "",
      });
      setPriority(null);
      setProjectDuration(null);
      return;
    } else {
      console.log("Failed to create product system.");
    }
  };

  const onChangeProjectsStepSelectInput = async (step: any) => {
    setProjectsStep(Number(step));
  };

  const onChangeProductPrioritySelectInput = async (priorityValue: any) => {
    setPriority(Number(priorityValue));
  };

  const onChangeProjectDurationSelectInput = async (duration: any) => {
    setProjectDuration(duration);
  };

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterProductSystem = () => {
    if (keyword.length > 0 && Array.isArray(productSystemData)) {
      return productSystemData.filter((item) => {
        const productSystemName = `${item.name}`;
        const productSystemId = `${item.id}`;
        return (
          productSystemName.toLowerCase().includes(keyword.toLowerCase()) ||
          productSystemId.toLowerCase().includes(keyword.toLowerCase())
        );
      });
    }
    return productSystemData;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterProductSystem().length / rowsPerPage) - 1
      )
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handlePaymentMethodDrawerOpen = () => {
    setOpenPaymentMethodDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handlePaymentMethodDrawerClose = () => {
    setOpenPaymentMethodDrawer(false);
  };

  const onTapSubmitForm = async () => {
    const validationRules = [
      {
        condition: !productSystemName,
        message: "Please enter product system name!",
      },
      {
        condition: paymentMethodData.length === 0,
        message: "Please add a payment method!",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    await handleCreateProductSystem();
  };

  const handleAddPaymentMethod = async () => {
    const validationRules = [
      {
        condition: !paymentName,
        message: "Please enter payment name!",
      },
      {
        condition: !projectsStep,
        message: "Please enter project step!",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    const newPaymentMethod: PaymentMethodInput = {
      name: paymentName,
      paymentType: checkPercentage
        ? "PERCENTAGE_OF_PROJECT_TOTAL"
        : "FIXED_AMOUNT",
      amount: checkPercentage ? percentageOfProjectTotal : fixedAmount,
      stepIndex: projectsStep,
    };
    setPaymentMethodData((prevPaymentMethodData) => [
      // ...prevPaymentMethodData,
      newPaymentMethod,
    ]);
    setPaymentName("");
    setPercentageOfProjectTotal(null);
    setFixedAmount(null);
    setProjectsStep(null);
    setOpenPaymentMethodFormDrawer(false);
    toast("successfully add payment method ", { type: "success" });
  };

  const handleSavePaymentMethod = async () => {
    const validationRules = [
      {
        condition: !paymentName,
        message: "Please enter payment name!",
      },
      {
        condition: !projectsStep,
        message: "Please enter project step!",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    const updatedPaymentMethod: PaymentMethodInput = {
      name: paymentName,
      paymentType: checkPercentage
        ? "PERCENTAGE_OF_PROJECT_TOTAL"
        : "FIXED_AMOUNT",
      amount: checkPercentage ? percentageOfProjectTotal! : fixedAmount!,
      stepIndex: projectsStep!,
    };
    setPaymentMethodData((prevPaymentMethodData) =>
      prevPaymentMethodData.map((method, index) =>
        index === editPaymentIndex ? updatedPaymentMethod : method
      )
    );

    setPaymentName("");
    setPercentageOfProjectTotal(null);
    setFixedAmount(null);
    setProjectsStep(null);
    setOpenPaymentMethodFormDrawer(false);
    setIsEditPaymentDetails(false);
    setEditPaymentIndex(null);
  };

  const viewRowDetails = (rowData: ProductSystem) => {
    setViewRowDetailsData(rowData);
    handlePaymentMethodDrawerOpen();
  };

  const createPaymentMethod = () => {
    setCheckPercentage(false);
    setCheckFixedAmount(false);
    setFixedAmount(null);
    setPercentageOfProjectTotal(null);
    setPaymentName("");
    setProjectsStep(null);
    setOpenPaymentMethodFormDrawer(true);
  };

  const editPaymentMethod = (paymentMethod: PaymentMethodInput) => {
    const { name, stepIndex, amount, paymentType } = paymentMethod;
    setPaymentName(name || "");
    setProjectsStep(stepIndex || null);

    const isPercentage =
      paymentType === "PERCENTAGE_OF_PROJECT_TOTAL" ? true : false;

    setCheckPercentage(isPercentage);
    setCheckFixedAmount(!isPercentage);
    setPercentageOfProjectTotal(isPercentage ? amount : null);
    setFixedAmount(isPercentage ? null : amount);

    setOpenPaymentMethodFormDrawer(true);
    setIsEditPaymentDetails(true);
  };

  const createProductSystemForm = () => {
    return (
      <ProductDrawer open={openDrawer} onClose={() => handleDrawerClose()}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "30px",
            minHeight: "80%",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={handleDrawerClose}
            labelTitle="Create new product system "
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "97%",
                marginBottom: "20px",
                marginRight: "3%",
                justifyContent: "space-between",
              }}
            >
              <ColDiv
                style={{
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Name</FormTitleTypography>
                </Box>

                <TxtInput
                  placeholder="Product system name"
                  onChange={setProductSystemName}
                  required={true}
                  value={productSystemName}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Spacer size={0.5} direction="col" />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>
                    Estimated completion time
                  </FormTitleTypography>
                </Box>

                <TxtInput
                  placeholder="Enter estimated completion time"
                  onChange={onChangeProjectDurationSelectInput}
                  required={true}
                  value={projectDuration}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />

                <Spacer size={0.5} direction="col" />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Priority</FormTitleTypography>
                </Box>

                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "inherit",
                    borderRadius: "10px",
                    border: "1px solid rgba(173, 173, 173, 1)",
                    height: "50px",
                  }}
                >
                  <SelectInput
                    options={PRIORITY_SELECT_INPUT_VALUE}
                    placeholder="Select priority"
                    onHandleChange={onChangeProductPrioritySelectInput}
                    value={priority}
                    required={true}
                    backgroundColor="inherit"
                  />
                </Box>

                <Spacer size={0.5} direction="col" />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                    marginTop: "5px",
                  }}
                >
                  <FormTitleTypography>Payment Method</FormTitleTypography>
                  <StandardIcon
                    src={plusIcon}
                    onClick={() => {
                      createPaymentMethod();
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingY: "10px",
                  }}
                >
                  {paymentMethodData.length === 0 ? (
                    <Typography variant="body1">
                      No payment methods added.
                    </Typography>
                  ) : (
                    paymentMethodData.map((row, rowIndex) => (
                      <PaymentMethodWithoutHeader
                        key={`product system ${rowIndex}`}
                        bodyContent={[
                          {
                            text: `${rowIndex + 1}`,
                            width: "10%",
                            fontSize: "15px",
                          },
                          {
                            text: `${row.name}`,
                            width: "30%",
                            fontSize: "15px",
                          },
                          {
                            text:
                              row.paymentType ===
                              "PERCENTAGE_OF_PROJECT_TOTAL" ? (
                                `${row.amount}%`
                              ) : (
                                <NumericFormat
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  value={row.amount}
                                  prefix="$"
                                  thousandSeparator
                                />
                              ),
                            width: "25%",
                          },
                          {
                            text: `${row.stepIndex}`,
                            width: "15%",
                            fontSize: "15px",
                          },
                          {
                            text: "",
                            width: "20%",
                            justifyContent: "end",
                            isAction: true,
                            actionComponent: (
                              <>
                                <Box
                                  onClick={() => {
                                    setEditPaymentIndex(rowIndex);
                                    editPaymentMethod(row);
                                  }}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    paddingRight: "20px",
                                    cursor: "pointer",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <StandardIcon src={editIcon} />
                                </Box>
                              </>
                            ),
                          },
                        ]}
                      />
                    ))
                  )}
                </Box>

                <Spacer size={0.5} direction="col" />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <FormTitleTypography>Photo</FormTitleTypography>
                  <StandardIcon
                    src={plusIcon}
                    onClick={() => {
                      setOpenPhotoFormDrawer(true);
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    paddingY: "10px",
                  }}
                >
                  {photosDetails.fileUrl === "" ? (
                    <Typography variant="body1">
                      No photo of the product has been added.
                    </Typography>
                  ) : (
                    <PicturesTableWithoutHeader
                      key={`product system photo`}
                      bodyContent={[
                        {
                          text: (
                            <a
                              href={`${photosDetails.fileUrl}`}
                              target="_blank"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "center",
                                fontSize: 15,
                                color: AppCSS.GLAS_BLUE,
                                backgroundColor: AppCSS.WHITE,
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                              rel="noreferrer"
                              download={false}
                            >
                              <p>View File</p>
                            </a>
                          ),
                          width: "20%",
                          fontSize: "15px",
                        },
                        {
                          text: (
                            <StandardIcon
                              style={{ cursor: "pointer" }}
                              src={deleteIcon}
                              onClick={() => {
                                handleDeleteDocument();
                              }}
                            />
                          ),
                          width: "10%",
                          fontSize: "15px",
                        },
                      ]}
                    />
                  )}
                </Box>
                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
          <CancelAndSubmitButtons
            onCancel={handleDrawerClose}
            onCreate={onTapSubmitForm}
          />
        </Box>
      </ProductDrawer>
    );
  };

  const createPaymentMethodFormDrawer = (paymentData = null) => {
    return (
      <ProductDrawer
        open={openPaymentMethodFormDrawer}
        onClose={() => setOpenPaymentMethodFormDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            height: "80%",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={() => setOpenPaymentMethodFormDrawer(false)}
            labelTitle={
              isEditPaymentDetails
                ? "Edit Payment Method"
                : "Create Product System Payment"
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "97%",
                marginBottom: "20px",
                marginRight: "3%",
                justifyContent: "space-between",
              }}
            >
              <ColDiv>
                <Lbl title="Name" size={16} width={80} bold={600} />
                <TxtInput
                  placeholder="Name  of payment"
                  onChange={setPaymentName}
                  required={true}
                  value={paymentName}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <Spacer size={1} direction="col" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Lbl title="Payment Type" size={16} width={80} bold={600} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <ChkInputBox
                      onChange={(value) => {
                        setCheckPercentage(value);
                        setCheckFixedAmount(!value);
                      }}
                      placeholder=""
                      width={50}
                      background="inherit"
                      checked={checkPercentage}
                    />

                    <Typography>Percentage of Project Total</Typography>
                  </Box>
                  <ColDiv
                    style={{
                      paddingLeft: "50px",
                      width: "50%",
                    }}
                  >
                    <TxtInput
                      type="number"
                      placeholder="Enter Percentage"
                      onChange={setPercentageOfProjectTotal}
                      value={percentageOfProjectTotal}
                      marginBottom={0}
                      marginTop={0}
                      background="inherit"
                      borderColor="rgba(173, 173, 173, 1)"
                      borderRadius={10}
                      disable={!checkPercentage}
                    />
                  </ColDiv>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "start",
                    marginTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <ChkInputBox
                      onChange={(value) => {
                        setCheckFixedAmount(value);
                        setCheckPercentage(!value);
                      }}
                      placeholder=""
                      width={50}
                      background="inherit"
                      marginBottom={0}
                      marginTop={0}
                      checked={checkFixedAmount}
                    />{" "}
                    <Typography>Fixed Amount</Typography>
                  </Box>

                  <ColDiv
                    style={{
                      paddingLeft: "50px",
                      width: "50%",
                    }}
                  >
                    <TxtInput
                      type="number"
                      placeholder="Enter Amount"
                      onChange={setFixedAmount}
                      value={fixedAmount}
                      marginBottom={0}
                      marginTop={0}
                      background="inherit"
                      borderColor="rgba(173, 173, 173, 1)"
                      borderRadius={10}
                      disable={!checkFixedAmount}
                    />
                  </ColDiv>
                </Box>

                <Spacer size={1} direction="col" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Lbl title="Project Step" size={16} width={80} bold={600} />
                </Box>
                <Spacer size={1} direction="col" />
                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "inherit",
                    borderRadius: "10px",
                    border: "1px solid rgba(173, 173, 173, 1)",
                    height: "50px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Lbl
                      title="Select the project step"
                      size={16}
                      mobileFontSize={16}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SelectInput
                      options={PROJECTS_STEP}
                      onHandleChange={onChangeProjectsStepSelectInput}
                      value={projectsStep}
                      required={true}
                      backgroundColor="inherit"
                    />
                  </Box>
                </Box>

                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={() => setOpenPaymentMethodFormDrawer(false)}
          onCreate={
            isEditPaymentDetails
              ? handleSavePaymentMethod
              : handleAddPaymentMethod
          }
          submitText={isEditPaymentDetails ? "Save" : "Create"}
        />
      </ProductDrawer>
    );
  };

  const ProductSystemPaymentTable = ({ value }: { value: any[] }) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          paddingY: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LeadsTableHeaderCard
          headers={PAYMENT_METHOD_TABLE_HEADER_LIST}
          fontSize="12px"
        />

        {value.map((row, rowIndex) => (
          <PaymentMethodWithoutHeader
            key={`product system ${rowIndex}`}
            bodyContent={[
              {
                text: `${rowIndex + 1}`,
                width: "5%",
                fontSize: "14px",
              },
              {
                text: `${row.name}`,
                width: "30%",
                fontSize: "14px",
              },
              {
                text:
                  row.paymentType === "PERCENTAGE_OF_PROJECT_TOTAL" ? (
                    `${row.percent}%`
                  ) : (
                    <NumericFormat
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      value={row.amount}
                      prefix="$"
                      thousandSeparator
                    />
                  ),
                width: "50%",
              },
              {
                text: `${row.stepNumber}`,
                width: "15%",
                fontSize: "14px",
              },
            ]}
          />
        ))}
      </Box>
    );
  };

  const showProductSystemDetails = () => {
    return (
      <ViewRowDetailsDrawer
        openDrawer={openPaymentMethodDrawer}
        handleDrawerClose={handlePaymentMethodDrawerClose}
        rowDetailsData={viewRowDetailsData}
        labelTitle="Product System details"
        rowArrayTableComponent={(value: any, key?: string) => (
          <DisplayPhoto value={value} key={key} />
        )}
        detailsOfRowToBeDisplay={PRODUCT_SYSTEM_DRAWER_DETAILS}
        rowArrayTableComponentTwo={(value: any[], key?: string) => (
          <ProductSystemPaymentTable value={value} key={key} />
        )}
      />
    );
  };

  const createPhotoFormDrawer = (PhotoData = null) => {
    return (
      <MaterialItemsDrawer
        open={openPhotoFormDrawer}
        onClose={() => setOpenPhotoFormDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            height: "80%",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={() => setOpenPhotoFormDrawer(false)}
            labelTitle="Upload product system photo"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "97%",
                marginRight: "3%",
                justifyContent: "space-between",
              }}
            >
              <ColDiv
                style={{
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    width: "96%",
                    marginX: "2%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    background: AppCSS.GRAY_MILD,
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Lbl title="Upload Photo" size={16} />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "40%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SFileUpload
                      style={{
                        border: uploadUrl === "" ? `3px solid #A9CBB7` : "none",
                        marginLeft: 10,
                        height: "35px",
                        width: "100px",
                      }}
                      onClick={() => {
                        if (fileRef.current) {
                          fileRef.current.click();
                        }
                      }}
                    >
                      <input
                        type="file"
                        id="file"
                        ref={fileRef}
                        onChange={() => {
                          if (uploadUrl === "") {
                            getUploadUrl();
                          }
                        }}
                        accept={"image/png, image/jpeg, image/jpg"}
                        style={{
                          display: "none",
                        }}
                      />
                      <FileOpen
                        titleAccess="Select File"
                        color="warning"
                        style={{
                          width: 32,
                          height: 32,
                          color: AppCSS.GLAS_GREEN,
                        }}
                      />
                    </SFileUpload>
                  </Box>
                </Box>
                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handlePhotoDrawerClose}
          onCreate={onTapSubmitPhotoForm}
          submitText="Add photo"
          isDisabled={uploadUrl === "" ? true : false}
          bgColor={uploadUrl === "" ? AppCSS.GRAY_MILD : AppCSS.GLAS_GREEN}
        />
      </MaterialItemsDrawer>
    );
  };

  const ProductsSystemTable = () => {
    return (
      <Container>
        <ColDiv
          style={{
            width: "100%",
            gap: "10px",
          }}
        >
          <ColDiv
            style={{
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "151px",
            }}
          >
            <SearchAndCreateComponent
              handleSearchIconClick={handleSearchIconClick}
              handleDrawerOpen={handleDrawerOpen}
              setKeyword={setKeyword}
              inputRef={inputRef}
              searchPlaceHolder="Search product systems"
            />
            <Box
              sx={{
                height: "calc(100% - 65px)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeadsTableHeaderCard
                headers={PRODUCT_SYSTEM_TABLE_HEADER_LIST}
              />
            </Box>
          </ColDiv>

          {filterProductSystem().length === 0 ? (
            <EmptyTable message="No products available" />
          ) : (
            filterProductSystem()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <LeadsTableBodyCard
                  key={`product system ${rowIndex}`}
                  bodyContent={[
                    {
                      text: `${row.id}`,
                      width: "30%",
                      fontSize: "15px",
                      paddingLeft: "50px",
                    },
                    {
                      text: `${row.name}`,
                      width: "40%",
                      fontSize: "15px",
                      paddingLeft: "50px",
                    },
                    {
                      text: "",
                      width: "30%",
                      justifyContent: "end",
                      isAction: true,
                      actionComponent: (
                        <TableViewDetails
                          rowData={row}
                          onViewClick={viewRowDetails}
                          text={"View"}
                        />
                      ),
                    },
                  ]}
                />
              ))
          )}
        </ColDiv>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          filterData={filterProductSystem}
        />
      </Container>
    );
  };

  const ProductsSystemContainer = () => {
    return (
      <Container
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <ColDiv
          style={{
            justifyContent: "start",
          }}
        >
          {Array.isArray(productSystemData) && ProductsSystemTable()}
        </ColDiv>
        {createProductSystemForm()}
        {createPaymentMethodFormDrawer()}
        {showProductSystemDetails()}
        {createPhotoFormDrawer()}
      </Container>
    );
  };

  return ProductsSystemContainer();
};
