/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import { AppCSS, Spacer, TxtInput, ChkInputBox } from "../../../../components";
import { Lbl } from "../../../../components/labels";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";

import * as React from "react";
import { useDispatch } from "react-redux";
import { Container } from "../../../../globalstyled";

import { Box, Typography } from "@mui/material";
import UpArrowIcon from "../../../../images/UploadIcon.png";
import DownArrowIcon from "../../../../images/DownloadIcon.png";

import LeadsTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import LeadsTableBodyCard from "../../../../components/table/CustomTableBodyCard";
import { MISC_FIELDS_TABLE_HEADER_LIST } from "../../../../contants/dashboardHeader";

import MiscFieldsDrawer from "../SideDrawer/index";
import TableViewDetails from "../../../../components/table/TableViewDetails";
import { FormTitleTypography, ArrowIcon } from "../itemCreation.styled";

import CancelAndSubmitButtons from "../ItemCreationComponent/SubmitAndCancelButton";

import {
  MiscFields,
  ProductSystem,
  ProductSystemForMiscField,
} from "../../../../redux/types/creation";
import { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import SearchAndCreateComponent from "../ItemCreationComponent/SearchAndCreateComponent";
import SideDrawerHeader from "../SideDrawer/SideDrawerHeader";
import Pagination from "../ItemCreationComponent/Pagination";
import ViewRowDetailsDrawer from "../ItemCreationComponent/ViewFieldDetails";
import { MISC_FIELDS_DRAWER_DETAILS } from "../../../../contants/itemsCreation";
import { toast } from "react-toastify";
import { useCreateMiscField } from "../../../../apolloClient/hooks/itemCreation/createHooks";
import { CreateMiscFieldInput } from "../../../../types/graphQlQuery.interface";
import { extractCheckedIds } from "../../../../utils";
import EmptyTable from "../../../../components/table/EmptyTable";
import ProductSystemParentIdsTable from "../ItemCreationComponent/ProductSystemParentIdsTable";

interface MiscFieldsTableProps {
  miscFieldsData: MiscFields[];
  refetchMiscField?: Function;
  productsIds?: ProductSystem[];
}

export const MiscFieldsTable: React.FC<MiscFieldsTableProps> = ({
  miscFieldsData,
  refetchMiscField,
  productsIds,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openRowDetailsDrawer, setOpenRowDetailsDrawer] = React.useState(false);

  const [viewRowDetailsData, setViewRowDetailsData] =
    useState<MiscFields | null>(null);

  const [productSystemId, setProductSystemId] = useState<string>("");
  const [forCustomerDisplay, setForCustomerDisplay] = useState<boolean>(false);

  const [miscName, setMiscName] = useState<string>("");
  const [showProductSystemParent, setShowProductSystemParent] =
    useState<boolean>(false);

  const [checkboxStates, setCheckboxStates] = useState<{
    [key: number]: boolean;
  }>({});

  const { createMiscFields } = useCreateMiscField();

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterMiscFields = () => {
    if (keyword.length > 0 && Array.isArray(miscFieldsData)) {
      return miscFieldsData.filter((item) => {
        const miscFieldName = `${item.name}`;
        return miscFieldName.toLowerCase().includes(keyword.toLowerCase());
      });
    }
    return miscFieldsData;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterMiscFields().length / rowsPerPage) - 1
      )
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleViewRowDetailsDrawerOpen = () => {
    setOpenRowDetailsDrawer(true);
  };

  const handleViewRowDetailsDrawerClose = () => {
    setOpenRowDetailsDrawer(false);
  };

  const onTapSubmitForm = async () => {
    const validationRules = [
      {
        condition: !miscName,
        message: "Please enter misc field name!",
      },

      {
        condition: !extractCheckedIds(checkboxStates).length,
        message: "Please select a minimum of one product system!",
      },
    ];

    for (const rule of validationRules) {
      if (rule.condition) {
        toast(rule.message, {
          type: "error",
          position: "top-left",
          className: "toast-message",
        });
        return;
      }
    }

    const checkedParentIds = extractCheckedIds(checkboxStates);

    const input: CreateMiscFieldInput = {
      showToCustomer: forCustomerDisplay,
      name: miscName,
      parentIds: checkedParentIds,
    };

    const success = await createMiscFields(input);
    if (success) {
      toast("Misc field created successfully.", {
        type: "success",
        className: "toast-message",
        position: "top-left",
      });
      refetchMiscField();
      handleDrawerClose();
      setMiscName("");
      setForCustomerDisplay(false);
      setCheckboxStates({});
      return;
    } else {
      console.log("Failed to create Misc field.");
    }
  };

  const viewRowDetails = (rowData: MiscFields) => {
    setViewRowDetailsData(rowData);
    handleViewRowDetailsDrawerOpen();
  };

  const createMiscFieldsForm = () => {
    return (
      <MiscFieldsDrawer open={openDrawer} onClose={() => handleDrawerClose()}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <SideDrawerHeader
            handleDrawerClose={handleDrawerClose}
            labelTitle="Create new miscellaneous field"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <ColDiv
                style={{
                  gap: "5px",
                }}
              >
                <Spacer size={1} direction="col" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>Field name</FormTitleTypography>
                </Box>

                <TxtInput
                  placeholder="Enter name"
                  onChange={setMiscName}
                  required={true}
                  value={miscName}
                  background="inherit"
                  borderColor="rgba(173, 173, 173, 1)"
                  borderRadius={10}
                  height={50}
                />
                <Spacer size={1} direction="col" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Lbl
                    title="Associated product systems"
                    size={16}
                    width={80}
                    bold={600}
                  />
                </Box>
                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "inherit",
                    borderRadius: "10px",
                    border: "1px solid rgba(173, 173, 173, 1)",
                    height: "fit-content",
                    minHeight: "50px",
                    paddingY: "10px",
                  }}
                  onClick={() =>
                    setShowProductSystemParent(!showProductSystemParent)
                  }
                >
                  <RowDiv
                    style={{
                      width: "100%",
                      paddingLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Lbl
                        title="Select associated product systems"
                        size={16}
                        mobileFontSize={16}
                        isFullWidth={true}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "40%",
                        alignItems: "center",
                        justifyContent: "end",
                        paddingRight: "20px",
                      }}
                    >
                      {showProductSystemParent ? (
                        <ArrowIcon src={UpArrowIcon} />
                      ) : (
                        <ArrowIcon src={DownArrowIcon} />
                      )}
                    </Box>
                  </RowDiv>

                  {showProductSystemParent && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        justifyContent: "center",
                        alignItems: "start",
                        width: "100%",
                        paddingX: "20px",
                      }}
                    >
                      {productsIds.map((item) => (
                        <Box
                          key={`checkProductsSystemParent ${item.id}`}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            cursor: "pointer",
                            justifyContent: "start",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <ChkInputBox
                            onChange={(value: boolean) => {
                              setCheckboxStates((prevCheckboxStates) => ({
                                ...prevCheckboxStates,
                                [item.id]: value,
                              }));
                              setShowProductSystemParent(true);
                            }}
                            placeholder=""
                            width={50}
                            marginTop={0}
                            marginBottom={0}
                            height={20}
                            background="inherit"
                            checked={checkboxStates[item.id] || false}
                          />
                          <Typography>{item.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                <Spacer size={2} direction="col" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {" "}
                  <FormTitleTypography>
                    For customer display
                  </FormTitleTypography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    justifyContent: "start",
                    alignItems: "start",
                    marginTop: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <ChkInputBox
                      onChange={(value) => {
                        setForCustomerDisplay(value);
                      }}
                      placeholder=""
                      width={50}
                      background="inherit"
                      marginBottom={0}
                      marginTop={0}
                      checked={forCustomerDisplay}
                    />{" "}
                    <Typography>Display in customer project portal</Typography>
                  </Box>
                </Box>

                <Spacer size={1} direction="col" />
              </ColDiv>
            </Box>
          </Box>
        </Box>

        <CancelAndSubmitButtons
          onCancel={handleDrawerClose}
          onCreate={onTapSubmitForm}
        />
      </MiscFieldsDrawer>
    );
  };

  const showMiscFieldsRowDetails = () => {
    return (
      <ViewRowDetailsDrawer
        openDrawer={openRowDetailsDrawer}
        handleDrawerClose={handleViewRowDetailsDrawerClose}
        rowDetailsData={viewRowDetailsData}
        labelTitle="Miscellaneous Fields Details"
        rowArrayTableComponent={(
          value: ProductSystemForMiscField[],
          key?: string
        ) => <ProductSystemParentIdsTable value={value} key={key} />}
        detailsOfRowToBeDisplay={MISC_FIELDS_DRAWER_DETAILS}
      />
    );
  };

  const MiscFieldTable = () => {
    return (
      <Container>
        <ColDiv
          style={{
            width: "100%",
            gap: "10px",
          }}
        >
          <ColDiv
            style={{
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.1)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "151px",
            }}
          >
            <SearchAndCreateComponent
              handleSearchIconClick={handleSearchIconClick}
              handleDrawerOpen={() => {
                handleDrawerOpen();
              }}
              setKeyword={setKeyword}
              inputRef={inputRef}
              searchPlaceHolder="Search miscellaneous fields"
            />
            <Box
              sx={{
                height: "calc(100% - 65px)",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeadsTableHeaderCard headers={MISC_FIELDS_TABLE_HEADER_LIST} />
            </Box>
          </ColDiv>

          {filterMiscFields().length === 0 ? (
            <EmptyTable message="No Misc field available" />
          ) : (
            filterMiscFields()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <LeadsTableBodyCard
                  key={`Misc field ${rowIndex}`}
                  bodyContent={[
                    {
                      text: `${row.id}`,
                      width: "20%",
                      fontSize: "15px",
                      paddingLeft: "50px",
                    },
                    {
                      text: `${row.name}`,
                      width: "40%",
                      paddingLeft: "50px",
                    },
                    {
                      text: "",
                      width: "40%",
                      justifyContent: "end",
                      isAction: true,
                      actionComponent: (
                        <TableViewDetails
                          rowData={row}
                          onViewClick={viewRowDetails}
                          text={"View"}
                        />
                      ),
                    },
                  ]}
                />
              ))
          )}
        </ColDiv>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          filterData={filterMiscFields}
        />
      </Container>
    );
  };

  const MiscFieldContainer = () => {
    return (
      <Container
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <ColDiv
          style={{
            justifyContent: "start",
          }}
        >
          {Array.isArray(miscFieldsData) && MiscFieldTable()}
        </ColDiv>
        {createMiscFieldsForm()}
        {showMiscFieldsRowDetails()}
      </Container>
    );
  };

  return MiscFieldContainer();
};
