import React from "react";
import { Box } from "@mui/material";
import { ContractorFile } from "../../../../redux/types/creation";
import ProductTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import { FILE_UPLOAD_TABLE_ROW_HEADER_LIST } from "../../../../contants/dashboardHeader";
import ProductSystemTableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";
import { AppCSS } from "../../../../components";

interface FileUploadTableProps {
  value?: ContractorFile[];
  key?: string;
}

const FileUploadTable: React.FC<FileUploadTableProps> = ({ value, key }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <ProductTableHeaderCard
        headers={FILE_UPLOAD_TABLE_ROW_HEADER_LIST}
        fontSize="12px"
      />

      {value.map((row, rowIndex) => (
        <ProductSystemTableWithoutHeader
          key={`${key} File upload ${rowIndex}`}
          bodyContent={[
            {
              text: `${row.id}`,
              width: "5%",
              fontSize: "12px",
            },
            {
              text: `${row.name}`,
              width: "20%",
              fontSize: "12px",
            },
            {
              text: `${row.description}`,
              width: "30%",
              fontSize: "12px",
            },
            {
              text: `${row.fileType}`,
              width: "25%",
              fontSize: "12px",
            },
            {
              text: (
                <a
                  href={`${row.url}`}
                  target="_blank"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    fontSize: 12,
                    color: AppCSS.GLAS_BLUE,
                    backgroundColor: AppCSS.GREEN_MILD,
                    borderRadius: "10px",
                    padding: "5px",
                  }}
                  rel="noreferrer"
                  download={false}
                >
                  <p>View File</p>
                </a>
              ),
              width: "20%",
            },
          ]}
        />
      ))}
    </Box>
  );
};

export default FileUploadTable;
