/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import {
  TapButton,
  AppCSS,
  Spacer,
  TxtInput,
  SelectPosition,
} from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";

import { toast } from "react-toastify";
import { AdminUserModel } from "../../../redux/types";
import { EditAdminUser } from "../../../redux/api/users.api";
import { useMutation } from "@apollo/client";
import { CreateAdminUserInput } from "../../../types/graphQlQuery.interface";
import { CREATE_ADMIN_USER } from "../../../apolloClient/operation/mutations";

interface CreateUserProps {
  onTapAdd: Function;
  onFetchUsers: Function;
  editUser?: AdminUserModel;
}

export const CreateUser: React.FC<CreateUserProps> = ({
  onTapAdd,
  onFetchUsers,
  editUser,
}) => {
  const [isAdded, setIsAdded] = useState(false);

  const [firstName, setFirstName] = useState(editUser?.firstName || "");
  const [lastName, setLastName] = useState(editUser?.lastName || "");
  const [email, setEmail] = useState(editUser?.email || "");
  const [phone, setPhone] = useState(editUser?.phone || "");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(editUser?.Status || 0);
  const [role, setRole] = useState(editUser?.role || "");

  const [createAdminUser] = useMutation<CreateAdminUserInput>(
    CREATE_ADMIN_USER,
    {
      onCompleted: () => {
        toast("User created successfully!", { type: "success" });
        setIsAdded(false);
        onFetchUsers();
      },
      onError: (error) => {
        toast("Error creating user!", { type: "error" });
        console.error("Error creating user:", error);
      },
    }
  );

  useEffect(() => {
    if (isAdded) {
      setIsAdded(false);
      onTapAdd();
    }
  }, []);

  const onTapCreateUser = async () => {
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      phone === "" ||
      role === ""
    ) {
      toast("Please enter mandatory fields!", {
        type: "error",
      });
      return;
    }

    await createAdminUser({
      variables: {
        input: {
          email,
          firstName: firstName,
          lastName: lastName,
          phone,
          password,
          role,
        },
      },
    });
  };

  const onTapSaveUser = async () => {
    if (firstName === "" || lastName === "" || phone === "") {
      toast("Please enter mandatory fields!", {
        type: "error",
      });
      return;
    }

    try {
      const statusCode = await EditAdminUser(editUser.id, {
        firstName: firstName,
        lastName: lastName,
        phone,
        Status: status,
      });
      if (statusCode === 200) {
        toast("user updated successfully!", {
          type: "success",
        });
      }
      setIsAdded(false);
      onFetchUsers();
    } catch (error) {
      toast("error on create user!", {
        type: "error",
      });
    }
  };

  const hostContainer = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "50%",
            marginTop: "5%",
          }}
        >
          <ColDiv>
            <Lbl
              title="Create new user"
              size={32}
              bold={600}
              backgroundColor="#f3f3f3"
            />
          </ColDiv>

          <Spacer size={1} direction="col" />

          {/* Selected Action row */}
          <Spacer direction="col" />

          <ColDiv
            style={{
              width: "100%",
            }}
          >
            <TxtInput
              value={firstName}
              placeholder="First Name"
              onChange={setFirstName}
              borderColor={AppCSS.BLUE}
            />
            <Spacer size={1} direction="col" />
            <TxtInput
              value={lastName}
              placeholder="Last Name"
              onChange={setLastName}
              borderColor={AppCSS.BLUE}
            />
            <Spacer size={1} direction="col" />
            <TxtInput
              disable={editUser ? true : false}
              value={email}
              placeholder="Email"
              onChange={setEmail}
              borderColor={AppCSS.BLUE}
            />
            <Spacer size={1} direction="col" />
            <TxtInput
              value={phone}
              placeholder="Phone"
              onChange={setPhone}
              borderColor={AppCSS.BLUE}
            />
            <Spacer size={1} direction="col" />
            {!editUser && (
              <TxtInput
                value={password}
                placeholder="Password"
                onChange={setPassword}
                borderColor={AppCSS.BLUE}
              />
            )}
            <Spacer size={1} direction="col" />
            {editUser && (
              <p
                style={{ fontSize: 12, color: AppCSS.GRAY_DARK, marginLeft: 5 }}
              >
                Current Role:{role}
              </p>
            )}
            <SelectPosition value={role} onHandleChange={setRole.bind(this)} />
            <Spacer size={1} direction="col" />

            {/* {editUser && (
            <RowDiv>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                style={{
                  flexDirection: "row",
                  paddingLeft: "20px",
                }}
              >
                <FormControlLabel
                  value={1}
                  checked={status === 1}
                  control={
                    <Radio
                      onClick={() => {
                        setStatus(1);
                      }}
                      sx={{
                        color: "#dc2342",
                        "&.Mui-checked": {
                          color: "#dc2342",
                        },
                      }}
                    />
                  }
                  label={
                    <p
                      style={{
                        fontSize: "1.0rem",
                      }}
                    >
                      Active
                    </p>
                  }
                />
                <FormControlLabel
                  value={0}
                  checked={status === 0}
                  control={
                    <Radio
                      onClick={() => {
                        setStatus(0);
                      }}
                      sx={{
                        color: "#dc2342",
                        "&.Mui-checked": {
                          color: "#dc2342",
                        },
                      }}
                    />
                  }
                  label={
                    <p
                      style={{
                        fontSize: "1.0rem",
                      }}
                    >
                      Inactive
                    </p>
                  }
                />
              </RadioGroup>
            </RowDiv>
          )} */}

            <Spacer size={2} direction="col" />
          </ColDiv>

          <RowDiv
            style={{
              width: "80%",
              maxWidth: "900px",
              display: "row",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <TapButton
              title="Cancel"
              onTap={() => onTapAdd()}
              width={120}
              bgColor={AppCSS.WHITE}
              color={AppCSS.BLUE}
              borderColor={AppCSS.BLUE}
            />
            <TapButton
              title={editUser ? "Save" : "Create User"}
              bgColor={editUser ? AppCSS.GLAS_YELLOW : AppCSS.BLUE}
              onTap={() => {
                if (editUser) {
                  onTapSaveUser();
                } else {
                  onTapCreateUser();
                }
              }}
              width={220}
            />

            <Spacer size={5} direction="row" />
          </RowDiv>
          <Spacer size={2} direction="col" />
        </div>
      </div>
    );
  };

  return hostContainer();
};
