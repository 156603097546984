import * as React from "react";
import ProductSystems from "./ProductSystems/index";
import MiscFields from "./MiscFields/index";
import MaterialCategories from "./MaterialCategories/index";
import MaterialMeasurements from "./MaterialMeasurements/index";
import MaterialItems from "./MaterialItems/index";
import LaborMeasurements from "./LaborMeasurements/index";
import Contractors from "./Contractors/index";
import LaborItems from "./LaborItems/index";
import ContractorReportTypes from "./ContractorReportTypes/index";
import ContractorChangeOrders from "./ContractorChangeOrders/index";
import { Tab, Tabs, Box } from "@mui/material";
import { AppCSS } from "../../../components";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import { TabsBox } from "./itemCreation.styled";

const ItemsCreationTabLabels = [
  "Product Systems",
  "Miscellaneous Fields",
  "Material Categories",
  "Material Measurements",
  "Materials",
  "Labor Measurements",
  "Contractors",
  "Labor",
  // "Contractor Reports",
  // "Contractor Change Orders",
];

export default function MaterialCreation() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const renderTabPanel = (index: number) => {
    switch (index) {
      case 0:
        return <ProductSystems />;
      case 1:
        return <MiscFields />;
      case 2:
        return <MaterialCategories />;
      case 3:
        return <MaterialMeasurements />;
      case 4:
        return <MaterialItems />;
      case 5:
        return <LaborMeasurements />;
      case 6:
        return <Contractors />;
      case 7:
        return <LaborItems />;
      // case 8:
      //   return <ContractorReportTypes />;
      // case 9:
      //   return <ContractorChangeOrders />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: AppCSS.GRAY_LIGHTER,
        justifyContent: "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        height: "100vh",
      }}
    >
      <ColDiv
        style={{
          width: "98%",
          marginLeft: "1%",
          marginRight: "1%",
          height: "97%",
          marginBottom: "3%",
        }}
      >
        <RowDiv
          style={{
            width: "100%",
            height: "100%",
            gap: "10px",
            marginRight: "10px",
            marginBottom: "20px",
          }}
        >
          <ColDiv
            style={{
              width: "260px",
              height: "100%",
              background: AppCSS.WHITE,
              boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            }}
          >
            <TabsBox
              sx={{
                width: "100%",
                height: "100%",
                marginTop: "20px",
                overflowY: "auto",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                orientation="vertical"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                    background: "none",
                    border: "none",
                  },
                  hidden: true,
                }}
                aria-label="full width tabs example"
                variant="scrollable"
              >
                {ItemsCreationTabLabels.map((label, index) => (
                  <Tab
                    key={index}
                    label={label}
                    {...a11yProps(index)}
                    sx={{
                      width: "100%",
                      height: "38px",
                      background: value === index ? AppCSS.BLUE : "inherit",
                      marginBottom: "2px",
                      marginTop: "2px",
                      justifyContent: "center",
                      alignContent: "left",
                      alignItems: "start",
                      fontSize: "12px",

                      color: AppCSS.BLACK,
                      "&.Mui-selected": {
                        color: AppCSS.WHITE,
                        background: AppCSS.BLUE,
                        borderRadius: "10px",
                      },
                    }}
                  />
                ))}
              </Tabs>
            </TabsBox>
          </ColDiv>
          <ColDiv
            style={{
              width: "calc(100% - 260px)",
              height: "100%",
              background: "inherit",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                paddingX: "20px",
              }}
            >
              {renderTabPanel(value)}
            </Box>
          </ColDiv>
        </RowDiv>
      </ColDiv>
    </div>
  );
}
