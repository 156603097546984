/* eslint-disable no-empty-pattern */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { useAppSelector } from "../../../redux/hooks";
import { AdminUserModel } from "../../../redux/types";
import { CreateUser } from "./CreateUser";
import { UserTable } from "./UserTable";
import { setAdminUsers } from "../../../redux/reducers";
import AppLoader from "../../../components/loader";
import { useFetchAdminUser } from "../../../apolloClient/hooks/user/fetchHooks";

interface DashboardProps {}

export const Users: React.FC<DashboardProps> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editUser, setEditUser] = useState<AdminUserModel>();

  const [addUser, setAdduser] = useState(false);
  const dispatch = useDispatch();

  const { adminUsers } = useAppSelector((state) => state.userReducer);

  const { loading, error, adminUsersData, refetch } = useFetchAdminUser({
    limit: 100,
    offset: 0,
  });

  useEffect(() => {
    if (!loading && !error && adminUsersData) {
      dispatch(setAdminUsers(adminUsersData));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching users:", error);
      setIsLoading(false);
    }
  }, [loading, error, adminUsersData, dispatch, addUser]);

  const onAddUser = () => {
    setEditUser(undefined);
    setAdduser(!addUser);
  };

  const onTapEditUser = (editUserFromTable: AdminUserModel) => {
    setEditUser(editUserFromTable);
    setAdduser(true);
  };

  const resetPassword = (email: string) => {
    // dispatch();
    // onResetPassword({
    //   email,
    // })
    setTimeout(() => {
      toast("New password is sent your registred Email Id!", {
        type: "success",
      });
    }, 1000);
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  if (addUser) {
    return (
      <CreateUser
        editUser={editUser}
        onTapAdd={onAddUser}
        onFetchUsers={() => {
          onAddUser();
          refetch();
        }}
      />
    );
  } else {
    return (
      <UserTable
        onTapResetPassword={resetPassword}
        onTapEditUser={onTapEditUser}
        users={adminUsersData}
        onTapAddUser={onAddUser}
      />
    );
  }
};
