import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { RowDiv } from "../misc/misc.styled";
import { AppCSS } from "..";

interface SelectInputProps {
  options: { title: string; value: any }[];
  onHandleChange: Function;
  value: any;
  required?: boolean;
  backgroundColor?: string;
  selectRef?: React.RefObject<HTMLSelectElement>;
  placeholder?: string;
}

interface customSelectInputProps {
  options: { title: string; value: any }[];
  onHandleChange: Function;
  value: any;
  required?: boolean;
  label?: string;
  backgroundColor?: string;
}

export const SelectInput: React.FC<SelectInputProps> = ({
  options,
  onHandleChange,
  value,
  required = false,
  backgroundColor,
  selectRef,
  placeholder,
}) => {
  const [item, setItem] = useState<any>(value);

  const menuItems = () => {
    return options.map((option) => {
      return (
        <MenuItem
          key={option.value}
          style={{
            fontSize: 16,
            background: "none",
          }}
          value={option.value}
        >
          {option.title}
        </MenuItem>
      );
    });
  };

  const onHandleItemChange = (value: any) => {
    setItem(value);
    onHandleChange(value);
  };

  return (
    <RowDiv
      style={{
        background: backgroundColor ? backgroundColor : "#f0f0f0",
        borderRadius: "5px",
        padding: "5px",
        paddingLeft: "10px",
        width: "96%",
      }}
    >
      <FormControl variant="filled" fullWidth>
        {!item && <InputLabel>{placeholder}</InputLabel>}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item}
          onChange={(e) => onHandleItemChange(e.target.value)}
          disableUnderline
          style={{
            backgroundColor: "white",
          }}
          required={true}
          ref={selectRef}
        >
          {menuItems()}
        </Select>
      </FormControl>
      {required && (
        <p
          style={{
            fontSize: 24,
            color: AppCSS.RED,
            zIndex: 200,
          }}
        >
          *
        </p>
      )}
    </RowDiv>
  );
};

export const CustomSelectInput: React.FC<customSelectInputProps> = ({
  options,
  onHandleChange,
  value,
  required = false,
  label,
}) => {
  const [item, setItem] = useState<any>(value);
  const menuItems = () => {
    return options.map((option) => {
      return (
        <MenuItem
          key={option.value}
          style={{
            fontSize: 16,
          }}
          value={option.value}
        >
          {option.title}
        </MenuItem>
      );
    });
  };

  const onHandleItemChange = (value: any) => {
    setItem(value);
    onHandleChange(value);
  };

  return (
    <RowDiv
      style={{
        background: "#f0f0f0",
        borderRadius: "5px",
        padding: "5px",
        paddingLeft: "10px",
        width: "96%",
        gap: "10px",
      }}
    >
      <InputLabel
        disableAnimation
        shrink={false}
        focused={false}
        id="item_type_label"
        style={{
          fontSize: "12px",
        }}
      >
        {label}
      </InputLabel>
      <FormControl
        variant="filled"
        style={{
          width: "40%",
        }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item}
          onChange={(e) => onHandleItemChange(e.target.value)}
          disableUnderline
          style={{
            background: "none",
          }}
          required={true}
        >
          {menuItems()}
        </Select>
      </FormControl>
      {required && (
        <p
          style={{
            fontSize: 24,
            color: AppCSS.RED,
            zIndex: 200,
          }}
        >
          *
        </p>
      )}
    </RowDiv>
  );
};
