/* eslint-disable jsx-a11y/alt-text */
import { TapButton, AppCSS, Spacer, TxtInput } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";

import * as React from "react";
import { Project } from "../../../redux/types";
import { Container } from "../../../globalstyled";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import {
  RowIconDiv,
  DashboardIcon,
  SearchInputBox,
  SearchIcon,
  PreviousIcon,
} from "../dashboard.styled";
import searchIcon from "../../../images/searchIcon.png";
import previousIcon from "../../../images/previousIcon.png";
import nextIcon from "../../../images/nextIcon.png";
import ProjectsTableHeaderCard from "../../../components/table/CustomTableHeaderCard";
import ProjectsTableBodyCard from "../../../components/table/CustomTableBodyCard";
import { PROJECTSTABLEHEADERLIST } from "../../../contants/dashboardHeader";
import EmptyTable from "../../../components/table/EmptyTable";
import { useEffect } from "react";

interface UserTableProps {
  projects: Project[];
}

export const ProjectTable: React.FC<UserTableProps> = ({ projects }) => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(8);
  const [keyword, setKeyword] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSearchIconClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const filterProjects = () => {
    if (keyword.length > 0 && Array.isArray(projects)) {
      return projects.filter((item) => {
        const fullName =
          `${item.customer?.firstName} ${item.customer?.lastName}`.toLowerCase();
        return fullName.includes(keyword.toLowerCase());
      });
    }
    return projects;
  };

  const goToPreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const goToNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(filterProjects().length / rowsPerPage) - 1
      )
    );
  };

  function convertToNormalDate(dateString: string) {
    // Remove the "+0000 UTC" part from the string
    const cleanedDateString = dateString.split(" +")[0];

    // Create a new Date object from the cleaned date string
    const date = new Date(cleanedDateString);

    // Format the date to a more readable format (e.g., YYYY-MM-DD HH:MM:SS)
    const formattedDate = date.toISOString().replace("T", " @").split(".")[0];

    return formattedDate;
  }

  const ProjectsTable = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            width: "95%",
            marginBottom: "1%",
            gap: "10px",
          }}
        >
          <ProjectsTableHeaderCard headers={PROJECTSTABLEHEADERLIST} />
          {filterProjects().length === 0 ? (
            <EmptyTable message="No projects available" />
          ) : (
            [...filterProjects()]
              .reverse()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => {
                console.log("this is row", row?.customer?.firstName);
                return (
                  <ProjectsTableBodyCard
                    key={`ProjectsDetails ${rowIndex}`}
                    bodyContent={[
                      { text: `${row.id}`, width: "10%" },
                      {
                        text: `${row.customerId}`,
                        width: "20%",
                      },
                      {
                        text: `${
                          row.customer?.firstName + " " + row.customer?.lastName
                        }`,
                        width: "20%",
                      },
                      {
                        text: `${convertToNormalDate(row.createdAt)}`,
                        width: "35%",
                      },
                      {
                        text: "",
                        width: "8%",

                        isAction: true,
                        actionComponent: (
                          <TapButton
                            title="View"
                            onTap={() => navigate(`/projects/${row.id}`)}
                            bgColor={AppCSS.BLUE}
                            color={AppCSS.WHITE}
                            radius={5}
                            height={35}
                          />
                        ),
                      },
                    ]}
                  />
                );
              })
          )}
        </ColDiv>
        <RowDiv
          style={{
            justifyContent: "end",
            marginTop: "20px",
            marginBottom: "5%",
            alignItems: "center",
            marginRight: "10%",
          }}
        >
          <PreviousIcon src={previousIcon} onClick={() => goToPreviousPage()} />
          <span style={{ margin: "0 15px" }}>
            Page {page + 1} of{" "}
            {Math.ceil(filterProjects().length / rowsPerPage)}
          </span>
          <PreviousIcon src={nextIcon} onClick={() => goToNextPage()} />
        </RowDiv>
      </Container>
    );
  };

  const userContainer = () => {
    return (
      <Container>
        <Spacer size={2} direction="col" />
        <ColDiv
          style={{
            display: "row",
            justifyContent: "space-around",
            marginLeft: "10px",
            marginRight: "auto",
          }}
        >
          <RowIconDiv
            style={{
              gap: "10px",
              width: "50%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "10px",
                marginLeft: "3%",
              }}
            >
              <DashboardIcon
                src={
                  "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/56dcbe9e-7d72-49b8-fcbb-ae901116b800/chatImage"
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100px",
                  marginRight: "10px",
                }}
              >
                <Lbl
                  title="Projects"
                  size={22}
                  width={80}
                  backgroundColor="#f3f3f3"
                />
              </Box>
            </Box>
            <SearchInputBox
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                borderRadius: "10px",
                backgroundColor: "white",
                borderColor: AppCSS.BLUE,
                borderWidth: 1,
                boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <SearchIcon
                src={searchIcon}
                style={{
                  margin: "10px",
                  background: "none",
                }}
                onClick={handleSearchIconClick}
              />
              <TxtInput
                placeholder={`Search customer name`}
                onChange={setKeyword}
                inputRef={inputRef}
                background="white"
              />
            </SearchInputBox>
          </RowIconDiv>

          <RowDiv
            style={{
              justifyContent: "center",
              alignItems: "center",
              background: "none",
            }}
          >
            <Spacer direction="col" />
            {Array.isArray(projects) && ProjectsTable()}
          </RowDiv>
        </ColDiv>
      </Container>
    );
  };

  return userContainer();
};
