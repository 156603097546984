/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { InsuranceTable } from "./InsuranceTable";
import { setInsuranceCompanies } from "../../../redux/reducers";
import { Container } from "../../../globalstyled";
import { AddCompany } from "./AddCompany";
import AppLoader from "../../../components/loader";
import {
  insuranceCompaniesResponse,
  InsuranceCompany,
} from "../../../types/graphQlQuery.interface";
import { useQuery } from "@apollo/client";
import { GET_INSURANCE_COMPANIES } from "../../../apolloClient/operation/querys/userQuery";

interface DashboardProps {}

export const InsuranceCompanies: React.FC<DashboardProps> = ({}) => {
  const dispatch = useDispatch();
  const userReducer = useAppSelector((state) => state.userReducer);
  const { insuranceCompanies } = userReducer;

  const [addCompany, setAddCompany] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedInsurance, setSelectedInsurance] =
    useState<InsuranceCompany | null>(null);

  const { loading, error, data, refetch } =
    useQuery<insuranceCompaniesResponse>(GET_INSURANCE_COMPANIES, {
      variables: { limit: 100, offset: 0 },
    });

  useEffect(() => {
    onFetchInsuranceCompany();
  }, [loading, error, data, dispatch, addCompany]);

  const onFetchInsuranceCompany = () => {
    if (!loading && !error && data) {
      dispatch(setInsuranceCompanies(data.insuranceCompanies));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching insurance company:", error);
      setIsLoading(false);
    }
  };

  const handleEditInsurance = (insurance: InsuranceCompany) => {
    setSelectedInsurance(insurance);
    onDisplayAddCompany();
  };

  const onDisplayAddCompany = () => {
    setAddCompany(!addCompany);
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    if (addCompany) {
      return (
        <Container>
          <AddCompany
            onTapAdd={() => {
              setAddCompany(false);
              setSelectedInsurance(null);
              refetch();
            }}
            company={selectedInsurance}
            onCancel={() => {
              setAddCompany(false);
              setSelectedInsurance(null);
            }}
          />
        </Container>
      );
    } else {
      return (
        <Container>
          <InsuranceTable
            insuranceCompanies={
              Array.isArray(insuranceCompanies) ? insuranceCompanies : []
            }
            onTapAddCompany={() => onDisplayAddCompany()}
            onEditInsurance={handleEditInsurance}
            refetch={refetch}
          />
        </Container>
      );
    }
  };

  return hostContainer();
};
