import React from "react";
import { Box, Typography } from "@mui/material";
import nextIcon from "../../images/nextIcon.png";
import { NextIcon } from "./table.styled";

interface TableViewDetailsProps {
  rowData: any;
  onViewClick: (rowData: any) => void;
  text: string;
}

const TableViewDetails: React.FC<TableViewDetailsProps> = ({
  rowData,
  onViewClick,
  text,
}) => {
  return (
    <Box
      onClick={() => onViewClick(rowData)}
      sx={{
        display: "flex",
        flexDirection: "row",
        paddingRight: "20px",
        cursor: "pointer",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Typography>{text}</Typography>
      <NextIcon src={nextIcon} />
    </Box>
  );
};

export default TableViewDetails;
