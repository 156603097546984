import { MenuItem } from "../types/interface";
import projectIcon from "../images/projectIcon.png";

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 1,
    text: "Leads",
    route: "/leads",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/eba1791c-9dda-4fb5-4d3a-951ac8187d00/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/43645ec1-955b-473f-30e2-0d4b2f9dfc00/chatImage",
  },
  {
    id: 2,
    text: "Waitlist",
    route: "/waitlist",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/0e85581e-44af-43ea-4b5b-23f9c1efe200/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/dd546dab-0cb7-4070-aacf-95ee86ee6b00/chatImage",
  },
  {
    id: 3,
    text: "Projects",
    route: "/projects",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/96e85578-a114-4939-7288-5e3588585900/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/56dcbe9e-7d72-49b8-fcbb-ae901116b800/chatImage",
  },
  {
    id: 4,
    text: "Insurance Providers",
    route: "/insurance",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/80e34b22-51cd-4a29-adf7-4603b5b6f000/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/f22060f3-d169-4d16-3d0d-aa27c443e000/chatImage",
    // subMenu: [
    //   { text: "Materials", route: "/materials", icon: projectIcon },
    //   { text: "Insurance Companies", route: "/insurance", icon: projectIcon },
    // ],
  },
  {
    id: 5,
    text: "Users",
    route: "/users",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/e73206c8-c4da-46df-44c1-f13c15532300/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/d6e17c6a-c877-4e4a-6561-864612dfe500/chatImage",
  },
  {
    id: 5,
    text: "Product Systems",
    route: "/material-create",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/cc0b8417-a058-4222-f2cd-e3a9cfbf8500/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/a52982af-a131-489c-1f6b-d5e90d24eb00/chatImage",
  },
  {
    id: 6,
    text: "Logout",
    route: "/logout",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/1650db8f-85fd-4ddd-299c-b874a9ccfd00/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/1c8a1047-6ebd-407e-0c51-7d482ecf0200/chatImage",
  },
];
