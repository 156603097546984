import React from "react";
import { AppCSS } from "..";
import { Box } from "@mui/material";

interface MaterialEditTextProps {
  text: string;
}

const MaterialEditText: React.FC<MaterialEditTextProps> = ({ text }) => {
  return (
    <Box
      style={{
        width: "100%",
        height: 40,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          background: AppCSS.GLAS_BLUE,
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <p
          style={{
            padding: 10,
            fontSize: 18,
            fontWeight: 600,
            color: AppCSS.WHITE,
          }}
        >
          {text}
        </p>
      </Box>
    </Box>
  );
};

export default MaterialEditText;
