import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_LABOR_MEASUREMENTS_FOR_PROJECT,
  GET_MATERIAL_MEASUREMENTS_FOR_PROJECT,
  GET_PROJECT_PRODUCTS,
} from "../../../../apolloClient/operation/querys/userQuery";
import {
  LaborForProjectResponse,
  MaterialsForProjectResponse,
  ProjectResponse,
} from "../../../../types/graphQlQuery.interface";
import { Product, Project } from "../../../../redux/types";
import {
  CustomerProjectDetails,
  ProjectMeasurementInput,
} from "../../../../redux/types/projectDetails";
import { AppCSS } from "../../../../components";
import {
  CREATE_PROJECT_LABOR_MEASUREMENT,
  CREATE_PROJECT_MATERIAL_MEASUREMENT,
} from "../../../../apolloClient/operation/mutations/mutation";
import { toast } from "react-toastify";
import AppLoader from "../../../../components/loader";

interface MaterialProps {
  project: CustomerProjectDetails;
}

export default function Labor({ project }: MaterialProps) {
  const [measurements, setMeasurements] = useState<ProjectMeasurementInput[]>(
    []
  );

  const { data: laborData, loading } = useQuery<LaborForProjectResponse>(
    GET_LABOR_MEASUREMENTS_FOR_PROJECT,
    {
      variables: { limit: 1000, offset: 0 },
    }
  );

  const { data: products } = useQuery<ProjectResponse>(GET_PROJECT_PRODUCTS, {
    variables: { id: project.projectId },
  });

  const [addProjectLaborMeasurement] = useMutation<
    {
      addProjectMaterialMeasurement: boolean;
    },
    { input: ProjectMeasurementInput[] }
  >(CREATE_PROJECT_LABOR_MEASUREMENT);

  const {
    data: productsData,
    loading: productsLoading,
    refetch,
  } = useQuery<ProjectResponse>(GET_PROJECT_PRODUCTS, {
    variables: { id: project.projectId },
  });

  console.log(project);

  useEffect(() => {
    if (project && laborData) {
      let endMeasurements = [];
      const allMeasurements = laborData?.laborMeasurements;
      console.log(allMeasurements);
      const addedMeasurements = project?.insuranceScope.laborMeasurements.map(
        (measurement) =>
          measurement?.measurements.map((endMeasure) => {
            console.log({
              projectId: project?.projectId,
              measurementId: endMeasure.id,
              quantity: endMeasure.value.quantity,
              productId: measurement.productId,
              productName: measurement.productId,
              name: endMeasure.name,
              unit: endMeasure.unit,
            });
            endMeasurements.push({
              projectId: project?.projectId,
              measurementId: endMeasure.id,
              quantity: endMeasure.value.quantity,
              productId: measurement.productId,
              productName: measurement.productId,
              name: endMeasure.name,
              unit: endMeasure.unit,
            });
          })
      );
      console.log("the added", endMeasurements);
      const finalAdd = allMeasurements.map((measurement) => {
        measurement.parents.forEach((parent) => {
          let found = endMeasurements.some(
            (measure) =>
              measurement.id === measure.measurementId &&
              parent.id === measure.productId
          );
          console.log("find", found);
          if (found) {
            return;
          } else {
            endMeasurements.push({
              projectId: project?.projectId,
              measurementId: measurement.id,
              quantity: 0,
              productId: parent.id,
              productName: parent.id,
              name: measurement.name,
              unit: measurement.unit,
            });
          }
        });
      });
      console.log("the final", endMeasurements);
      setMeasurements(endMeasurements);
    }
  }, [project, laborData]);

  const createMeasurement = (measurement: ProjectMeasurementInput) => {
    addProjectLaborMeasurement({ variables: { input: [measurement] } })
      .then(() => {
        toast("Measurement updated", { type: "success" });
      })
      .catch((error) => {
        console.error("Error creating measurement:", error);
        toast("Error updating measurement", { type: "error" });
      });
  };

  const handleMeasurementChange = (
    measurementId: number,
    quantity: number,
    productId: number
  ) => {
    setMeasurements((prevMeasurements) =>
      prevMeasurements.map((m) =>
        m.measurementId === measurementId && m.productId === productId
          ? { ...m, quantity }
          : m
      )
    );
  };

  if (!productsData || !laborData) {
    return <AppLoader isLoading={productsLoading} />;
  }

  if (!productsData?.project?.products || !laborData) {
    refetch();
    return <AppLoader isLoading={productsLoading} />;
  }

  return (
    <>
      <div>
        {productsData?.project?.products.map((productSystem) => (
          <div style={{ color: "black" }} key={productSystem.id}>
            <p style={{ fontSize: 20, fontWeight: "bold", marginTop: 20 }}>
              {capitalizeFirstLetter(productSystem.name)} {productSystem.id}
            </p>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {Array.isArray(measurements) ? (
                measurements
                  .filter(
                    (measurement) =>
                      measurement.productName === productSystem.id
                  )
                  .map((measurement) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 5,
                        marginLeft: 10,
                      }}
                      key={`${measurement.measurementId}-${productSystem.id}`}
                    >
                      <div
                        style={{
                          color: "black",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {capitalizeFirstLetter(measurement.name) +
                          measurement.measurementId}
                      </div>
                      <div
                        style={{
                          width: 200,
                          height: 35,
                          borderWidth: 1,
                          borderColor: "black",
                          borderRadius: 5,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="number"
                          onBlur={() => {
                            createMeasurement({
                              projectId: project.projectId,
                              measurementId: measurement.measurementId,
                              quantity:
                                measurements.find(
                                  (m) =>
                                    m.measurementId ===
                                      measurement.measurementId &&
                                    m.productId === productSystem.id
                                )?.quantity || 0,
                              productId: productSystem.id,
                            });
                            console.log({
                              projectId: project.projectId,
                              measurementId: measurement.measurementId,
                              quantity:
                                measurements.find(
                                  (m) =>
                                    m.measurementId ===
                                      measurement.measurementId &&
                                    m.productId === productSystem.id
                                )?.quantity || 0,
                              productId: productSystem.id,
                            });
                          }}
                          value={
                            measurements.find(
                              (m) =>
                                m.measurementId === measurement.measurementId &&
                                m.productId === productSystem.id
                            )?.quantity || ""
                          }
                          onChange={(e) =>
                            handleMeasurementChange(
                              measurement.measurementId,
                              parseFloat(e.target.value),
                              productSystem.id
                            )
                          }
                          style={{
                            width: 150,
                            height: "100%",
                            paddingLeft: 5,
                            borderColor: "black",
                            borderRadius: 5,
                            outline: "none",
                          }}
                        />
                        <div
                          style={{
                            width: 50,
                            height: "100%",
                            fontSize: 12,
                            borderColor: "black",
                            textAlign: "center",
                            fontWeight: "bold",
                            backgroundColor: AppCSS.BLACK,
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {measurement.unit.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
