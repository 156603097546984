/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppAlert } from "../../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../../redux/hooks";
import { MaterialCategoriesTable } from "./MaterialCategoriesTable";
import { setMaterialCategories } from "../../../../redux/reducers";
import { Container } from "../../../../globalstyled";
import AppLoader from "../../../../components/loader";
import { useFetchMaterialCategories } from "../../../../apolloClient/hooks/itemCreation/fetchHooks";

interface MaterialCategoriesProp {}

const MaterialCategory: React.FC<MaterialCategoriesProp> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { materialCategories } = userReducer;

  const {
    loading,
    error,
    materialCategories: materialCategory,
    refetch,
  } = useFetchMaterialCategories({
    limit: 100,
    offset: 0,
  });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    onFetchMaterialCategoriesFields();
  }, [loading, error, dispatch, materialCategory, handleRefetch]);

  const onFetchMaterialCategoriesFields = async () => {
    if (!loading && !error && materialCategory) {
      dispatch(setMaterialCategories(materialCategory));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Product:", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <MaterialCategoriesTable
          materialCategoriesData={materialCategories}
          refetchMaterialCategories={handleRefetch}
        />
      </Container>
    );
  };

  return hostContainer();
};

export default MaterialCategory;
