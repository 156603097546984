import React from "react";
import { Box } from "@mui/material";
import { ProductSystemForMiscField } from "../../../../redux/types/creation";
import ProductTableHeaderCard from "../../../../components/table/CustomTableHeaderCard";
import { PRODUCT_SYSTEM_PARENT_IDS_TABLE_ROW_HEADER_LIST } from "../../../../contants/dashboardHeader";
import ProductSystemTableWithoutHeader from "../../../../components/table/CustomTableBodyCardWithoutHeader";

interface ProductSystemParentIdsTableProps {
  value?: ProductSystemForMiscField[];
  key?: string;
}

const ProductSystemParentIdsTable: React.FC<
  ProductSystemParentIdsTableProps
> = ({ value, key }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      <ProductTableHeaderCard
        headers={PRODUCT_SYSTEM_PARENT_IDS_TABLE_ROW_HEADER_LIST}
        fontSize="14px"
      />

      {value.map((row, rowIndex) => (
        <ProductSystemTableWithoutHeader
          key={`${key} product system ${rowIndex}`}
          bodyContent={[
            {
              text: `${row.id}`,
              width: "100%",
              fontSize: "14px",
              paddingLeft: "50px",
            },
          ]}
        />
      ))}
    </Box>
  );
};

export default ProductSystemParentIdsTable;
