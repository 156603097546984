import { FC, useEffect, useState } from "react";
import { AppCSS, Spacer, TapButton, TxtInput } from "../../../../components";
import { ColDiv, RowDiv } from "../../../../components/misc/misc.styled";
import { TinyLbl } from "../../../../components/labels/labels.styled";
import { Project } from "../../../../redux/types";
import { toast } from "react-toastify";
import { EstimateAddOns } from "../../../../redux/types/Estimate";
import { Prompt } from "../../../../components/dialogue/Dialogue";
import { ProjectAddOns } from "../../../../components/ProjectAddOns";
import InsuranceScopeTap, { TabConfig } from "./InsuranceScopeTab";
import MaterialsDisplay from "../../../../components/share/MaterialDisplay";
//import LaborsDisplay from "../../../../components/share/LaborsDisplay";
import InsuranceScopeViewer from "../../../../components/ui/InsuranceScopeViewer";
import {
  useAddProjectMaterialMeasurement,
  useApproveProjectScope,
} from "../../../../apolloClient/hooks/general/createHook";
import {
  CustomerProjectDetails,
  ProjectMeasurementInput,
} from "../../../../redux/types/projectDetails";
import { extractMeasurementsData } from "../../../../utils/project";
import { FormProvider, useForm } from "react-hook-form";
import Materials from "./Materials";
import Labor from "./Labor";
import Addons from "./Addons";
import InsuranceInfo from "./InsuranceDetails";

interface InsuranceProps {
  estimateAddOns?: EstimateAddOns[];
  project?: Project;
  insuranceScopeUrl?: string;
  insuranceScopeDetails: any;
  customerProjectDetails: CustomerProjectDetails;
  handleRefetchProjectDetails: Function;
}

export const InsuranceDetails: FC<InsuranceProps> = ({
  project,
  estimateAddOns,
  insuranceScopeUrl,
  insuranceScopeDetails,
  customerProjectDetails,
  handleRefetchProjectDetails,
}) => {
  const [insuranceScope, setInsuranceScope] = useState<any>(
    insuranceScopeDetails
  );
  const [isMaterialsCategoryEdit, setIsMaterialsCategoryEdit] = useState(false);
  const [approved, setApproved] = useState(false);
  const [actionType, setActionType] = useState("save");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const { approveScope } = useApproveProjectScope();
  const { addProjectMaterialMeasurement } = useAddProjectMaterialMeasurement();
  const [materialsFocused, setMaterialsFocused] = useState(true);
  const [laborFocused, setLaborFocused] = useState(false);

  const [insuranceFocused, setInsuranceFocused] = useState(false);

  const [addonsFocused, setAddonsFocused] = useState(false);

  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    handleRefetchProjectDetails();
  }, []);

  const switchActiveTab = (activetab: string) => {
    switch (activetab) {
      case "materials":
        setMaterialsFocused(true);
        setLaborFocused(false);
        setInsuranceFocused(false);
        setAddonsFocused(false);
        break;
      case "labor":
        setMaterialsFocused(false);
        setLaborFocused(true);
        setInsuranceFocused(false);
        setAddonsFocused(false);
        break;
      case "insurance":
        setMaterialsFocused(false);
        setLaborFocused(false);
        setInsuranceFocused(true);
        setAddonsFocused(false);
        break;
      case "addons":
        setMaterialsFocused(false);
        setLaborFocused(false);
        setInsuranceFocused(false);
        setAddonsFocused(true);
        break;
      default:
        break;
    }
  };

  const methods = useForm({
    defaultValues: {
      materialMeasurements: insuranceScopeDetails.materialMeasurements.map(
        (materialCategory: any) => ({
          measurements: materialCategory.measurements.map(
            (measurement: any) => ({
              quantity: measurement.value?.quantity || null,
            })
          ),
        })
      ),
    },
  });

  if (!insuranceScope) {
    return <div>Loading...</div>;
  }

  // const handleConfirm = () => {
  //   switch (actionType) {
  //     case "save":
  //       methods.handleSubmit(OnSaveData)();
  //       break;
  //     case "approve":
  //       onTapApproveScope();
  //       break;
  //     default:
  //       console.log("No action defined.");
  //   }
  // };

  const onClickApprove = () => {
    setOpenConfirm(true);
    setConfirmationMessage("Approve Insurance scope?");
    setActionType("approve");
  };

  // const OnSaveData = async (formData: any) => {
  //   const changedInputs: ProjectMeasurementInput[] =
  //     formData.materialMeasurements.flatMap(
  //       (category: any, categoryIndex: number) =>
  //         category.measurements
  //           .map((measurement: any, measurementIndex: number) => {
  //             const original =
  //               insuranceScope.materialMeasurements[categoryIndex].measurements[
  //                 measurementIndex
  //               ].value.quantity;
  //             if (measurement.quantity !== original) {
  //               return {
  //                 projectId: customerProjectDetails.projectId,
  //                 measurementId:
  //                   insuranceScope.materialMeasurements[categoryIndex]
  //                     .measurements[measurementIndex].id,
  //                 quantity: measurement.quantity,
  //               };
  //             }
  //             return null;
  //           })
  //           .filter((input: any) => input !== null)
  //     );

  //   if (changedInputs.length === 0) {
  //     toast("No changes detected", {
  //       type: "info",
  //       position: "top-left",
  //       className: "toast-message",
  //     });
  //     return;
  //   }

  //   const success = await addProjectMaterialMeasurement(changedInputs);
  //   if (success) {
  //     setOpenConfirm(false);
  //     methods.reset(formData);
  //     handleRefetchProjectDetails();
  //     toast("Data saved successfully", {
  //       type: "success",
  //       position: "top-left",
  //       className: "toast-message",
  //     });
  //   } else {
  //     toast("Failed to save data", {
  //       type: "error",
  //       position: "top-left",
  //       className: "toast-message",
  //     });
  //     setOpenConfirm(false);
  //   }
  // };

  const onTapApproveScope = async () => {
    setOpenConfirm(false);

    const result = await approveScope({
      projectId: customerProjectDetails.projectId,
      approved: true,
    });

    if (!result) {
      toast("Failed to approve the project", {
        type: "error",
        position: "top-center",
        className: "toast-message",
      });
      return;
    }
    toast("Project approved successfully", {
      type: "success",
      position: "top-center",
      className: "toast-message",
    });
    setApproved(true);
  };

  const activeStyle = {
    color: AppCSS.BLUE,
    fontSize: 18,
    width: "fit-content",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: "pointer",
  };

  const inactiveStyle = {
    color: "black",
    fontSize: 18,
    width: "fit-content",
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
    cursor: "pointer",
  };

  return (
    <RowDiv
      style={{
        display: "flex",
        flexDirection: "column",

        justifyContent: "justify-between",
      }}
    >
      <Prompt
        message={confirmationMessage}
        onConfirm={onTapApproveScope}
        onClose={() => setOpenConfirm(false)}
        open={openConfirm}
      />

      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {/* <InsuranceScopeViewer insuranceScopeUrl={insuranceScopeUrl} /> */}
        <Spacer direction="row" size={0.25} />
        <TapButton
          title={approved ? "Scope is Approved" : "Approve Scope"}
          onTap={onClickApprove}
          bgColor={AppCSS.WHITE}
          color={AppCSS.BLUE}
          borderColor={AppCSS.BLUE}
          width={164}
          height={45}
          radius={10}
          borderWidth="0.5px"
          disable={approved}
        />
        {/* <TapButton
          title="Save"
          onTap={onClickSave}
          color={AppCSS.WHITE}
          bgColor={AppCSS.BLUE}
          borderColor={AppCSS.BLUE}
          width={164}
          height={45}
          radius={10}
          borderWidth="0.5px"
        /> */}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginBottom: 20,
        }}
      >
        <div
          style={materialsFocused ? activeStyle : inactiveStyle}
          onClick={() => switchActiveTab("materials")}
        >
          Materials
        </div>
        <div
          style={laborFocused ? activeStyle : inactiveStyle}
          onClick={() => switchActiveTab("labor")}
        >
          Labor
        </div>
        <div
          style={insuranceFocused ? activeStyle : inactiveStyle}
          onClick={() => switchActiveTab("insurance")}
        >
          Insurance Details
        </div>
        <div
          style={addonsFocused ? activeStyle : inactiveStyle}
          onClick={() => switchActiveTab("addons")}
        >
          Add-ons
        </div>
      </div>
      {materialsFocused ? (
        <Materials
          project={customerProjectDetails}
          refetchData={handleRefetchProjectDetails}
        />
      ) : (
        <></>
      )}
      {laborFocused ? <Labor project={customerProjectDetails} /> : <></>}
      {insuranceFocused ? (
        <InsuranceInfo project={customerProjectDetails} />
      ) : (
        <></>
      )}
      {addonsFocused ? <Addons project={customerProjectDetails} /> : <></>}

      <Spacer direction="col" size={3} />
    </RowDiv>
  );
};
