/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppAlert } from "../../../../components/dialogue/Dialogue";
import { useAppSelector } from "../../../../redux/hooks";
import { MaterialItemsTable } from "./MaterialItemsTable";
import {
  setMaterialItems,
  setMaterialMeasurements,
} from "../../../../redux/reducers";
import { Container } from "../../../../globalstyled";
import AppLoader from "../../../../components/loader";
import {
  useFetchMaterialMeasurements,
  useFetchMaterials,
} from "../../../../apolloClient/hooks/itemCreation/fetchHooks";

interface MaterialItemsProp {}

const MaterialMeasurement: React.FC<MaterialItemsProp> = ({}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { materialItems, materialMeasurements } = userReducer;

  const { loading, error, materialsData, refetch } = useFetchMaterials({
    limit: 100,
    offset: 0,
  });

  const {
    loading: materialMeasurementsLoading,
    error: materialMeasurementsError,
    materialMeasurementsData,
  } = useFetchMaterialMeasurements({
    limit: 100,
    offset: 0,
    isSkip: materialMeasurements.length === 0 ? false : true,
  });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    onFetchMaterial();

    if (materialMeasurements.length === 0) {
      onFetchMaterialMeasurements();
    }
  }, [loading, error, materialsData, dispatch, handleRefetch]);

  const onFetchMaterial = async () => {
    if (!loading && !error && materialsData) {
      dispatch(setMaterialItems(materialsData));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Material misc field:", error);
      setIsLoading(false);
    }
  };

  const onFetchMaterialMeasurements = () => {
    if (
      !materialMeasurementsLoading &&
      !materialMeasurementsError &&
      materialMeasurementsData
    ) {
      dispatch(setMaterialMeasurements(materialMeasurementsData));
      setIsLoading(false);
    } else if (error) {
      console.error("Error fetching Material measurement :", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <AppLoader isLoading={isLoading} />;
  }

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <MaterialItemsTable
          materialItemsData={materialItems}
          refetchMaterials={handleRefetch}
          materialMeasurementIds={materialMeasurements}
        />
      </Container>
    );
  };

  return hostContainer();
};

export default MaterialMeasurement;
