import { useQuery } from "@apollo/client";
import {
  GET_CONTRACTOR,
  GET_CONTRACTOR_CHANGE_ORDERS,
  GET_CONTRACTOR_REPORTS,
  GET_LABOR_MEASUREMENT_FIELDS,
  GET_LABORS,
  GET_MATERIAL,
  GET_MATERIAL_CATEGORIES,
  GET_MATERIAL_MEASUREMENT_FIELDS,
  GET_MATERIAL_MEASUREMENTS_NAME_UNIT_FIELDS,
  GET_MISC_FIELDS,
  GET_PRODUCTS,
  GET_PRODUCTS_Ids,
  GET_PRODUCTS_NAME,
} from "../../operation/querys/itemsCreationQuery";
import {
  GetContractorChangeOrdersResponse,
  GetContractorReportsResponse,
  GetContractorResponse,
  GetLaborMeasurementsResponse,
  GetLaborResponse,
  GetMaterialCategoriesResponse,
  GetMaterialMeasurementsResponse,
  GetMaterialResponse,
  GetMiscFieldsResponse,
  GetProductsResponse,
} from "../../../redux/types/creation";

export interface queryInput {
  limit: number;
  offset: number;
  isSkip?: boolean;
}

export const useFetchMaterialCategories = ({
  limit,
  offset,
  isSkip,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetMaterialCategoriesResponse,
    queryInput
  >(GET_MATERIAL_CATEGORIES, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    materialCategories: data?.materialCategory,
    refetch,
  };
};

export const useFetchMaterialMisc = ({ limit, offset, isSkip }: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetMiscFieldsResponse,
    queryInput
  >(GET_MISC_FIELDS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    miscFields: data?.miscFields,
    refetch,
  };
};

export const useFetchProducts = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetProductsResponse,
    queryInput
  >(GET_PRODUCTS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return { loading, error, products: data?.products, refetch };
};

export const useFetchProductsIds = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetProductsResponse,
    queryInput
  >(GET_PRODUCTS_Ids, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return { loading, error, products: data?.products, refetch };
};

export const useFetchLaborMeasurements = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetLaborMeasurementsResponse,
    queryInput
  >(GET_LABOR_MEASUREMENT_FIELDS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    laborMeasurementData: data?.laborMeasurements,
    refetch,
  };
};

export const useFetchMaterialMeasurements = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetMaterialMeasurementsResponse,
    queryInput
  >(GET_MATERIAL_MEASUREMENT_FIELDS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    materialMeasurementsData: data?.materialMeasurements,
    refetch,
  };
};

export const useFetchMaterials = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetMaterialResponse,
    queryInput
  >(GET_MATERIAL, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    materialsData: data?.materials,
    refetch,
  };
};

export const useFetchContractorReports = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetContractorReportsResponse,
    queryInput
  >(GET_CONTRACTOR_REPORTS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    contractorReportsData: data?.contractorReports,
    refetch,
  };
};

export const useFetchContractor = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetContractorResponse,
    queryInput
  >(GET_CONTRACTOR, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    contractorsData: data?.contractors,
    refetch,
  };
};

export const useFetchLabor = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetLaborResponse,
    queryInput
  >(GET_LABORS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    laborsData: data?.labors,
    refetch,
  };
};

export const useFetchContractorChangeOrder = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetContractorChangeOrdersResponse,
    queryInput
  >(GET_CONTRACTOR_CHANGE_ORDERS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    contractorChangeOrdersData: data?.ContractorOrders,
    refetch,
  };
};

export const useFetchMaterialMeasurementsNameAndUnit = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetMaterialMeasurementsResponse,
    queryInput
  >(GET_MATERIAL_MEASUREMENTS_NAME_UNIT_FIELDS, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return {
    loading,
    error,
    materialMeasurementsData: data?.materialMeasurements,
    refetch,
  };
};

export const useFetchProductsName = ({
  limit,
  offset,
  isSkip = false,
}: queryInput) => {
  const { loading, error, data, refetch } = useQuery<
    GetProductsResponse,
    queryInput
  >(GET_PRODUCTS_NAME, {
    variables: { limit, offset },
    skip: isSkip,
  });

  return { loading, error, products: data?.products, refetch };
};
