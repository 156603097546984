import { gql } from "@apollo/client";

export const CREATE_ADMIN_USER = gql`
  mutation CreateAdminUser($input: CreateAdminUserInput!) {
    createAdminUser(input: $input) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export const EDIT_ADMIN_USER = gql`
  mutation EditAdminUser($input: EditAdminUserInput!) {
    editAdminUser(input: $input) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export const CREATE_INSURANCE_COMPANY = gql`
  mutation CreateInsuranceCompany($input: CreateInsuranceCompanyInput!) {
    createInsuranceCompany(input: $input)
  }
`;

export const RESET_PROGRESS = gql`
  mutation ResetProgress($input: ProgressResetInput!) {
    resetProgress(input: $input) {
      id
      projectId
      goal
      approved
      remarks
      title
      description
      completed
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: Int!) {
    deleteProject(id: $id)
  }
`;

export const CREATE_LEAD = gql`
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      leadId
    }
  }
`;

export const EDIT_INSURANCE_COMPANY = gql`
  mutation EditInsuranceCompany(
    $id: Int!
    $input: CreateInsuranceCompanyInput!
  ) {
    editInsuranceCompany(id: $id, input: $input)
  }
`;

export const DELETE_INSURANCE_COMPANY_BY_ID = gql`
  mutation DeleteInsuranceCompany($id: Int!) {
    deleteInsuranceCompany(id: $id)
  }
`;

export const CREATE_MATERIAL = gql`
  mutation CreateMaterial($input: CreateMaterialInput!) {
    createMaterial(input: $input)
  }
`;

export const CREATE_UPLOAD_URL = gql`
  mutation CreateUploadUrl($input: UploadFileInput!) {
    createUploadUrl(input: $input) {
      url
      fileName
    }
  }
`;

export const APPROVE_SCOPE_MUTATION = gql`
  mutation ApproveScope($input: scopeApprovalInput!) {
    approveScope(input: $input)
  }
`;

export const ADD_PROJECT_MATERIAL_MEASUREMENT = gql`
  mutation AddProjectMaterialMeasurement($input: [ProjectMeasurementInput]!) {
    addProjectMaterialMeasurement(input: $input)
  }
`;

export const CREATE_PAYMENT_MUTATION = gql`
  mutation CreatePayment($input: PaymentInput!) {
    createPayment(input: $input) {
      token
    }
  }
`;

export const CREATE_PROJECT_FILE = gql`
  mutation CreateProjectFile($input: createProjectFileInput!) {
    createProjectFile(input: $input)
  }
`;

export const CREATE_PROJECT_MATERIAL_MEASUREMENT = gql`
  mutation AddProjectMaterialMeasurement($input: [ProjectMeasurementInput]!) {
    addProjectMaterialMeasurement(input: $input)
  }
`;

export const CREATE_PROJECT_LABOR_MEASUREMENT = gql`
  mutation AddProjectLaborMeasurement($input: [ProjectMeasurementInput]!) {
    addProjectLaborMeasurement(input: $input)
  }
`;

export const CREATE_PROJECT_ADDON = gql`
  mutation AddProjectAddOns($input: CreateAddOnsInput!) {
    addProjectAddOns(input: $input)
  }
`;

export const EDIT_PROJECT_ADDON = gql`
  mutation EditProjectAddOns($input: EditAddOnsInput!) {
    editProjectAddOns(input: $input)
  }
`;

export const ADD_CLAIM_DETAILS = gql`
  mutation AddClaimDetails($input: AddClaimInput!) {
    addClaimDetails(input: $input)
  }
`;

export const DELETE_PROJECT_ADDON = gql`
  mutation deleteProjectAddOns($addOnId: Int!) {
    deleteProjectAddOns(addOnId: $addOnId)
  }
`;
