/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { CenterBox, ColDiv, RowDiv } from "../../components/misc/misc.styled";
import { Lbl } from "../../components/labels";
import { AppCSS, Spacer, TapButton, TxtInput } from "../../components";

import { useEffect, useState } from "react";
import { LoginContainer } from "./login.styled";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userLogin } from "../../redux/reducers";
import { useLazyQuery } from "@apollo/client";
import { LOGIN_ADMIN_USER } from "../../apolloClient/operation/querys/userQuery";

interface LoginProps {}

interface LoginData {
  adminLogin: {
    token: string;
  };
}

interface LoginVariables {
  email: string;
  password: string;
}

export const Login: React.FC<LoginProps> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [forgot, setForgot] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const profile = useAppSelector((state) => state.userReducer).userProfile;

  const [loginUser, { data, error }] = useLazyQuery<LoginData, LoginVariables>(
    LOGIN_ADMIN_USER
  );

  useEffect(() => {
    if (error) {
      console.error("Error occurred:", error);
      toast("Login failed! Please check your credentials.", {
        type: "error",
      });
    }

    if (data && data.adminLogin && data.adminLogin.token) {
      localStorage.setItem("token", data.adminLogin.token);
      dispatch(userLogin({ token: data.adminLogin.token, _id: "" }));
      navigate("/dashboard");
    }
  }, [data, error, dispatch, navigate]);

  const onTapSignin = async () => {
    if (email === "" || password === "") {
      toast("Please enter email address and password!", {
        type: "error",
      });
      return;
    }

    loginUser({
      variables: {
        email: email,
        password: password,
      },
    });
  };

  const onTapResetPassword = () => {
    if (email === "") {
      toast("Please enter email address!", {
        type: "error",
      });
      return;
    }
  };

  const loginView = () => {
    return (
      <CenterBox
        style={{
          maxWidth: "420px",
        }}
      >
        <RowDiv>
          <Lbl title="Sign in" size={25} bold={500} />
        </RowDiv>

        <ColDiv>
          <TxtInput placeholder="Email address" onChange={setEmail} />
          <Spacer size={2} direction="col" />
          <TxtInput
            type="password"
            placeholder="Password"
            onChange={setPassword}
            onPressEnter={() => {
              onTapSignin();
            }}
          />
        </ColDiv>
        <Spacer size={2} direction="col" />
        <RowDiv
          style={{
            justifyContent: "space-between",
          }}
        >
          <TapButton
            onTap={() => setForgot(true)}
            title="Forgot Password"
            bgColor={AppCSS.WHITE}
            color={AppCSS.GLAS_GREEN}
            height={40}
          />
          <Spacer size={2} direction="row" />
          <TapButton
            onTap={onTapSignin}
            title="Sign In"
            bgColor={AppCSS.GLAS_BLUE}
            height={40}
          />
        </RowDiv>
        <Spacer size={1} direction="col" />

        <Spacer size={2} direction="col" />
      </CenterBox>
    );
  };

  const forgotView = () => {
    return (
      <CenterBox
        style={{
          maxWidth: "420px",
        }}
      >
        <RowDiv>
          <Lbl title="Forgot Password" size={18} bold={600} />
        </RowDiv>
        <Spacer size={4} direction="col" />
        <ColDiv>
          <TxtInput placeholder="Email address" onChange={setEmail} />
        </ColDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            justifyContent: "space-between",
          }}
        >
          <TapButton
            onTap={() => setForgot(false)}
            title="Cancel"
            color={AppCSS.GLAS_BLUE}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            height={40}
          />
          <Spacer size={2} direction="row" />

          <TapButton
            onTap={onTapResetPassword}
            title="Reset Password"
            bgColor={AppCSS.GLAS_BLUE}
            height={40}
          />
        </RowDiv>
      </CenterBox>
    );
  };

  return <LoginContainer>{forgot ? forgotView() : loginView()}</LoginContainer>;
};

export default Login;
